<template>
  <section class="welc">
    <section class="block-content">
      <h3 :aria-label="`Tagline ${hotel.tag_line}`" v-if="hotel_tagline" style="white-space: pre-wrap">{{hotel_tagline}}</h3>
      <h2 style="text-transform: uppercase">{{ $t('lbl_welcome_to') }} {{hotel_name}}</h2>
      <p style="white-space: pre-wrap">{{additional_details}}</p>
    </section>
    <div class="carousel-container">
      <carousel aria-label="Hotel Images" :animated="true" :carousel-images="roomCovers"></carousel>
    </div>
  </section>
</template>
<script>
  import carousel from '@/components/general/carousel/carousel.vue';

  import {mapState} from 'vuex'

  export default {
    components: {
      carousel
    },
    props:['roomCovers'],
    computed: {
      ...mapState({
        hotel: state => state.hotel !== null,
        hotel_tagline: state => state.hotel.tag_line,
        hotel_name: state => state.hotel.name,
        additional_details: state => state.hotel.additional_details
      }),
    }
  }
</script>
<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';
  .welc {
    align-items: flex-start;
    .block-content {
      z-index: 1;
      p {
        white-space: pre-wrap;
        box-shadow: 0px 0px 18px #cecece;
        padding: 20px;
        border-radius: 10px;
        background: white;
        min-width: 700px;
        @include media("<=phone") {
          margin: 10px 0;
          min-width: 200px;
        }
      }
    }
    >:last-child {
      margin-left: 30px;
      height: 100%;
      >:first-child {
        border-radius: 10px;
        >:first-child {
          border-radius: 10px;
        }
      }
      @include media("<=phone") {
        margin: 0 auto;
        min-width: 200px;
        width: 100%;
      }
    }
    @include media("<=phone") {
      flex-direction: column;
    }
  }

  @include media("<=tablet") {
    .block-content {
      margin: 0 auto
    }

    .carousel-container {
      margin: 0 auto !important;
    }
  }
</style>
