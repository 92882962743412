import {Menu, X, Facebook, Twitter, Instagram, MapPin, Phone, Mail, User, LogIn, Loader} from 'vue-feather-icon';
import Pinterest from '@/components/general/pinterest-icon.vue'
import boldHeader from '@/components/headers/bold-header.vue'
import defaultHeader from '@/components/headers/default-header.vue'
import expressHeader from '@/components/headers/express-header.vue'
import overlayHeader from '@/components/headers/overlay-header.vue'
import tripAdvisorsJson from './../../assets/trip-advisors'
import LangModal from "../../components/LangModal";
import popup from '../../components/popup'
import expressFooter from '@/components/footer/express-footer.vue'
import GlobalMessage from "../../components/GlobalMessage";

export default {
  components: {
    menuIcon: Menu.default,
    x: X.default,
    Facebook: Facebook.default,
    Twitter: Twitter.default,
    Instagram: Instagram.default,
    Pinterest,
    MapPin: MapPin.default,
    Phone: Phone.default,
    User: User.default,
    Mail: Mail.default,
    Loader: Loader.default,
    loginIcon: LogIn.default,
    boldHeader,
    defaultHeader,
    expressHeader,
    overlayHeader,
    LangModal,
    popup,
    expressFooter,
    GlobalMessage
  },
  beforeMount() {
    const env = process.env;
    let url;

    if (this.hotel === null && this.$route.name === 'home') {
      this.$store.dispatch('setPropDetails');
    }else{
      this.configure_dynamic_menus();
    }

    url = `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel.id}&url=${window.location.href}`;
    this.book_url = url;
  },
  mounted() {
    if (process.env.NODE_ENV !== 'production') {
      this.isProduction = false;
    }
    this.setAuthCookies();
    this.active_menu = this.$route.params.active_menu;

    this.$nextTick(() => {
      this.message_height = document.getElementById('global-message').offsetHeight
    })
  },
  methods: {
    setAuthCookies() {

      let host = window.location.host;
      if (host.indexOf(':') !== -1 && host.split(':')[0] === 'localhost') {
        document.cookie = 'website_url=' + window.location.href;
      } else {
        document.cookie = `website_url=${window.location.href};path=/;domain=.hotelkeyapp.com`;
        // document.cookie = `hk_logged_user=${callCenterInfo.username};path=/;domain=.hotelkeyapp.com`;
      }
    },
    accessStatus() {
      if (this.access_status) {
        this.$store.commit('setAccessStatus', false);
      } else {
        this.$store.commit('setAccessStatus', true);
      }
    },
    sanitize(url) {
      return url.replace(/ /g, '%20');
    },
    openlogin() {
      this.showlogin = true;
    },
    goToMap() {
      window.location.href = this.gmaps_url;
    },
    goTo(item) {
      if (item.split('.')[0] === 'www') {
        window.location.href = `https://${item}`;
      } else {
        window.location.href = `${item}`;
      }
    },
    anchorText(text) {
      const urlRegex = /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w\-]*)?(\?[^\s]*)?/gi;
      return text.replace(urlRegex, (url) => {
        return `<a href="${url.includes('http') ? url : '//'+ url}"  target="_blank" style="color: #1b01ed;text-decoration: underline;">${url}</a>`
      })
    },
    configure_dynamic_menus() {
      if (this.website_menus.length > 0) {
        this.website_menus.forEach(menu => {
          if ((menu.is_parent === true) || (menu.is_parent === false && menu.parent_website_menu_id === null)) {
            menu.sub_menus = [];
            this.main_menus.push({...menu, active_menu: menu.name});
          } else {
            this.submenus.push(menu);
          }
        })
      }
      if (this.submenus.length > 0) {
        this.submenus.forEach(submenu => {
          this.main_menus.forEach(menu => {
            if (submenu.parent_website_menu_id === menu.id) {
              menu.sub_menus.push({...submenu, active_menu: menu.name});
            }
          })
        })
      }
    },
    chooseMenu(submenu) {
      let pattern = /^((http|https|ftp):\/\/)/;
      let url_ = null;

      if (submenu.is_redirect_url) {
        if(!pattern.test(submenu.redirect_url)) {
           url_ = "http://" + submenu.redirect_url;
           window.open(url_,'_blank');
        }else{
          window.open(submenu.redirect_url,'_blank');
        }
      } else if((submenu.hasOwnProperty('sub_menus') && submenu.sub_menus.length===0)) {
        this.$router.push({
          name: 'dynamic-menu',
          params: {
            tagline: submenu.tagline,
            description: submenu.description,
            images: submenu.images,
            name: submenu.name,
            cover_image: submenu.cover_image,
            active_menu : submenu.active_menu
          }
        })
      } else if(!submenu.hasOwnProperty('sub_menus') && !submenu.is_redirect_url && !!submenu.parent_website_menu_id) {
        this.$router.push({
          name: 'dynamic-menu',
          params: {
            tagline: submenu.tagline,
            description: submenu.description,
            images: submenu.images,
            name: submenu.name,
            cover_image: submenu.cover_image,
            active_menu : submenu.active_menu
          }
        })
      }
    }
  },
  data: () => ({
    show_modal: false,
    mobile_nav: false,
    active_policy: null,
    book_url: null,
    showlogin: false,
    isProduction: true,
    gmaps_url: null,
    w_a_statement: false,
    other_statement: false,
    numberOfLinks: 0,
    main_menus: [],
    submenus: [],
    active_menu: null,
    tripAdvisorsJson: tripAdvisorsJson,
    enable_trip_advisor: process.env.VUE_APP_ENABLE_TRIP_ADVISOR,
    message_height: '0'
  }),
  computed: {
    authstatus() {
      return !!this.$store.state.user_info.key;
    },
    access_status() {
      return this.$store.state.access_status;
    },
    loading() {
      return this.$store.state.loading;
    },
    social_links() {
      return {
        facebook: !!this.web_config && this.web_config.facebook_url || '',
        twitter: !!this.web_config && this.web_config.twitter_url || '',
        pinterest: !!this.web_config && this.web_config.pinterest_url || '',
        instagram: !!this.web_config && this.web_config.instagram_url || ''
      }
    },
    hotel() {
      return this.$store.state.hotel;
    },
    latitude() {
      return parseFloat(this.$store.state.hotel.latitude);
    },
    longitude() {
      return parseFloat(this.$store.state.hotel.longitude);
    },
    packages() {
      return !!this.hotel && this.hotel.package_rates.filter(_package => !!_package.active).length > 0;
    },
    gallery() {
      return !!this.hotel && this.$store.state.not_touched_images.length > 0;
    },
    discounts() {
      return !!this.hotel && this.$store.getters.availableDiscounts.length > 0;
    },
    web_config() {
      return !!this.hotel && this.$store.state.web_config;
    },
    master_config() {
      return !!this.$store.state.web_config.is_master && this.$store.state.web_config.is_master || false;
    },
    website_menus() {
      return !!this.hotel && this.hotel.website_menus.filter(menu => menu.active === true);
    },
    theme () {
      const theme = !!this.web_config && this.web_config.theme
      return `${theme.toLowerCase()}-theme`
    },
    bookingColor() {
      return this.web_config.color_code
    },
    policies() {
      return this.web_config.property_website_config_policies || []
    },
    show_global_message() {
      return this.$store.state.show_global_message && this.web_config.global_message
    }
  },
};
