<style lang="scss" scoped>
article{
  margin: 0;
}

.card {
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
  h3 {
    text-transform: capitalize;
    font-size: 70%;
    margin: 0;
    word-break: break-word;
  }
  .icon-amenitie {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .actual-img {
    background-size: cover;
    height: 50px;
    width: 50px;
    position: absolute;
  }
  .content {
    margin: 0px auto;
  }
  .fallback-icon {
    flex: 1;
    display: flex;
    justify-content: center;
    stroke: grey;
    width: 40px;
    height: 40px;
  }
}

.card:hover {
  background-color: #f7f7f7;
  h3 {
    color: black;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  .icon-amenitie{
    .actual-img{
      background-image: none !important;
    }
  }

}


</style>
<template>
  <article class="card">
    <div class="content">
      <div class="icon-amenitie">
        <div class="actual-img" role="img" :alt="'Picture of ' + name" v-if="!!icon_url" :style="{'background-image': 'url(' + icon_url + ')'}"></div>
        <span class="fallback-icon" v-else>
          <star></star>
        </span>
        <img :alt="'Picture of ' + name" v-if="!!icon_url" :src="'' + icon_url" width="50px" height="50px" >
      </div>
      <h3><strong>{{name}}</strong></h3>
    </div>
  </article>
</template>
<script>
/* ============
 * Amenitie_card Component
 * ============
 *
 * todo: add documentation here!
 */
import { Star } from 'vue-feather-icon';

export default {
  components: {
    Star: Star.default
  },
  props: {
    icon: {
      required: false,
    },
    name: {
      required: true,
    },
  },
  computed: {
    icon_url() {
      return !!this.icon && this.icon.replace(/ /g, '%20');
    }
  },
};

</script>
