import {Loader} from 'vue-feather-icon';

import carousel from '@/components/general/carousel/carousel.vue';
import layout from '@/layouts/page/page.vue';
import homeCheckin from '@/components/home_checkin/home_checkin.vue';

import divider from '@/components/general/divider.vue';
import roomView from '@/components/room_view/room_view.vue';
import roomViewBoldTheme from '@/components/room_view_bold_theme/room_view_bold_theme.vue';
import roomViewExpressTheme from '@/components/room_view_express_theme/room_view_express_theme.vue';
import roomViewOverlayTheme from '@/components/room_view_overlay_theme/room_view_overlay_theme.vue';
import complexImages from './complex-images.vue';
import exploreCards from '@/pages/home/explore-cards.vue';
import exploreAreaCarousel from '@/components/explore-area-carousel/explore_area_carousel.vue';
import ExpressExploreArea from "../../components/ExpressExploreArea/ExpressExploreArea";
import welcomeTheme from '@/components/welcome-theme/welcome-theme.vue'
import ExpressWelcomeTheme from "../../components/welcome-theme/ExpressWelcomeTheme";
import AwardContainer from "../../components/awards/AwardContainer";
import ExpressRoomContainer from "../../components/room_view_express_theme/ExpressRoomContainer";
import ExpressAttractionContainer from "../../components/ExpressAttractions/ExpressAttractionContainer";

import {mapState} from 'vuex'

export default {
  components: {
    carousel,
    layout,
    homeCheckin,
    divider,
    roomView,
    complexImages,
    exploreCards,
    loader: Loader.default,
    roomViewBoldTheme,
    roomViewExpressTheme,
    exploreAreaCarousel,
    roomViewOverlayTheme,
    welcomeTheme,
    ExpressWelcomeTheme,
    ExpressExploreArea,
    ExpressAttractionContainer,
    AwardContainer,
    ExpressRoomContainer
  },
  beforeMount() {
    if (this.hotel) {
      const env = process.env;
      let url;

      url = `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&url=${window.location.href}`;
      this.book_url = url;
    }
  },
  mounted() {
    if (this.hotel === false) {
      this.$store.dispatch('setPropDetails');
    } else {
      document.getElementsByTagName('title')[0].innerHTML = 'Home | ' + this.hotel_name;
    }

    if (process.env.NODE_ENV !== 'production') {
      this.hide_border = true;
    }

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.screenWidth = document.documentElement.clientWidth
      })

      !function(d,s,id){

        var js, fjs=d.getElementsByTagName(s)[0];

        /*if (!d.getElementById(id)) {
          js=d.createElement(s);
          js.id=id;
          js.src='https://weatherwidget.io/js/widget.min.js';
          fjs.parentNode.insertBefore(js,fjs);
        }*/

        js=d.createElement(s);
        js.id=id;
        js.src='https://weatherwidget.io/js/widget.min.js';
        fjs.parentNode.insertBefore(js,fjs);
      }(document,'script','weatherwidget-io-js');
    })
  },
  computed: {
    ...mapState({
      hotel_id: state => state.hotel.id,
      hotel_name: state => state.hotel.name,
      cover_photo: state => state.hotel.cover_photo,
      images: state => state.hotel.images,
      hotel: state => state.hotel !== null,
      tag_line: state => state.hotel.tag_line,
      additional_details: state => state.hotel.additional_details,
      hotelDetails: state => state.hotel,
      language: state => state.language_code
    }),
    hotel() {
      return this.$store.state.hotel !== null;
    },
    latitude() {
      return parseFloat(this.$store.state.hotel.latitude);
    },
    longitude() {
      return parseFloat(this.$store.state.hotel.longitude);
    },
    borderStyle() {
      return !!this.web_config && (this.border_style.border += this.borderColor);
    },
    configRoomTypes() {
      return this.web_config && this.web_config.website_config_room_types
    },
    rooms() {
      this.web_config.website_config_room_types.forEach(item =>{
        this.roomWithCovers.forEach((room,index) =>{
          if(room.id === item.room_type_id){
            this.$set(room, "order_no", item.order_no);
          }
        })

      })

      this.roomWithCovers.forEach((room,index) =>{
        if(!room.order_no){
          this.$set(room, "order_no", index+80);
        }
      })
      this.roomWithCovers.sort((a, b) => a.order_no - b.order_no)

      if (this.configRoomTypes.length > 0) {

        return this.roomWithCovers.filter(room => this.configRoomTypes.some(item => room.id === item.room_type_id))
          .filter(room => {
            return this.accessible ? room : !room.accessible
          })
      } else {

        return this.roomWithCovers.filter(room => room.active)
          .filter(room => {
            return this.accessible ? room : !room.accessible
          })
      }
    },
    roomWithCovers() {
      return this.$store.state.room_types.map((room) => {
        let images = room.images.slice(0);
        !!room.cover_photo && images.unshift({url: room.cover_photo,caption: room.cover_photo_caption});
        return {...room, cover_pics: images}
      });
    },
    showAccessibleCheckbox() {
      return this.roomWithCovers.some(room => room.accessible)
    },
    roomCovers() {
      let property_default_images = this.web_config.property_website_images.PROPERTY_SECONDARY;

      let filtered_images = null;
      if (property_default_images !== null && property_default_images.length > 0) {
        filtered_images = property_default_images.filter(image => {
          return image !== null;
        }).map(image => (image));
      }
      if (filtered_images === null || filtered_images.length === 0) {
        return !!this.rooms && this.rooms.map(room => room.cover_pics.length > 0 && ({url: room.cover_pics[0].url}));
      }

      return filtered_images;
    },
    featureAttractions() {
      return this.$store.state.feature_attractions.filter(item => item.active);
    },
    checkAwards() {
      return this.hotelDetails.property_website_config_awards.length
    },
    hotelImages() {
      return !!this.cover_photo ? [{url: this.cover_photo}].concat(this.images) : this.images;
    },
    room_amenities() {
      return this.$store.getters.roomAmenities;
    },
    isLoading() {
      return this.$store.state.loading;
    },
    property_code() {
      return this.$store.state.property_code;
    },
    borderColor() {
      return !!this.web_config && this.web_config.color_code;
    },
    web_config() {
      return this.$store.state.web_config;
    },
    child_properties() {
      return !!this.web_config && this.web_config.website_child_properties.filter(child => child.active);
    },
    master_config() {
      return !!this.web_config.is_master && this.web_config.is_master || false;
    },
    bookingColor() {
      return this.web_config.booking_color_id
    },
    theme() {
      const theme = !!this.web_config && this.web_config.theme
      return `${theme.toLowerCase()}-theme`
    },
    activeCategories() {
      let activeAttractions = this.hotelDetails.property_explore_attractions.filter(item => item.active)
      return this.categories.filter(c => activeAttractions.find(attr => attr.name === c.name))
    }
  },
  data: () => ({
    weather_url: process.env.VUE_APP_WEATHER_WIGDET_URL,
    mobile_nav: false,
    googleMapsUrl: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAVHklv9R4x00LvMoFkk0wAMVWEcKZLAxw",
    hide_border: false,
    border_style: {
      border: "10px solid ",
    },
    book_url: null,
    access_status: false,
    categories: [
      {
      id: 1,
      image: null,
      name: 'ATTRACTION',
      tags: ['landmarks', 'amusementparks', 'tours', 'museums', 'parks'],
    }, {
      id: 2,
      image: null,
      name: 'BARS',
      tags: ['lounges', 'breweries', 'wine_bars', 'wineries', 'divebars'],
    }, {
      id: 3,
      image: null,
      name: 'TRANSPORTATION',
      tags: ['taxis', 'buses', 'busstations', 'airports'],
    }, {
      id: 4,
      image: null,
      name: 'ENTERTAINMENT',
      tags: ['casinos', 'festivals', 'cinemas', 'theater'],
    }, {
      id: 5,
      image: null,
      name: 'ACTIVITIES',
      tags: ['golf', 'kids_activities', 'surfshop', 'climbing', 'diving', 'bungeejumping', 'gliding', 'gokarts', 'hiking', 'jetskis', 'mountainbiking', 'parasailing', 'rock_climbing', 'skiing', 'skydiving', 'snorkeling', 'surfing'],
    }, {
      id: 6,
      image: null,
      name: 'TOURS',
      tags: ['tours'],
    }],
    accessible: false,
    screenWidth: document.documentElement.clientWidth
  }),
  methods: {
    featureImages(item) {
      let imageResults = [];
      if (this.featureAttractions.length > 0) {
        item.website_feature_attraction_images.forEach((element) => {
          if (element.website_feature_attraction_id === item.id) {
            element.url = element.image_url;

            let model  = element;
            this.$set(model, "title", item.title);

            imageResults.push(model);
          }
        })

        return imageResults;
      } else {
        return imageResults;
      }

    },
    goTo(item) {
      if (item && item.split('.')[0] === 'www') {
        window.open(`https://${item}, _blank`);
      } else {
        window.open(`${item}`, '_blank');
      }
    },
    goBook(value) {
      const env = process.env;
      const url =
        `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&from=${value.from}&to=${value.to}&guests=${value.adults}&skip_search=true&property_id=${this.hotel_id}&url=${window.location.href}`;
      window.location.href = url;
    },
    bookNow() {
      const env = process.env;
      let url;

      url = `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&url=${window.location.href}`;
      window.location.href = url;
    }
  }
};
