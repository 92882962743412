<template>
	<button :aria-label="`Explore ${title}`" class="e-card" role="button">
		<div class="background" :style="{'background-image': 'url('+ sanitizeUrl(background) +')'}">
		</div>
		<div class="content">
			<h4>{{title}}</h4>

      <div class="action-button">
				<button style="text-transform: uppercase" role="button" tabindex="0">{{ $t('lbl_explore') }}</button>
			</div>
		</div>
	</button>
</template>
<script>
export default {
  props: ["title", "background"],
  methods: {
    sanitizeUrl(url) {
      return url !== undefined && url !== null && url.replace(/ /g, "%20");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~css_vars";
@import "~mixins";

.e-card {
  border-radius: 0;
  border: none;
  min-width: 280px;
  min-height: 340px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    transition: transform 0.4s ease;
  }
  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    .action-button,
    h4 {
      opacity: 0;
      text-transform: uppercase;
      color: white;
      font-weight: 300;
      font-family: "Gilda Display";
      font-size: 2rem;
      margin: 0px;
      margin-bottom: 10px;
    }
    button {
      background-color: transparent;
      border-radius: 100%;
      color: white;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 300;
      height: 80px;
      width: 80px;
      padding: 0px;
      min-width: unset;
      z-index: 10;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 80px;
        height: 80px;
        border: solid 1px rgba(white, 0.8);
        border-radius: 100%;
        z-index: -1;
      }
      &:hover {
        color: $black;
        &::after {
          background-color: white;
        }
      }
    }
    .action-button {
      transform: translateY(100px);
      width: auto;
    }
    h4 {
      transform: translateY(40px);
    }
  }
  &:hover,
  &:focus {
    .background {
      transition: all 0.4s ease-out 0.1s;
      transform: scale(1.1);
    }
    .content {
      background-color: rgba(black, 0.4);
      z-index: 5;
    }
    .content .action-button,
    .content h4 {
      transition: all 0.4s ease-out 0.1s;
      opacity: 1;
    }
    .content .action-button {
      transform: translateY(0px);
    }
    .content h4 {
      transform: translateY(0px);
    }
  }
}

@include media("<=tablet") {
  .e-card {
    .background {
      transition: all 0.4s ease-out 0.1s;
      transform: scale(1.1);
    }
    .content {
      background-color: rgba(black, 0.4);
      z-index: 5;
    }
    .content .action-button,
    .content h4 {
      transition: all 0.4s ease-out 0.1s;
      opacity: 1;
    }
    .content .action-button {
      transform: translateY(0px);
    }
    .content h4 {
      transform: translateY(0px);
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  button {
    min-width: 50px;
  }
}
</style>
