import CommonUtils from "../utils";

export default {
  setHotel(state, payload) {
    localStorage.setItem("hk-prop", payload.code);
    state.hotel = payload;
  },
  setLanguageCode(state, payload) {
    CommonUtils.setLanguageCode(payload.code);
    state.language_code = payload;
  },
  setRoomTypes(state, payload) {
    state.room_types = payload;
  },
  setAmenities(state, payload) {
    state.amenities = payload;
  },
  setPropertyCode(state, payload) {
    state.property_code = payload;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setMinimumDate(state, payload) {
    state.minimum_date = payload;
  },
  addDetailedProperty(state, payload) {
    let id = payload.id;
    state.properties_w_details = {
      ...state.properties_w_details,
      [id]: payload,
    };
  },
  setDeviceType(state, payload) {
    state.device_type = payload;
  },
  setExploreTab(state, payload) {
    state.explore_tab = payload;
  },
  setWebConfig(state, payload) {
    state.web_config = payload;
  },
  setFeatureAttractions(state, payload) {
    state.feature_attractions = payload;
  },
  setGalleryImages(state, payload) {
    let array1 = [];
    let array2 = [];
    let array3 = [];
    let i = 1;
    payload.forEach((img) => {
      if (i === 1) {
        array1.push(img);
        i++;
      } else if (i === 2) {
        array2.push(img);
        i++;
      } else if (i === 3) {
        array3.push(img);
        i = 1;
      }
    });
    let all_arrays = { array1: array1, array2: array2, array3: array3 };
    state.gallery_images = all_arrays;
    state.not_touched_images = payload;
  },
  setAccessStatus(state, payload) {
    state.access_status = payload;
  },
  setGlobalMessage(state, payload) {
    state.show_global_message = payload;
  },
};
