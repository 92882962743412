import {ChevronRight, ZoomIn, X, MapPin} from 'vue-feather-icon'

var autoSlideTimer;

export default {
  mounted() {
    this.currentSlide = 0; //parseInt((this.carouselImages.length - 1) / 2, 10) > 0 ? parseInt((this.carouselImages.length - 1) / 2, 10) : 1;
    if (this.carouselImages.length > 1 && this.autoPlay === true) {
      this.setAutoSlide();
    }
  },
  components: {
    ChevronIcon: ChevronRight.default,
    ZoomIn: ZoomIn.default,
    X: X.default,
  },
  props: {
    carouselImages: {
      type: Array,
      required: false,
      default: () => ([{
        url: null,
      }]),
    },
    size: {
      type: String,
      required: false,
      default: 'full',
    },
    autoPlay: {
      type: Boolean,
      required: false,
      default: false,
    },
    animated: {
      type: Boolean,
      required: false,
      default: false,
    },
    zoomable: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDots: {
      type: Boolean,
      required: false,
      default: false,
    },
    dotsPosition: {
      type: String,
      required: false,
      default: 'bottom',
    },
    imageType: {
      type: String,
      required: false,
      default: 'Room Image'
    }
  },
  data: () => ({
    currentSlide: null,
    showLightbox: false,
  }),
  computed: {
    hotel() {
      return this.$store.state.hotel !== null;
    },
    web_config() {
      return !!this.hotel && this.$store.state.web_config;
    },
    positiveSlide() {
      return this.currentSlide < 0 ? this.currentSlide * -1 : this.currentSlide;
    },
    dotsPositionCss() {
      switch (this.dotsPosition) {
        case 'top': {
          return {
            'top': '30px',
          }
        }
        case 'bottom': {
          return {
            'bottom': '10px',
          }
        }
        case 'express': {
          return {
            'bottom': '10px'
          }
        }
      }
    },
    access_status() {
      return this.$store.state.access_status;
    },
    theme () {
      const theme = (!!this.web_config && this.web_config.theme) || 'classic'
      return `${theme.toLowerCase()}-theme`
    },
    filteredImages() {
      return this.carouselImages.filter(img => img.url)
    },
    colorCode() {
      return this.web_config.color_code
    }
  },
  methods: {
    sanitizeUrl(url) {
      return url !== undefined && url !== null && url.replace(/ /g, '%20');
    },
    next(origin) {
      this.animated && this.setAutoSlide();
      this.currentSlide = (this.currentSlide + 1) % this.filteredImages.length;

      if (origin === 'lightbox') {
        this.$refs.esclistener.focus();
      }
    },
    previous(origin) {
      this.animated && this.setAutoSlide();
      let imagesLength = this.filteredImages.length;
      this.currentSlide - 1 < 0 ? this.currentSlide = imagesLength - 1 : this.currentSlide--;

      if (origin === 'lightbox') {
        this.$refs.esclistener.focus();
      }
    },
    setManually(index) {
      this.setAutoSlide();
      this.currentSlide = (index) % this.filteredImages.length;
    },
    setAutoSlide() {
      clearInterval(autoSlideTimer);
      autoSlideTimer = setInterval(() => {
        this.next();
      }, 4000);
    },
    triggerLightbox() {
      this.showLightbox = true;
      this.$refs.esclistener.focus();
      this.$emit('bigBoxTriggered', true)
    },
    close() {
      this.showLightbox = false;
      this.$emit('bigBoxTriggered', false)
    }
  },
};
