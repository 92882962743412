<template>
  <div style="position: relative">

    <button @click="goLeft()" class="right" v-if="showScrollButtons" :class="{'hidden-arrow': !scrollLeftt}">
      <chevron-left></chevron-left>
    </button>

    <div class="explore-area-carousel" ref="explore">
      <e-card v-for="(category, index) in activeCategories" :background="the_web_images[category.name].url"
              :title="category.label.toUpperCase()" :key="category.name"
              @click.native="setExploreCategory(category, index)"></e-card>
    </div>

    <button @click="goRight()" class="left" v-if="showScrollButtons" :class="{'hidden-arrow': !scrollRightt}">
      <chevron-right></chevron-right>
    </button>
  </div>
</template>
<script>
  import eCard from './explore-card.vue'
  import {ChevronLeft, ChevronRight} from 'vue-feather-icon'

  export default {
    components: {
      eCard,
      ChevronLeft: ChevronLeft.default,
      ChevronRight: ChevronRight.default
    },
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
      this.checkChevrons();
    },
    data() {
      return {
        categories: [
          {
          id: 1,
          image: null,
          name: 'ATTRACTION',
          label: this.$t('lbl_attraction'),
          tags: ['landmarks', 'amusementparks', 'tours', 'museums', 'parks'],
        }, {
          id: 2,
          image: null,
          name: 'BARS',
          label: this.$t('lbl_bars'),
          tags: ['lounges', 'breweries', 'wine_bars', 'wineries', 'divebars'],
        }, {
          id: 3,
          image: null,
          name: 'TRANSPORTATION',
          label: this.$t('lbl_transportation'),
          tags: ['taxis', 'buses', 'busstations', 'airports'],
        }, {
          id: 4,
          image: null,
          name: 'ENTERTAINMENT',
          label: this.$t('lbl_entertainment'),
          tags: ['casinos', 'festivals', 'cinemas', 'theater'],
        }, {
          id: 5,
          image: null,
          name: 'ACTIVITIES',
          label: this.$t('lbl_activities'),
          tags: ['golf', 'kids_activities', 'surfshop', 'climbing', 'diving', 'bungeejumping', 'gliding', 'gokarts', 'hiking', 'jetskis', 'mountainbiking', 'parasailing', 'rock_climbing', 'skiing', 'skydiving', 'snorkeling', 'surfing'],
        }, {
          id: 6,
          image: null,
          name: 'TOURS',
          label: this.$t('lbl_tours'),
          tags: ['tours'],
        }],
        windowWidth: window.innerWidth,
        scrollLeftt: true,
        scrollRightt: true,
      }
    },
    computed: {
      hotel() {
        return this.$store.state.hotel !== null;
      },
      the_web_images() {
        let to_extract = this.categories.map(category => category.name);
        let new_struct = {};
        to_extract.forEach(extract => {
          let struct = {
            title: extract,
            url: null,
          };
          if (!!this.web_config && this.web_config.hasOwnProperty(extract.toUpperCase()) && this.web_config[extract.toUpperCase()].length > 0) {
            struct.url = this.web_config[extract.toUpperCase()][0].image_url;
          }
          if (struct.url === null) {
            struct.url = require(`@/assets/static/explore_categories/${extract.toUpperCase()}.jpg`);
          }
          new_struct[extract] = struct;
        });
        return new_struct;
      },
      web_config() {
        return this.$store.state.web_config && this.$store.state.web_config.property_website_images;
      },
      activeAttractions() {
        return !!this.hotel && this.$store.state.hotel.property_explore_attractions.filter(item => item.active)
      },
      activeCategories() {
        return this.categories.filter(c => this.activeAttractions.find(attr => attr.name === c.name))
      },
      showScrollButtons() {
        if (this.windowWidth >= 1260) {
          return this.activeCategories.length > 4
        }
        if (this.windowWidth < 1260 && this.windowWidth >= 970) {
          return this.activeCategories.length > 3
        }
        if (this.windowWidth < 970 && this.activeCategories >= 660) {
          return this.activeCategories.length > 2
        }
        return this.activeCategories.length > 1
      },
    },
    methods: {
      setExploreCategory(category, index) {
        category.id = index;
        this.$store.commit('setExploreTab', category);
        this.$router.push('/explore');
      },
      goLeft() {
        let elem = document.getElementsByClassName('explore-area-carousel')[0]

        elem.scroll({
          left: elem.scrollLeft - document.getElementsByClassName('e-card')[0].scrollWidth,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkChevrons()
        },300)
      },
      goRight() {

        let elem = document.getElementsByClassName('explore-area-carousel')[0]

        elem.scroll({
          left: elem.scrollLeft + document.getElementsByClassName('e-card')[0].scrollWidth,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkChevrons()
        },300)
      },
      checkChevrons() {

        let elem = document.getElementsByClassName('explore-area-carousel')[0];
        if(elem.scrollLeft === 0) {
          this.scrollLeftt = false
        } else if(elem.scrollLeft > 0) {
          this.scrollLeftt = true;
        }

        let scrollLefti = parseInt(elem.scrollLeft.toFixed(0))

        if(scrollLefti + parseInt(elem.offsetWidth.toFixed(0)) === parseInt(elem.scrollWidth.toFixed(0))) {
          this.scrollRightt = false;
        }
        else if(scrollLefti + parseInt(elem.offsetWidth.toFixed(0)) !== parseInt(elem.scrollWidth.toFixed(0))) {
          this.scrollRightt = true;
        }
      }
    },
  }

</script>
<style lang="scss" scoped src="./explore_area_carousel.scss">
</style>
