import { render, staticRenderFns } from "./ExpressDiscounts.vue?vue&type=template&id=6e233336&scoped=true"
import script from "./ExpressDiscounts.vue?vue&type=script&lang=js"
export * from "./ExpressDiscounts.vue?vue&type=script&lang=js"
import style0 from "./ExpressDiscounts.vue?vue&type=style&index=0&id=6e233336&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e233336",
  null
  
)

export default component.exports