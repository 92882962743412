<style lang="scss">
  @import '~mixins';

  figure {
    margin: 0;
    padding: 0;
  }

  .atmosphere {
    .el-divider {
      margin-bottom: 20px;
    }

    .el-tabs__content {
      overflow: unset;
    }

    .el-tabs__item {
      text-transform: uppercase;
    }

    .activateHardware {
      translate: transformZ(0);
    }

    .bckg {
      position: absolute;
      bottom: 0px;
      height: 100%;
      min-height: 300px;
      width: 100%;
      padding-top: 330px;
    }

    .access-tabs {
      .tab-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        /*border-bottom: 2px solid #E4E7ED;*/

        button {
          border-radius: 0;
          padding: 0;
          width: auto;
          min-width: unset;
          font-size: 14px;
          font-weight: 500;
          height: 40px;
          letter-spacing: 1px;
          box-sizing: content-box;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: #ddd;
            height: 2px;
          }
        }

        button + button {
          margin-left: 40px;
        }

        .active-tab {
          color: #409EFF;
          /*border-bottom: 2px solid #409EFF;*/

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: #409EFF;
            height: 2px;
          }
        }

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: #ddd;
          height: 2px;
        }
      }
    }
  }

  @include media('>phone') {
    .atmosphere .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
    .atmosphere .bckg {
      transition: all .4s ease;
    }
  }

  @include media('<=phone') {
    .el-tabs__nav-scroll {
      overflow: -moz-scrollbars-none; // Firefox
      overflow-x: scroll;
      -ms-overflow-style: none; // IE 10+
      &::-webkit-scrollbar {
        display: none; // Safari and Chrome
      }
    }
  }

  .loader {
    display: flex;
    min-height: 180px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .atmosphere .el-tabs__item button {
    min-width: unset;
    display: inline-block;
    padding: 0px;
    border-radius: 0px;
    margin: 0px;
    font-weight: unset;
    font-size: inherit;
    text-transform: uppercase;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .atmosphere {
      .el-tabs__nav-wrap::after {
        background-color: white;
      }
    }


  }

</style>
<template>
  <layout v-if="hotel">
    <div slot="content" tabindex="-1" id="main_content">
      <div class="loader" v-if="!hotel">
        <loader></loader>
      </div>
      <div v-else class="atmosphere">
        <figure class="activateHardware" style="position: relative; min-height: 330px;"
                v-if="theme !== 'express-theme'">
          <div class="bckg" role="img" :alt="'Picture of '+tab_data.name"
               :style="{'background-image': 'url('+ current_image +')','background-size' : 'cover' , 'background-position' : 'center', 'background-position ' : ' center',}"></div>
        </figure>
        <div class="map" style="position: relative" v-if="theme === 'express-theme'">
          <attractions-map :results="map_results"/>
        </div>

        <el-tabs v-if="!access_status" aria-label="Sub Navigation" v-model="stringifiedModel" id="tabs"
                 tab-position="top" @tab-click="setImage()">
          <el-tab-pane :lazy="true" v-for="(category) in activeCategories" :key="category.name">
            <span :style="{ color: bookingColor }" slot="label">{{ category.label }}</span>
            <website-attraction :enable-yelp="enableYelp(category)" :tab-type="category.name"
                                @eventDone="mapAttractions(category.name, $event)" :attraction-types="category.tags"
                                :attractions="featured(category)">
            </website-attraction>
          </el-tab-pane>
        </el-tabs>

        <div v-if="access_status" class="access-tabs">
          <div class="tab-buttons" aria-label="Attractions nav menu">
            <button
              :aria-label="category === activeCategories[activeTab] ? `${category.name} tab, active` : `Go to ${category.name} tab`"
              :class="{ 'active-tab': category === activeCategories[activeTab] }"
              @click="setImage(), activeTab = index" v-for="(category, index) in activeCategories"
              :key="category.name">{{ category.label }}
            </button>
          </div>

          <div class="content" v-if="category === activeCategories[activeTab]"
               v-for="(category, index) in activeCategories" :key="category.name">
            <website-attraction :enable-yelp="enableYelp(category)" :tab-type="category.name"
                                @eventDone="mapAttractions(category.name, $event)" :attraction-types="category.tags"
                                :attractions="featured(category)">
            </website-attraction>
          </div>
        </div>
      </div>
    </div>
  </layout>
  <div class="loader" v-else>
    <loader></loader>
  </div>
</template>
<script>
  import {Loader} from 'vue-feather-icon';
  import websiteAttraction from '@/components/attractions/website-attraction.vue';
  import layout from '@/layouts/page/page.vue';
  import divider from '@/components/general/divider.vue';
  import attractionsMap from '@/components/attractions/attractions-map.vue'

  import {mapState} from 'vuex'

  export default {
    components: {
      websiteAttraction,
      layout,
      divider,
      Loader: Loader.default,
      attractionsMap
    },
    mounted() {

      this.$nextTick(() => {
        let active_bar = document.getElementsByClassName('el-tabs__active-bar');

        if (active_bar) {
          active_bar[0].style.backgroundColor = this.bookingColor
        }
      })

      if (this.hotel === false) {
        this.$store.dispatch('setPropDetails').then(response => {
          this.categories.forEach(category => {
            category.image = this.the_web_images[category.name].url || require(`@/assets/static/explore_categories/${category.name}.jpg`);
          });
          if (this.$store.state.explore_tab === null) {
            this.current_image = this.activeCategories[0].image;
            this.tab_data = this.activeCategories[0];

          } else {
            this.activeTab = this.$store.state.explore_tab.hasOwnProperty('id') ? this.$store.state.explore_tab.id : '0';
            this.current_image = this.activeCategories[this.activeTab].image;
            this.tab_data = this.activeCategories[this.activeTab];
          }
        });
      } else {
        document.getElementsByTagName('title')[0].innerHTML = 'Explore | ' + this.hotel_name;
        this.categories.forEach(category => {
          category.image = this.the_web_images[category.name].url || require(`@/assets/static/explore_categories/${category.name}.jpg`);
        });
        if (this.$store.state.explore_tab === null) {
          this.current_image = this.activeCategories[0].image;
          this.tab_data = this.activeCategories[0];
        } else {
          this.activeTab = this.$store.state.explore_tab.hasOwnProperty('id') ? this.$store.state.explore_tab.id : '0';
          this.current_image = this.activeCategories[this.activeTab].image;
          this.tab_data = this.activeCategories[this.activeTab];

        }
      }
    },
    data() {
      return {
        categories: [{
          id: 1,
          image: null,
          name: 'ATTRACTION',
          label: this.$t('lbl_attraction'),
          tags: ['landmarks', 'amusementparks', 'tours', 'museums', 'parks']
        }, {
          id: 2,
          image: null,
          name: 'BARS',
          label: this.$t('lbl_bars'),
          tags: ['lounges', 'breweries', 'wine_bars', 'wineries', 'divebars']
        }, {
          id: 3,
          image: null,
          name: 'TRANSPORTATION',
          label: this.$t('lbl_transportation'),
          tags: ['taxis', 'buses', 'busstations', 'airports']
        }, {
          id: 4,
          image: null,
          name: 'ENTERTAINMENT',
          label: this.$t('lbl_entertainment'),
          tags: ['casinos', 'festivals', 'cinemas', 'theater']
        }, {
          id: 5,
          image: null,
          name: 'ACTIVITIES',
          label: this.$t('lbl_activities'),
          tags: ['golf', 'kids_activities', 'surfshop', 'climbing', 'diving', 'bungeejumping', 'gliding', 'gokarts', 'hiking', 'jetskis', 'mountainbiking', 'parasailing', 'rock_climbing', 'skiing', 'skydiving', 'snorkeling', 'surfing']
        }, {
          id: 6,
          image: null,
          name: 'TOURS',
          label: this.$t('lbl_tours'),
          tags: ['tours']
        }],
        current_image: null,
        activeTab: 0,
        attraction_results: [],
        bars_results: [],
        transportation_results: [],
        entertainment_results: [],
        activities_results: [],
        tours_results: [],
        tab_data: null,
      }
    },
    computed: {
      ...mapState({
        hotel_name: state => state.hotel.name
      }),

      hotel() {
        return this.$store.state.hotel !== null;
      },
      stringifiedModel: {
        get() {
          return this.activeTab.toString();
        },
        set($value) {
          this.activeTab = $value;
        },
      },
      the_web_images() {
        let to_extract = this.categories.map(category => category.name);
        let new_struct = {};
        to_extract.forEach(extract => {
          let struct = {
            title: extract,
            url: null,
          };
          if (!!this.web_config && this.web_config.hasOwnProperty(extract.toUpperCase()) && this.web_config[extract.toUpperCase()].length > 0) {
            struct.url = this.web_config[extract.toUpperCase()][0].image_url;
          }
          if (struct.url === null) {
            struct.url = require(`@/assets/static/explore_categories/${extract}.jpg`);
          }
          new_struct[extract] = struct;
        });
        return new_struct;
      },
      web_config() {
        return this.$store.state.web_config && this.$store.state.web_config.property_website_images;
      },
      activeAttractions() {
        return this.hotel && this.$store.state.hotel.property_explore_attractions.filter(item => item.active)
      },
      activeCategories() {
        return this.categories.filter(c => this.activeAttractions.find(attr => attr.name === c.name))
      },
      access_status() {
        return this.$store.state.access_status
      },
      bookingColor() {
        return this.$store.state.web_config.color_code
      },
      theme() {
        let theme = !!this.$store.state.web_config && this.$store.state.web_config.theme
        return `${theme.toLowerCase()}-theme`
      },
      map_results() {
        let active_category = this.activeCategories.find((item, index) => index === parseInt(this.activeTab))

        return this[`${active_category.name.toLowerCase()}_results`]
      }
    },
    methods: {
      setImage() {
        let active_category = this.activeCategories.find((item, index) => index === parseInt(this.activeTab))
        this.tab_data = active_category

        this.$store.commit('setExploreTab', active_category);
        this.current_image = active_category.image;
      },
      featured(item) {
        return this.activeAttractions.find(att => att.name === item.name).property_explore_attraction_sequences
      },
      enableYelp(item) {
        return this.activeAttractions.find(att => att.name === item.name).enable_yelp
      },
      mapAttractions(type, data) {
        switch (type) {
          case 'ATTRACTION': {
            this.attraction_results = data.results
            break;
          }
          case 'BARS': {
            this.bars_results = data.results
            break;
          }
          case 'TRANSPORTATION': {
            this.transportation_results = data.results
            break;
          }
          case 'ENTERTAINMENT': {
            this.entertainment_results = data.results
            break;
          }
          case 'ACTIVITIES': {
            this.activities_results = data.results
            break;
          }
          case 'TOURS': {
            this.tours_results = data.results
            break;
          }
          default: {
            this.attraction_results = data.results
            break;
          }
        }
      }
    }
  }

</script>
