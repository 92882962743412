import Vue from "vue";
import App from "./app.vue";
import store from "./store";
import VueRouter from "vue-router";
import routes from "./routes";
import VueAnnouncer from "vue-announcer";
import "./assets/sass/app.scss";
import "./axios_initialization";
import VueI18n from "vue-i18n";

const messages = {};

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});

import {
  Tabs,
  TabPane,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Button,
  Alert,
  Notification,
  Loading,
  Checkbox,
  InputNumber,
  Popover,
  Form,
  FormItem,
  Input,
} from "element-ui";

import "babel-polyfill";
import "isomorphic-fetch";

import "es6-promise/auto";

import Es6Promise from "es6-promise";
import VueGtm from "vue-gtm";

Es6Promise.polyfill();

Vue.component(Button.name, Button);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(Alert.name, Alert);
Vue.component(Checkbox.name, Checkbox);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Popover.name, Popover);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);
Vue.component(Input.name, Input);

Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$notify = Notification;

export const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

Vue.use(VueAnnouncer, {}, router);

if (process.env.VUE_APP_MODE === "master") {
  let link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = process.env.VUE_APP_MASTER_IMAGE;
  document.getElementsByTagName("head")[0].appendChild(link);
}

if (process.env.VUE_APP_ENABLE_GOOGLE_TAG_MANAGER === "true") {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
    enabled: process.env.VUE_APP_ENABLE_GOOGLE_TAG_MANAGER === "true",
    debug: process.env.NODE_ENV === "development",
    vueRouter: router,
  });
}

Vue.use(VueRouter);
Vue.config.debug = process.env.NODE_ENV === "development";

router.beforeEach((to, from, next) => {
  if (to.meta.nonMaster && process.env.VUE_APP_MODE === "master") {
    next({
      path: "/",
    });
  } else {
    next();
  }
});

import * as VueGoogleMaps from "./../node_modules/vue2-google-maps/src/main";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAVHklv9R4x00LvMoFkk0wAMVWEcKZLAxw",
    libraries: "places",
  },
});

require("./assets/sass/app.scss");

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
