export default {
  property_code: null,
  language_code: {
    name: null,
    code: null,
  },
  minimum_date: null,
  hotel: null,
  room_types: null,
  amenities: null,
  loading: false,
  current_reservation: null,
  initialDataStatus: false,
  api_fail: false,
  user_info: {
    username: null,
    key: null,
  },
  guest_details: null,
  guest_travelers: null,
  selected_traveler: null,
  guest_reservations: null,
  guest_detailed_reservations: {},
  properties_w_details: {},
  account_phase: null,
  summary_tab: "upcoming",
  device_type: null,
  explore_tab: {},
  main_color: {
    DarkVibrant: {
      r: 0,
      g: 0,
      b: 0,
    },
    DarkMuted: {
      r: 0,
      g: 0,
      b: 0,
    },
    LightVibrant: {
      r: 0,
      g: 0,
      b: 0,
    },
    Muted: {
      r: 0,
      g: 0,
      b: 0,
    },
    Vibrant: {
      r: 0,
      g: 0,
      b: 0,
    },
  },
  web_config: null,
  feature_attractions: null,
  gallery_images: {},
  not_touched_images: null,
  access_status: false,
  show_global_message: true,
};
