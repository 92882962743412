<template>
    <header id="express-header" :style="{ 'background-color': colorCode, 'top': headerTopValue }">
      <div class="logo" v-if="business_logo">
        <img :src="business_logo" :alt="`${hotel_name} Logo`" :style="{ 'border': `5px solid ${colorCode}` }">
      </div>

      <div class="header-content">

        <section title="Contact Us Section" class="contact-info">
          <address>
            <a target="_blank" :href="'tel:' + phone_no"><phone title="Phone Number"></phone> {{phone_no}}</a>
            <a target="_blank" :href="'mailto:' + email"><mail title="Email"></mail> {{email}}</a>
          </address>

          <div class="lang" @click="$emit('showLanguages')" v-if="languages.length > 1">
            <img src="../../assets/static/earth.svg" alt="">
            {{ language.name }}
          </div>
        </section>

        <div class="menu">

          <div @click="(mobile_nav = true)" class="mobile-menu">
            <menu-icon></menu-icon>
          </div>

          <nav id="navigation" role="navigation" :class="{'mobile-active': mobile_nav === true}">
            <div @click="(mobile_nav = false)" v-if="mobile_nav === true" class="close-icon">
              <x id="close-mobile-menu"></x>
            </div>
            <router-link aria-label="Go to Home Tab" target="_self" active-class="active" to="/">{{ $t('lbl_home') }}</router-link>
            <router-link aria-label="Go to Rooms Tab" v-if="!master_config" target="_self" active-class="active" to="/rooms">{{ $t('lbl_rooms') }}</router-link>
            <a href="#home_rooms" class="secondary-item" v-if="master_config">{{ $t('lbl_hotels') }}</a>
            <router-link aria-label="Go to Amenities Tab" v-if="!master_config" target="_self" active-class="active" to="/amenities">{{ $t('lbl_amenities') }}</router-link>
            <router-link aria-label="Go to Explore Tab" v-if="!master_config && activeCategories && activeCategories.length > 0" target="_self" active-class="active" to="/explore">{{ $t('lbl_explore') }}</router-link>
            <router-link aria-label="Go to Gallery Tab" target="_self" active-class="active" to="/gallery" v-if="!!gallery && !master_config ">{{ $t('lbl_gallery') }}</router-link>
            <router-link aria-label="Go to Packages tab" target="_self" active-class="active" to="/packages" v-if="!!packages && !master_config">{{ $t('lbl_packages') }}</router-link>
            <router-link aria-label="Go to Discounts Tab" target="_self" active-class="active" to="/discounts" v-if="!!discounts && !master_config">{{ $t('lbl_discounts') }}</router-link>

            <div class="dynamic-menus" v-if="main_menus.length>0" v-for="(menu,index) in main_menus">
              <div :class="{'active-menu': active_menu === menu.name}" class="main-menu">
                <span :aria-label="`${menu.is_parent ? 'Click to open submenu' : `Click to go to ${menu.name} tab`}`" style="white-space: nowrap"
                      @click="menu.is_parent ? showSubMenu ? showSubMenu = false : showSubMenu = true : chooseMenu(menu)">
                  {{menu.name}}
                </span>
                <div class="sub-menus" v-if="showSubMenu && menu.sub_menus.length>0" >
                  <a :aria-label="`This is a submenu, Redirect to ${submenu.name}`"
                     @click="chooseMenu(submenu); mobile_nav = false" v-for="submenu in menu.sub_menus"
                     :class="{'active_menu': active_menu === submenu.name}">
                    {{submenu.name}}
                  </a>
                </div>
              </div>
            </div>

            <router-link aria-label="Go to Inquiry Tab" target="_self" active-class="active" to="/inquiry" v-if="!master_config && web_config.display_contact_us">{{ $t('lbl_hk_website_inquiry') }}</router-link>

            <a aria-label="Go to Booking Module" target="_self" style="flex-shrink: 0; z-index: 0" class="book-button" @click="bookNow" v-if="!master_config">
              {{ $t('lbl_book_now') }}
            </a>
          </nav>

        </div>

      </div>

    </header>
</template>

<script>
  import { Menu, Phone, Mail, X, MapPin } from 'vue-feather-icon';
  import homeCheckin from '../home_checkin/home_checkin.vue'
  import locales from '../../../localization_keys'
  import {mapState} from 'vuex'

  export default {
    name: "express-header",
    components: {
      Phone: Phone.default,
      Mail: Mail.default,
      X: X.default,
      menuIcon: Menu.default,
      homeCheckin,
      MapPin: MapPin.default
    },
    props: ['globalMessage'],
    beforeMount() {
      this.languages = JSON.parse(JSON.stringify(locales))
      this.configure_dynamic_menus();
    },
    mounted() {
      let element = document.getElementById('express-header');
      element.style.setProperty('--hover-color', `${this.web_config.color_code}`)

      this.active_menu = this.$route.params.active_menu;
    },
    data() {
      return {
        languages: [],
        mobile_nav: false,
        book_url: null,
        main_menus: [],
        submenus: [],
        active_menu: null,
        showSubMenu: false,
        categories: [
          {
          id: 1,
          image: null,
          name: 'ATTRACTION',
          tags: ['landmarks', 'amusementparks', 'tours', 'museums', 'parks'],
        }, {
          id: 2,
          image: null,
          name: 'BARS',
          tags: ['lounges', 'breweries', 'wine_bars', 'wineries', 'divebars'],
        }, {
          id: 3,
          image: null,
          name: 'TRANSPORTATION',
          tags: ['taxis', 'buses', 'busstations', 'airports'],
        }, {
          id: 4,
          image: null,
          name: 'ENTERTAINMENT',
          tags: ['casinos', 'festivals', 'cinemas', 'theater'],
        }, {
          id: 5,
          image: null,
          name: 'ACTIVITIES',
          tags: ['golf', 'kids_activities', 'surfshop', 'climbing', 'diving', 'bungeejumping', 'gliding', 'gokarts', 'hiking', 'jetskis', 'mountainbiking', 'parasailing', 'rock_climbing', 'skiing', 'skydiving', 'snorkeling', 'surfing'],
        }, {
          id: 6,
          image: null,
          name: 'TOURS',
          tags: ['tours'],
        }]
      }
    },
    computed: {
      ...mapState({
        hotel_id: state => state.hotel.id,
        hotel_name: state => state.hotel.name,
        phone_no: state => state.hotel.phone_no,
        email: state => state.hotel.email,
        business_logo: state => state.hotel.business_logo,
        hotelDetails: state => state.hotel,
        language: state => state.language_code,
        address: state => state.hotel.address,
        global_message: state => state.show_global_message
      }),
      hotel() {
        return this.$store.state.hotel !== null;
      },
      master_config() {
        return !!this.$store.state.web_config.is_master && this.$store.state.web_config.is_master || false;
      },
      packages() {
        return !!this.hotel && this.$store.state.hotel.package_rates.filter(_package => !!_package.active).length > 0;
      },
      gallery() {
        return !!this.hotel && this.$store.state.not_touched_images.length > 0;
      },
      discounts() {
        return !!this.hotel && this.$store.getters.availableDiscounts.length > 0;
      },
      web_config() {
        return !!this.hotel && this.$store.state.web_config;
      },
      website_menus() {
        return !!this.hotel && this.$store.state.hotel.website_menus.filter(menu => menu.active === true);
      },
      colorCode() {
        return this.web_config.color_code
      },
      activeCategories() {
        let activeAttractions = this.hotelDetails.property_explore_attractions.filter(item => item.active)
        return this.categories.filter(c => activeAttractions.find(attr => attr.name === c.name))
      },
      headerTopValue() {
        if (!this.global_message) {
          return '0'
        } else {
          return `${this.globalMessage}px`
        }
      }
    },
    methods: {
      configure_dynamic_menus() {
        if (this.website_menus.length > 0) {
          this.website_menus.forEach(menu => {
            if ((menu.is_parent === true) || (menu.is_parent === false && menu.parent_website_menu_id === null)) {
              menu.sub_menus = [];
              this.main_menus.push({...menu, active_menu: menu.name});
            } else {
              this.submenus.push(menu);
            }
          })
        }
        if (this.submenus.length > 0) {
          this.submenus.forEach(submenu => {
            this.main_menus.forEach(menu => {
              if (submenu.parent_website_menu_id === menu.id) {
                menu.sub_menus.push({...submenu, active_menu: menu.name});
              }
            })
          })
        }
      },
      chooseMenu(submenu) {
        let pattern = /^((http|https|ftp):\/\/)/;
        let url_ = null;

        if (submenu.is_redirect_url) {
          if (!pattern.test(submenu.redirect_url)) {
            url_ = "http://" + submenu.redirect_url;
            window.open(url_,'_blank');
          }else{
            window.open(submenu.redirect_url,'_blank');
          }
        } else if(submenu.hasOwnProperty('sub_menus') && submenu.sub_menus.length ===0) {
          this.$router.push({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu: submenu.active_menu,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })
        }else if(!submenu.hasOwnProperty('sub_menus') && !submenu.is_redirect_url && !!submenu.parent_website_menu_id){
          this.$router.push({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu: submenu.active_menu,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })
        }
      },
      bookNow() {
        const env = process.env;
        let url;

        url = `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&url=${window.location.href}`;
        window.location.href = url;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  #express-header {
    --hover-color: #fff;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: fixed;
    top: 0;
    z-index: 101;
    right: 0;
    left: 0;
    padding: 0 100px;
    height: 80px;

    .logo{
      top: 9px;
      position: relative;

      img{
        width: 80px;
        height: 85px;
        border-radius: 4px;
      }
    }

    .header-content{
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .contact-info {
        color: #fff;
        font-size: 11px;
        z-index: 1;
        display: flex;
        justify-content: flex-end;

        address{
          display: flex;
          justify-content: flex-end;
          margin-right: 10px;
          margin-top: 5px;
          font-style: normal;

          a {
            padding: 0 5px;
            display: flex;
            align-items: center;

            svg {
              stroke: #fff;
              height: 16px;
            }
          }
        }

        .lang {
          display: flex;
          align-items: center;
          margin-left: 15px;
          color: #fff;
          margin-top: 5px;
          cursor: pointer;
          opacity: 0.9;
          font-size: 0.7rem;

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }

          &:hover{
            opacity: 1;
          }
        }
      }

      .menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .mobile-menu {
          display: none;
          cursor: pointer;

          svg {
            stroke: #fff;
          }
        }

        nav {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 16px;
          flex-wrap: wrap;

          a, .dynamic-menus{
            padding: 0 8px;
            font-size: .9rem;
            color: #fff;
            font-weight: 400;
            cursor: pointer;
            text-decoration: none;

            /*&.router-link-exact-active {
              border-bottom: 1px solid #fff;
            }

            &:hover{
              border-bottom: 1px solid #fff;
            }*/

            .main-menu {
              position: relative;
              cursor: pointer;
              .sub-menus {
                display: flex;
                position: absolute;
                border-top: 2px solid white;
                background-color: rgba(0, 0, 0, 0.55);
                min-width: 200px;
                top: 30px;
                right: -50%;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                padding: 12px 16px;
                z-index: 1;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                transform: translateY(1em);
                transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
                a {
                  padding-bottom: 5px;
                  margin: 0;
                  color: white;
                }
                /*a:hover {
                  border-bottom: 1px solid #fff;
                }*/
              }
              span{
                font-size: 0.9rem;
                font-weight: 400;
                color: #fff;
              }
            }
          }

          /*.active-menu {
            border-bottom: 1px solid #fff;
          }*/

          .book-button {
            border: none;
            border-radius: 4px;
            color: #333;
            font-size: .9rem;
            cursor: pointer;
            outline: none;
            position: relative;
            z-index: 1;
            background-color: #fff;
            padding: 5px 10px;

            &:hover {
              color: var(--hover-color);
              border: none;
            }

            &::before {
              background: unset;
            }
          }
        }
      }
    }
  }

  @include media('<=tablet') {
    header {
      z-index: 102;
      justify-content: space-between;
      padding: 0 10px;

      .header-content {

        .menu {
          justify-content: flex-end;
          height: 100%;
          .mobile-menu{
            display: flex;
            align-items: center;
          }

          nav#navigation {
            display: none;
          }

          nav#navigation.mobile-active {
            display: flex !important;
            overflow-y: auto;
            height: 100vh;
          }

          .contact-info{
            display: none;
          }

          nav{
            display: none;
            position: fixed;
            box-sizing: border-box;
            flex-direction: column;
            text-align: center;
            height: 100%;
            width: 100%;
            padding: 1rem 4rem;
            background-color: white;
            z-index: 99999;
            grid-column: full;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin-left: 0 !important;

            .close-icon{
              position: absolute;
              right: 25px;
              top: 20px;
              cursor: pointer;
            }

            a {
              color: #333;
              font-size: 1.5rem !important;
              flex: 1;
              display: flex;
              align-items: center;
              margin-right: 0 !important;
            }

            /*a.router-link-exact-active {
              color: var(--hover-color);
            }*/

            .dynamic-menus {
              padding: 0;
              width: 100%;
              position: unset;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;

              .main-menu {
                font-weight: 500;
                color: #333;
                span{
                  font-size: 1.5rem !important;
                  color: #333;
                }
                .sub-menus {
                  display: none;
                  position: unset;
                  z-index: 1;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  background-color: rgba(0, 0, 0, 0.8) !important;
                }
              }

            }
            .main-menu:focus .sub-menus,
            .main-menu:focus-within .sub-menus,
            .main-menu:hover .sub-menus {
              display: flex;
              background-color: white;
              box-shadow: none;
              margin: 0;
              a {
                border-bottom: none;
              }
            }
            .secondary-item {
              border: none;
              font-size: 1.2rem;
              margin: 10px;
              padding: 0px;
              margin: 15px 0px;
              color: $black;
              button {
                color: grey;
                background: transparent;
                box-shadow: none;
                font-size: unset;
                padding: 0px;
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .active_menu {
    border-bottom: 1px solid #fff;
  }

  @include media('<=phone') {
    .logoImg img {
      max-width: 60px !important;
    }

    header{

      .header-content {
        .contact-info{
          address {
            display: none;
          }
        }
      }

      .menu {
      }
    }
  }
</style>
