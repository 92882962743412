<template>
	<layout v-if="hotel">
		<section slot="content" id="discounts-page">
			<section class="activateHardware" style="position: relative; padding-top: 100px;" v-if="theme !== 'express-theme'">
				<divider v-if="welcomeText.title || welcomeText.description">
					<span class="classic">{{welcomeText.title}}</span>
					<p class="content" style="max-width: 700px; font-weight: 400; text-align: center;">{{welcomeText.description}}</p>
				</divider>
			</section>

      <divider :style="{ 'margin': theme === 'express-theme' ? '110px 100px 50px 100px' : '5rem 0' }" v-if="theme === 'express-theme'">
        {{ $t('lbl_discounts') }}
      </divider>

			<section id="home_rooms" :class="{ 'explore_rooms': theme !== 'express-theme', 'express': theme === 'express-theme' }">
        <express-discounts v-if="theme === 'express-theme'" :key="discount.id" :discount="discount"
                           v-for="discount in discountImages" @click.native="setDiscount(discount)" />

				<room-view v-if="theme !== 'express-theme'" v-for="(discount, index) in discountImages" :key="discount.id" :view-type="'discount'" :title="discount.name" :reverse="index%2 !== 0" :description="discount.long_description" :subheading="discount.short_description" :images="discount.all_images">
					<div slot="action_buttons" id="action_buttons">
						<button aria-label="Go to booking module" :style="{ 'background-color' : buttonColor }"  class="book-discount-button"
                    role="button" :class="theme" @click="setDiscount(discount)">{{ $t('lbl_book_now') }}</button>
					</div>
				</room-view>
			</section>
		</section>
	</layout>
  <div class="loader" v-else>
    <loader></loader>
  </div>
</template>
<script>
var isWithinRange = require('date-fns/is_within_range');
import { Facebook, Twitter, Instagram, Share2, Loader } from 'vue-feather-icon';
import layout from '@/layouts/page/page.vue'
import divider from '@/components/general/divider.vue'
import roomView from '@/components/room_view/room_view.vue'
import carousel from '@/components/general/carousel/carousel.vue'
import ExpressDiscounts from "../components/ExpressDiscounts";

import {mapState} from 'vuex'

export default {
	components: {
		layout,
		divider,
		roomView,
		carousel,
		Facebook: Facebook.default,
		Twitter: Twitter.default,
		Instagram: Instagram.default,
		Share2: Share2.default,
    loader: Loader.default,
    ExpressDiscounts
	},
	mounted() {
		if (this.hotel === false) {
			this.$store.dispatch('setPropDetails');
		} else {
			document.getElementsByTagName('title')[0].innerHTML = 'Discounts | ' + this.hotel_name;
		}
	},
	data: () => ({
		url: null,
		selected_discount: null,
	}),
	computed: {
	  ...mapState({
      hotel_id: state => state.hotel.id,
      hotel_name: state => state.hotel.name,
    }),
		hotel() {
			return this.$store.state.hotel !== null;
		},
		discounts() {
			return !!this.hotel && this.$store.getters.availableDiscounts;
		},
		discountImages() {
			let test = [];
			let minDate = this.$store.state.minimum_date;
			if(!this.discounts) return [];
			this.discounts.forEach((item) => {
				let start_date = item.sell_start_date && item.sell_start_date.slice(0, 10)
				let end_date = item.sell_end_date && item.sell_end_date.slice(0, 10);
				if (!start_date || !end_date) return false;
				let inBetween = isWithinRange(minDate, start_date, end_date) && !!item.active;
        !!item.cover_photo ? (item.all_images = [...item.discount_images, {url: item.cover_photo}]) : (item.all_images = [...item.discount_images]);
				if (!!inBetween) {
					test.push(item);
				}
			});
			return test;
		},
		welcomeText() {
			if (!this.web_config) {
				return false;
			}
			let discounts = this.web_config.property_website_images.DISCOUNTS;
			let sampletext = {
				title: '',
				description: ''
			};
			return discounts.length ? { title: discounts[0].tagline, description: discounts[0].description } : sampletext;
		},
		web_config() {
			return this.$store.state.web_config;
		},
    buttonColor() {
		  return this.web_config.color_code
    },
    theme() {
		  let theme = !!this.web_config && this.web_config.theme
      return `${theme.toLowerCase()}-theme`
    }
	},
	methods: {
		setDiscount(selected_discount) {
			this.selected_discount = selected_discount;
      let url = encodeURIComponent(window.location.href)
			this.url = `${process.env.VUE_APP_BOOKING_URL}?pc=${process.env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&url=${url}&discount_type=${this.selected_discount.code}`;
			window.location.href = this.url;
		},
	}
}

</script>
<style lang="scss">
@import '~css_vars';
@import '~mixins';

article {
  margin: 0;
  padding: 0;
}

.loader {
  display: flex;
  min-height: 180px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%
}


#discounts-page {

	.explore_rooms {
		margin: 50px;
		padding: 0px;
		display: grid;
		grid-template-columns: 1fr; //repeat(auto-fill, minmax(340px, 500px));
		justify-content: center;
    box-shadow: 0px 0px 5px #888897;
		>div {
			width: 100%;
		}
    @include media('<=phone') {
      margin: 0;
    }
	}

  .express {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 100px 20px;
    grid-gap: 20px;
  }

	#action_buttons {
		margin-top: auto;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		button {
			font-weight: 300;
			font-family: 'Roboto';
			letter-spacing: 1px;
			padding: .8rem 2rem;
      &.bold-theme {
        border-radius: 0;
        font-weight: bold;
      }
		}
	}

	.icons {
		margin-right: auto;
		display: flex;
		align-items: center;
		height: 30px;
		justify-content: center;
		svg {
			margin-right: 10px;
			width: 20px;
			height: 20px;
			cursor: pointer;
			stroke: lighten($black, 40);
			path {
				stroke: lighten($black, 40);
			}
			&:hover {
				stroke: lighten($black, 10);
				path {
					stroke: lighten($black, 10);
				}
			}
		}
		.social-links {
			display: none;
			align-items: center;
			padding: 5px;
			margin: -10px;
		}
		&:hover {
			.share-icon {
				display: none;
			}
			.social-links {
				display: flex;
			}
		}
	}
}

@media (max-width: 768px) {
  #discounts-page {
    .express {
      margin: 0 20px 20px;
    }
  }
}

@include media("<=phone") {
  #discounts-page {
    .express {
      grid-template-columns: 1fr;
      margin: 0 20px 20px;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS styles go here */

	#discounts-page{
		.explore_rooms{
			display: block;
		}
	}

}

</style>
