<template>
  <div class="section-card">
    <h4>{{ section.title }}</h4>
    <p :aria-label="section.description">{{ section.description }}</p>
    <button role="button" :aria-label="`Explore ${section.title}`" :style="{ 'background-color': buttonColor }"
            v-if="section.redirect_url" @click="goTo(section.redirect_url)">{{ $t('lbl_explore') }}
    </button>
  </div>
</template>

<script>
  export default {
    name: "sectionCard",
    props: ['section'],
    computed: {
      web_config() {
        return this.$store.state.web_config;
      },
      buttonColor() {
        return this.web_config.color_code
      }
    },
    methods: {
      goTo(item) {
        if (item.split('.')[0] === 'www') {
          window.open(`https://${item}`, '_blank');
        } else {
          window.open = (`${item}`, '_blank');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';

  .section-card {

    h4 {
      font-size: 1.1rem;
      margin: 0;
    }

    p {
      margin: 10px 0 20px;
      font-size: .9rem;
      color: #6a6c6f;
      white-space: pre-wrap;
      text-align: justify;
    }

    button {
      padding: 10px 20px;
      color: #fff;
      font-size: 1rem;
    }
  }
</style>
