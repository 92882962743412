import Axios from 'axios';
import Vue from 'vue'

Axios.defaults.baseURL = `${process.env.VUE_APP_SERVER_ENV}${process.env.VUE_APP_BASE_URL}`;
Axios.defaults.headers.common.Accept = 'application/json';

// Bind Axios to Vue.
Vue.$http = Axios;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return Axios;
  },
});
