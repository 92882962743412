<template>
	<div class="the-map">
		<gmap-map :center="{lat:parseFloat(hotelDetailsNeeded.latitude), lng:parseFloat(hotelDetailsNeeded.longitude)}" :zoom="11" map-type-id="terrain">
			<gmap-marker v-if="result.coordinates.latitude" v-for="(result, index) in results" :key="result.id" :position="{lat:parseFloat(result.coordinates.latitude), lng:parseFloat(result.coordinates.longitude)}" :clickable="true" @click="toggleInfoWindow(result,index)"></gmap-marker>
			<gmap-info-window v-if="!!infoWindowPos" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
				{{infoWindowContent}} <a :href="getDirectionsLink" target="_blank" class="gd">{{ $t('lbl_get_directions') }}</a>
			</gmap-info-window>
		</gmap-map>
	</div>
</template>
<script>
export default {
	data: () => ({
		infoWinOpen: false,
		infoWindowPos: null,
		infoWindowContent: '',
		getDirectionsLink: null,
	}),
	props: {
		results: {
			required: true,
			type: Array,
		},
	},
	computed: {
    hotelDetailsNeeded() {
      let {latitude, longitude} = this.$store.state.hotel;
      return {latitude, longitude}
    },
	},
	methods: {
		toggleInfoWindow(result, index) {
			let latitude = parseFloat(result.coordinates.latitude);
			let longitude = parseFloat(result.coordinates.longitude);

			this.infoWindowContent = `${result.name}.`;
			this.infoWindowPos = {
				lat: latitude,
				lng: longitude,
			};
			this.getDirectionsLink = `https://www.google.com/maps?saddr=${this.hotelDetailsNeeded.latitude},${this.hotelDetailsNeeded.longitude}&daddr=${latitude},${longitude}`;
			this.infoWinOpen = true;
		},
	},
};

</script>
<style lang="scss">
@import '~css_vars';
.the-map {
	flex: 1;
	margin-top: 15px;
	.vue-map-container {
		height: 330px;
		width: 100%;
		position: sticky;
		top: 30px;
	}
	.scaled-down-arrow {
		transform: scale(.5) translateX(8px);
	}
	a.gd {
		color: $blue;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
}

.gm-style-iw {
	max-width: 200px;
}

</style>
