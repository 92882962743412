<template>
  <layout v-if="hotel">
    <section slot="content" id="inquiry" class="inquiry" aria-label="Contact Us Section">
      <div class="inquiry-intro">
        <h3>{{ $t('lbl_hk_website_contact_us') }}</h3>
        <p>{{ $t('lbl_hk_website_contact_us_intro_msg') }}</p>
        <img src="../assets/static/contact_us.svg" alt="Contact Us Image">
      </div>
      <div class="inquiry-form">
        <el-form :model="model" :rules="rules" ref="emailForm">
          <el-form-item :label="$t('lbl_hk_website_tell_us_your_name')" prop="name">
            <el-input v-model="model.name" :placeholder="$t('lbl_hk_website_tell_us_your_name')"></el-input>
          </el-form-item>

          <el-form-item :label="$t('lbl_hk_website_enter_your_email')" prop="email">
            <el-input v-model="model.email" :placeholder="$t('lbl_hk_website_enter_your_email')"></el-input>
          </el-form-item>

          <el-form-item :label="$t('lbl_hk_website_enter_contact_number')" prop="phoneno">
            <el-input v-model="model.phoneno" :placeholder="$t('lbl_hk_website_enter_contact_number')"
                      @keypress.native="isSpecialChar($event)"></el-input>
          </el-form-item>

          <el-form-item :label="$t('lbl_hk_website_enter_message')" prop="notes">
            <el-input type="textarea" v-model="model.notes" :placeholder="$t('lbl_hk_website_enter_message')"></el-input>
          </el-form-item>

          <div class="actions">
            <button @click="send('emailForm')" aria-label="Send Email" type="button"
                    :style="{ 'background-color': buttonColor }">{{ $t('lbl_hk_website_send') }}</button>

            <span class="loader" v-if="loading">
              <loader :stroke="buttonColor"></loader>
            </span>
          </div>
        </el-form>
      </div>
    </section>
  </layout>
</template>

<script>
  import layout from '@/layouts/page/page.vue'
  import {Loader} from 'vue-feather-icon'

  export default {
    name: "Inquiry",
    components: {
      layout,
      loader: Loader.default,
    },
    mounted() {
      if (!this.hotel) {
        this.$store.dispatch('setPropDetails');
      }
    },
    data() {
      return {
        loading: false,
        model: {
          name: null,
          email: null,
          phoneno: null,
          notes: null
        },
        rules: {
          name: [
            {required: true, message: this.$t('lbl_hk_website_name_is_required'), trigger: 'change'}
          ],
          email: [
            {required: true, message: this.$t('lbl_hk_website_email_is_required'), trigger: 'change'},
            {type: 'email', message: this.$t('lbl_hk_website_invalid_email_address'), trigger: 'change'}
          ],
          phoneno: [
            {required: true, message: this.$t('lbl_hk_website_number_is_required'), trigger: 'change'}
          ],
          notes: [
            {required: true, message: this.$t('lbl_hk_website_message_is_required'), trigger: 'change'}
          ],
        }
      }
    },
    computed: {
      hotel() {
        return this.$store.state.hotel;
      },
      web_config() {
        return this.$store.state.web_config;
      },
      buttonColor() {
        return this.web_config.color_code
      },
    },
    methods: {
      send(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.callApi()
          }
        })
      },
      callApi() {
        let url = `/properties/${this.hotel.id}/contactus`;

        this.loading = true;

        this.$http.post(url, this.model).then(() => {
          this.$notify.success({
            title: this.$t('lbl_hk_website_success'),
            message: this.$t('lbl_hk_website_email_success')
          })
        }).catch(error => {
          this.$notify.error({
            title: error.response.status,
            message: error.response.data.message
          })
        }).finally(() => {
          this.$refs['emailForm'].resetFields();
          this.loading = false;
        })
      },
      isSpecialChar(e) {
        let charCode = e.key;
        let regex = new RegExp("^[\\d\+]+$");

        if (!regex.test(charCode)) {
          e.preventDefault()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~css_vars';
  @import '~mixins';

  .inquiry {
    margin: 150px 100px 100px;
    display: flex;

    .inquiry-intro {
      flex: 1;
      padding: 40px;
      align-self: center;
      text-align: center;

      p {
        font-size: 1rem;
      }
    }

    .inquiry-form {
      flex: 1;
      box-shadow: 0px 10px 10px #00000014;
      border-radius: 4px;
      align-self: stretch;
      padding: 30px;

      .actions {
        display: flex;
        align-items: center;

        button {
          font-size: 1.1rem;
          padding: 10px 20px;
          color: #fff;
          margin-right: 10px;
          box-shadow: none;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @include media('<=phone') {
    .inquiry {
      flex-direction: column;
      margin: 100px 10px;
    }
  }

  @media (max-width: 768px) {
    .inquiry {
      margin: 100px 10px;
    }
  }
</style>
