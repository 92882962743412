<style lang="scss" scoped>
  @import '~css_vars';
  @import '~mixins';

  article, figure {
    margin: 0;
    padding: 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow: hidden;
    max-width: 350px;
    min-width: 250px;
    max-height: 420px;
    width: 100%;
    margin: 15px;
    position: relative;
    overflow: visible;

    .image {
      position: relative;
    }

    .content {
      padding: 20px 30px 20px 20px;
      display: flex;
      align-items: flex-start;
      position: relative;

      a {
        margin: 0;
        padding: 0;
      }

      .rating {
        display: flex;
        position: absolute;
        top: 20px;
        right: 5px;

        span {
          font-weight: normal;
          font-size: .8rem;
          background-color: grey;
          padding: 5px;
          border-radius: 3px;
          color: white;

          &.perfect {
            background-color: darken($green, 10);
          }

          &.good {
            background-color: adjust-hue($green, -50%);
          }

          &.bad {
            background-color: adjust-hue($green, -100%);
          }

          &.worst {
            background-color: adjust-hue($green, -150%);
          }
        }
      }
    }

    .directions {
      display: flex;
      justify-content: center;
      padding: .5rem;
      margin-top: auto;

      a {
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        color: $blue;
        font-weight: 400;
        font-size: .8rem;
        padding: .3rem 1rem;
        border-radius: 4px;

        &:hover {
          background-color: whitesmoke;
        }
      }
    }

    .main_content {
      display: flex;
      flex-direction: column;
    }

    .name {
      display: block;
      max-width: 297px;
      margin-right: 35px;
    }

    .second_row {
      display: flex;
      flex-direction: column;
      margin: 10px 0px;
    }

    .sub {
      text-transform: capitalize;
      margin: 0px;
      font-size: .8rem;
      line-height: 1;
      letter-spacing: 0px;

      .distance {
        margin-top: 10px;
        display: flex;

        svg {
          stroke: red;
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }
    }

    .sub.type {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      span {
        border: solid 1px $grey;
        margin-bottom: 2px;
        color: grey;
        font-weight: 400;
        border-radius: 4px;
        padding: 5px;
        margin-right: 5px;
        flex-wrap: wrap;
        font-size: .75rem;
      }
    }

    .price-level {
      font-size: .8rem;
      display: flex;
      margin-top: 5px;

      .dotted {
        color: $grey;
      }
    }

    h5 a:hover,
    h5 a:active {
      text-decoration: underline;
    }
  }

  .image {
    height: 200px;
    background-color: whitesmoke;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .card {
      margin: 0 auto 20px auto;
    }
  }

</style>
<template>
  <section :aria-label="`Attraction name ${info.name}`" class="card">
    <!--<featured-item v-if="info.featured"></featured-item>-->
    <figure :aria-label="`Image for ${info.name}`" class="image"
            :style="!!info.image_url && `background-image: url(${info.image_url})`">
      <span v-if="!info.image_url">{{ $t('lbl_no_images_to_show') }}</span>
    </figure>
    <div class="content">
      <div class="rating">
          <span v-if="!!info.rating" :class="{'worst': info.rating<2, 'bad': info.rating>=2 && info.rating<3,
                'good': info.rating>=3 && info.rating<4,
                'perfect': info.rating>=4}">
            {{ access_status ? `Rating ${parseFloat(info.rating).toFixed(1)}` : parseFloat(info.rating).toFixed(1)}}
          </span>
      </div>
      <section class="main_content">
        <a :aria-label="`Visit ${info.name}`" class="name" target="_blank" :href="info.url"><strong>{{ info.name
          }}</strong></a>
        <div class="second_row">
          <p v-if="info.categories" aria-label="Categories" class="sub type"><span v-for="category in info.categories">{{category.title}}</span>
          </p>
          <div aria-hidden="true" class="price-level" v-if="!!info.price">
            <span>{{info.price}}</span>
            <span class="dotted" v-for="left_out in (5-(info.price.length + 1))">$</span>
          </div>
        </div>
        <p aria-label="Address and distance" class="sub"
           style="display: flex; flex-direction: column; line-height: 1.4;">
          <span v-if="info.address">{{ info.address }}</span>
          <span v-if="info.location">{{info.location.address1}}, {{info.location.address2}}</span>
          <span v-if="info.location">{{info.location.city}}, {{info.location.zip_code}}, {{info.location.state}}</span>
          <span v-if="info.distance" class="distance"><map-pin aria-hidden="true"></map-pin> {{distanceInMiles}} {{ $t('lbl_miles') }}</span>
        </p>
      </section>
    </div>
    <div class="directions">
      <a v-if="nearby_location" :aria-label="`Get directions for ${info.name}`" :href="nearby_location" target="_blank">{{
        $t('lbl_get_directions') }}</a>
      <a style="text-transform: uppercase" v-if="exploreAttraction" :aria-label="`Explore ${info.name}`" :href="exploreAttraction" target="_blank">{{
        $t('lbl_explore') }}</a>
    </div>
  </section>
</template>
<script>
  import {MapPin} from 'vue-feather-icon'
  import featuredItem from '../featured.vue'

  export default {
    props: ['info'],
    data: () => ({}),
    components: {
      MapPin: MapPin.default,
      featuredItem
    },
    computed: {
      hotelDetailsNeeded() {
        let {latitude, longitude} = this.$store.state.hotel;
        return {latitude, longitude}
      },
      categories() {
        return this.info.categories.reduce((b, a, currentIndex) => {
          return b += currentIndex > 0 ? ', ' + a.title : a.title;
        }, '');
      },
      distanceInMiles() {
        return parseFloat(this.info.distance / 1609.344).toFixed(2)
      },
      nearby_location() {
        if (this.info.coordinates) {
          return `https://www.google.com/maps?saddr=${this.hotelDetailsNeeded.latitude},${this.hotelDetailsNeeded.longitude}&daddr=${this.info.coordinates.latitude.toString()},${this.info.coordinates.longitude.toString()}`;
        } else {
          return false
        }
      },
      exploreAttraction() {
        let pattern = /^((http|https|ftp):\/\/)/;

        if (this.info.redirect_url) {
          if (!pattern.test(this.info.redirect_url)) {

            return "http://" + this.info.redirect_url;

          } else {
            return this.info.redirect_url
          }
        } else {
          return false
        }
      },
      access_status() {
        return this.$store.state.access_status
      }
    },
  };

</script>
