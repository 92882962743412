import home from '@/pages/home/home.vue'
import rooms from '@/pages/rooms_suites/rooms_suites.vue'
import explore from '@/pages/explore-atmosphere/explore-atmosphere.vue'
import gallery from '@/pages/gallery/gallery.vue'
import packages from '@/pages/packages.vue'
import discounts from '@/pages/discounts.vue'

import amenities from '@/pages/amenities/amenities.vue'
import dynamicMenu from '@/pages/dynamic-menus/dynamic-menu.vue'

import masterView from '@/master-layout/master-view.vue';
import Inquiry from "../pages/Inquiry";

export default [{
  path: '/',
  name: 'home',
  props: true,
  get component() {
    if (process.env.VUE_APP_MODE === 'master') {
      return masterView;
    } else {
      return home;
    }
  },
  meta: {
    announcer: 'Home View Activated'
  }
},
  {
    path: '/rooms',
    name: 'rooms',
    component: rooms,
    meta: {
      announcer: 'Rooms View Activated',
      nonMaster: true
    }
  },
  {
    path: '/explore',
    name: 'explore',
    component: explore,
    meta: {
      announcer: 'Explore View Activated',
      nonMaster: true
    }
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: gallery,
    meta: {
      announcer: 'Gallery View Activated',
      nonMaster: true
    }
  },
  {
    path: '/packages',
    name: 'packages',
    component: packages,
    meta: {
      announcer: 'Packages View Activated',
      nonMaster: true
    }
  },
  {
    path: '/discounts',
    name: 'discounts',
    component: discounts,
    meta: {
      announcer: 'Discounts View Activated',
      nonMaster: true
    }
  },
  {
    path: '/amenities',
    name: 'amenities',
    component: amenities,
    meta: {
      announcer: 'Amentities View Activated',
      nonMaster: true
    }
  },
  {
    path: '/dynamic-menu/:name',
    name: 'dynamic-menu',
    component: dynamicMenu,
    meta: {
      announcer: 'Dynamic Menu Activated',
      nonMaster: true
    }
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: Inquiry,
    meta: {
      announcer: 'Inquiry View Activated',
      nonMaster: true
    }
  },
  {
    path: '/*',
    redirect: '/',
  }
];
