import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import * as actions from './actions';
import state from './state';
import mutations from './mutations';
var isWithinRange = require('date-fns/is_within_range');

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters: {
    roomAmenities: state => {
      if (state.room_types === null) {
        return [];
      }
      let amenities = {};
      state.room_types.forEach((i) => {
        amenities[i.id] = i.amenities;
      });
      return amenities;
    },
    roomsWithIds: state => {
      if (state.room_types === null) {
        return [];
      }
      return state.room_types.reduce((b, a) => {
        b[a.id] = a;
        return b
      }, {});
    },
    roomMinCounts: state => {
      if (state.room_types === null) {
        return [];
      }
      let roomMinCounts = {};
      state.room_types.forEach((i) => {
        roomMinCounts[i.id] = i.min_room_count_value;
      });
      return roomMinCounts;
    },
    roomsWithNames: state => {
      if (state.room_types === null) {
        return [];
      }
      let room_types_with_names = state.room_types.filter(room => room.images.length > 0);
      return room_types_with_names.reduce((b, a) => {
        b[a.name] = a;
        return b
      }, {});
    },
    sorted_guest_reservations: state => {
      let gr = JSON.parse(JSON.stringify(state.guest_reservations));
      return !!gr && gr.sort((a, b) => {
        return new Date(a.check_in_date) - new Date(b.check_in_date);
      });
    },
    availableDiscounts: state => {
      return !!state.hotel && !!state.hotel.discounts && !!state.minimum_date
      && state.hotel.discounts.filter(discount => {
        let start_date = discount.sell_start_date && discount.sell_start_date.split('T')[0];
        let end_date = discount.sell_end_date && discount.sell_end_date.split('T')[0];
        let un_source = state.hotel.website_config.website_config_sources.find(source => source.source_type === 'UNAUTHENTICATED')

        if(!start_date || !end_date) return false;

        return isWithinRange(state.minimum_date, start_date, end_date) && !!discount.active && discount.sources && !!discount.sources.some(source => {
          return source.id === un_source.source_id;
        });
      });
    }
  },

  strict: debug,

  plugins: debug ? [createLogger()] : [],
});
