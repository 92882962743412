<template>
  <div class="popup-wrapper">
    <div class="popup">
      <div class="popup-header">
        <slot name="header">
          <h4>Header</h4>
        </slot>
      </div>
      <div class="popup-content normal-font">
        <div class="extra-amenities-list">
          <ul :aria-label="`Amenities part ${index + 1}`"
              v-for="(chunk, index) in extraAmenities">
            <li v-for="amenitie in chunk" :class="{'EXPRESS' : theme !== 'CLASSIC'}">
              <check v-if="theme === 'CLASSIC'" aria-hidden="true">
              </check>
              <check2 v-else aria-hidden="true">
              </check2>
              {{amenitie.name}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {CheckCircle, Check} from 'vue-feather-icon'

  export default {
    props: ['extraAmenities'],
    components: {
      check: CheckCircle.default,
      check2: Check.default
    },
    computed: {
      theme() {
        return this.$store.state.hotel.website_config.theme
      }
    }
  }

</script>
<style lang="scss" scoped>
  @import '~css_vars';
  @import 'mixins';

  .popup-wrapper {
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(black, .3);
    display: flex;
    align-items: center;
    justify-content: center;

    .popup {
      background-color: white;
      border-radius: 8px;
      overflow: hidden;
      min-height: 25vh;
      max-height: 90vh;
      min-width: 35vw;
      max-width: 900px;
      display: flex;
      flex-direction: column;

      .popup-bottom {
        margin-top: auto;
      }

      @include media('<=phone') {
        margin: 0 20px;
      }
    }

    .popup-header {
      h4 {
        font-weight: 500;
        text-transform: initial;
        font-size: 1.2rem;
        margin: 10px;
      }

      padding: 10px 20px;
    }

    .popup-content {
      overflow: auto;
      font-weight: 400;
      padding: 20px 20px;
      margin: 0;
      text-transform: initial;
      font-size: 1rem;
      color: lighten($black, 10);
      border-top: solid 1px #e2e2e2;
    }
  }

  .extra-amenities-list {
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;

    ul {
      li {
        display: flex;
        align-items: center;
        font-size: .85rem;
        color: #545454;
        font-weight: 400;
        line-height: 1.2;
        max-width: 300px;
        margin-bottom: 8px;

        svg {
          width: 15px;
          height: 15px;
          margin-right: 5px;
          stroke: #070707;
        }

        &.EXPRESS {
          svg {
            stroke: #61d095;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .popup-wrapper {
      .popup {
        width: 450px;
      }
    }
  }

</style>
