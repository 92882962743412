<template>
	<div class="popup-wrapper" :class="{'clean': !!clean}">
		<div class="popup" :class="{ 'wide': groupWidth === true , 'cancel-group' : cancelGroup === true }">
			<div class="header">
				<slot name="header">
					<h4>Header</h4>
				</slot>
			</div>
			<div class="popup-content normal-font">
				<slot>
					<p>Popup Content</p>
				</slot>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['clean', 'groupWidth', 'cancelGroup'],
}

</script>
<style lang="scss" scoped>
@import '~css_vars';

.popup-wrapper {
	position: fixed;
  overflow: hidden;
	z-index: 1000;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(black, .5);
	display: flex;
	align-items: center;
	justify-content: center;

	.popup {
    padding: 40px;
		background-color: white;
		border-radius: 8px;
		overflow: hidden;
		min-height: 50vh;
		max-height: 90vh;
    min-width: 800px;
		max-width: 800px;
		display: flex;
		flex-direction: column;
	}

  .wide{
    width: 650px;
  }

  .cancel-group{
    width: 90%;
  }
	.header {

		h4 {
			font-weight: 500;
			text-transform: initial;
			font-size: 1.2rem;
		}
	}
	.popup-content {
		overflow: auto;
		font-weight: 400;
		margin: 0;
		text-transform: initial;
		font-size: 1rem;
		color: lighten($black, 10);
		border-top: solid 1px #e2e2e2;
	}

	&.clean {
		background-color: transparent;
		border-radius: 0px;
	}
}

@media (max-width: 1024px){
	.popup-wrapper{
		.popup{
      min-width: unset;
      max-width: unset;
			width: 450px;
		}
	}
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 700ms;
}
</style>
