<template>
  <div class="discount" :style="{ 'background-image': backgroundImage ? `url(${backgroundImage})` : 'linear-gradient(to left, #FFB88C, #DE6262)' }">
    <div class="discount-content">
      <h3>{{ discount.name }}</h3>
      <span>{{ discount.short_description }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ExpressDiscounts",
    props: ['discount'],
    computed: {
      backgroundImage() {
        return this.discount.all_images.length > 0 ? this.discount.all_images[0].url : false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .discount {
    width: 100%;
    height: 250px;
    background-position: center;
    background-size: cover;
    cursor: pointer;

    .discount-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.6);

      span {
        font-size: 0.9rem;
      }
    }
  }
</style>
