<template>
	<div aria-label="Images Section" class="complex-images" v-if="images && images.length > 0">
		<div v-if="from && from === 'welcome'" role="img" :alt="'Picture of '+ image.description " :aria-label="'Picture of '+ image.description  " v-for="(image, index) in filteredImages" :style="{'background-image': `url('${sanitizeUrl(image.image_url)}')`}"></div>
    <div v-if="from === undefined" role="img" :alt="image.caption ? image.caption : `Picture  ${index + 1}  of  ${image.title}` " :aria-label="image.caption ? `${image.caption}` : `Picture  ${index + 1}  of  ${image.title}`" v-for="(image, index) in filteredImages" :style="{'background-image': `url('${sanitizeUrl(image.image_url)}')`}"></div>

  </div>
</template>
<script>
export default {
	props: {
		images: {
			type: Array,
			required: true,
		},
    imageType: {
		  type: String,
      required: false,
      default: 'Property Image'
    },
    from:{
      type: String,
      required: false,
    }
	},
  mounted() {
  },
  computed: {
	  filteredImages() {
	    return this.images.filter(img => img.url || img.image_url)
    }
  },
	methods: {
		sanitizeUrl(url) {
		  console.log("URL",url)
			return url !== undefined && url !== null && url.replace(/ /g, '%20');
		},
	},
}

</script>
<style lang="scss" scoped>
@import '~mixins';

.complex-images {
	min-height: 400px;
	position: relative;
  left: 10%;
	display: flex;
	align-items: center;
	justify-content: center;

  &.complex-images-express {
    left: 33%;
    max-width: 50%;
  }
}

.complex-images > div {
	position: absolute;
	background-size: cover;
	background-position: 0% 50%;
}

.complex-images > div:first-child {
	width: 400px;
	height: 250px;
	background-color: whitesmoke;
	z-index: 3;
	bottom: -10px;
	left: 10px;
	transition: transform 1s ease .2s;
	&:hover {
		transform: translateX(5%) translateY(1%) scale(1.1) rotate(1deg);
	}
}

.complex-images > div:nth-child(2) {
	width: 220px;
	height: 290px;
	background-color: darken(whitesmoke, 2);
	z-index: 2;
	left: 340px;
	transition: transform 1s ease .2s;
	&:hover {
		transform: translateX(30%) translateY(-9%) scale(1.1) rotate(8deg);
    @include media('<=phone') {
      transform: translateX(7%) translateY(-24%) scale(1.1) rotate(0deg);
    }
	}
}

.complex-images > div:last-child{
  width: 390px;
	height: 190px;
	background-color: darken(whitesmoke, 3);
	z-index: 1;
	top: 0px;
	left: 0px;
	transition: transform 1s ease .2s;
	&:hover {
		transform: translateX(0) translateY(-25%) scale(1.1) rotate(-1deg);
    @include media('<=phone') {
      transform: translateX(-4%) translateY(-34%) scale(1.1) rotate(-7deg);
    }
	}
}

@include media('<=tablet') {
  .complex-images {
    transform: translateX(10%) scale(0.74);
    margin-bottom: -100px;
    left: 0%;
    &.complex-images-express {
      left: 0;
      max-width: 100%;
    }
  }
}


@include media('<=phone') {
	.complex-images {
		transform: translateX(-15%) scale(0.54);
		margin-bottom: -100px;
    &.complex-images-express {
      left: 0;
      max-width: 100%;
    }
	}
}

</style>
