export default class CommonUtils {
  static setLanguageCode(language) {
    let host = window.location.host

    if (host.indexOf(':') !== -1 && host.split(':')[0] === 'localhost') {
      document.cookie = 'hk_language=' + language
    } else {
      document.cookie = `hk_language=${language};path=/;domain=.hotelkeyapp.com`
    }
  }
}
