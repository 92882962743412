<template>
  <div class="awards" aria-label="Awards Section">

    <button @click="goLeft()" class="right" :class="{'hidden-arrow': !scrollLeftt}">
      <chevron-left></chevron-left>
    </button>

    <div class="award-container" :style="style">
      <award-view :key="award.id" v-for="award in awards" :award="award" />
    </div>

    <button @click="goRight()" class="left" :class="{'hidden-arrow': !scrollRightt}">
      <chevron-right></chevron-right>
    </button>

  </div>
</template>

<script>
  import {ChevronLeft, ChevronRight} from 'vue-feather-icon'
  import AwardView from "./AwardView";

  export default {
    name: "AwardContainer",
    components: {
      AwardView,
      ChevronLeft: ChevronLeft.default,
      ChevronRight: ChevronRight.default
    },
    mounted() {
      this.checkChevrons();
    },
    data() {
      return {
        scrollLeftt: true,
        scrollRightt: true
      }
    },
    computed: {
      hotelDetails() {
        return this.$store.state.hotel
      },
      awards() {
        return this.hotelDetails.property_website_config_awards.length > 0 ? this.hotelDetails.property_website_config_awards.sort((a, b) => a.order_no - b.order_no) : []
      },
      style() {
        return {
          'grid-template-columns': `repeat(${this.awards.length}, ${this.awards.length < 4 ? '300px' : 'minmax(255px, 280px)'})`,
          'grid-gap' : this.awards.length < 4 ? '50px' : '20px',
          'justify-content': this.awards.length < 4 ? 'center' : 'start'
        }
      }
    },
    methods: {
      goLeft() {
        let elem = document.getElementsByClassName('award-container')[0]

        elem.scroll({
          left: elem.scrollLeft - document.getElementsByClassName('award')[0].scrollWidth - 20,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkChevrons()
        }, 300)
      },
      goRight() {

        let elem = document.getElementsByClassName('award-container')[0]

        elem.scroll({
          left: elem.scrollLeft + document.getElementsByClassName('award')[0].scrollWidth + 20,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkChevrons()
        }, 300)
      },
      checkChevrons() {

        let elem = document.getElementsByClassName('award-container')[0];
        if (elem.scrollLeft === 0) {
          this.scrollLeftt = false
        } else if (elem.scrollLeft > 0) {
          this.scrollLeftt = true;
        }

        let scrollLefti = parseInt(elem.scrollLeft.toFixed(0))

        if (scrollLefti + parseInt(elem.offsetWidth.toFixed(0)) === parseInt(elem.scrollWidth.toFixed(0))) {
          this.scrollRightt = false;
        } else if (scrollLefti + parseInt(elem.offsetWidth.toFixed(0)) !== parseInt(elem.scrollWidth.toFixed(0))) {
          this.scrollRightt = true;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  .awards {
    position: relative;
    padding: 0 100px;
    max-width: 100%;
    overflow: hidden;

    .award-container {
      display: grid;
      max-width: 100%;
      overflow-x: hidden;
    }

    .right {
      position: absolute;
      left: 0;
      top: 40%;
      min-width: 30px;
      padding: 0;
      &.hidden-arrow {
        display: none;
      }
    }

    .left {
      position: absolute;
      right: 0;
      top: 40%;
      min-width: 30px;
      padding: 0;
      &.hidden-arrow {
        display: none;
      }
    }
  }

  @include media("<=tablet") {
    .awards {
      padding: 0 50px;

      .award-container {
        justify-content: start !important;
      }
    }
  }

  @include media("<=phone") {
    .awards {
      padding: 0 30px;

      .award-container {
        grid-gap: 20px !important;
        justify-content: start !important;
      }
    }
  }

</style>
