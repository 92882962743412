<template>
  <div class="express-attractions" aria-label="Featured Attractions">
    <button @click="goLeft()" class="right" :class="{'hidden-arrow': !scrollLeftt}">
      <chevron-left></chevron-left>
    </button>

    <div class="express-attraction" :style="style">
      <express-attraction v-for="attraction in attractions"
                          :attraction="attraction" :key="attraction.title"
                          @activateAttraction="goTo(attraction.external_link)" />
    </div>

    <button @click="goRight()" class="left" :class="{'hidden-arrow': !scrollRightt}">
      <chevron-right></chevron-right>
    </button>
  </div>
</template>

<script>
  import ExpressAttraction from "./ExpressAttraction";
  import {ChevronLeft, ChevronRight} from 'vue-feather-icon'

  export default {
    name: "ExpressAttractionContainer",
    props: ['attractions'],
    components: {
      ChevronLeft: ChevronLeft.default,
      ChevronRight: ChevronRight.default,
      ExpressAttraction
    },
    mounted() {
      this.checkChevrons();
    },
    data() {
      return {
        scrollLeftt: true,
        scrollRightt: true
      }
    },
    computed: {
      style() {
        return {
          'grid-template-columns': `repeat(${this.attractions.length}, minmax(340px, 350px))`,
          'grid-gap' : '20px',
          'justify-content': this.attractions.length < 4 ? 'center' : 'start'
        }
      }
    },
    methods: {
      goLeft() {
        let elem = document.getElementsByClassName('express-attraction')[0]

        elem.scroll({
          left: elem.scrollLeft - document.getElementsByClassName('attraction')[0].scrollWidth - 20,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkChevrons()
        }, 300)
      },
      goRight() {

        let elem = document.getElementsByClassName('express-attraction')[0]

        elem.scroll({
          left: elem.scrollLeft + document.getElementsByClassName('attraction')[0].scrollWidth + 20,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkChevrons()
        }, 300)
      },
      checkChevrons() {

        let elem = document.getElementsByClassName('express-attraction')[0];
        if (elem.scrollLeft === 0) {
          this.scrollLeftt = false
        } else if (elem.scrollLeft > 0) {
          this.scrollLeftt = true;
        }

        let scrollLefti = parseInt(elem.scrollLeft.toFixed(0))

        if (scrollLefti + parseInt(elem.offsetWidth.toFixed(0)) === parseInt(elem.scrollWidth.toFixed(0))) {
          this.scrollRightt = false;
        } else if (scrollLefti + parseInt(elem.offsetWidth.toFixed(0)) !== parseInt(elem.scrollWidth.toFixed(0))) {
          this.scrollRightt = true;
        }
      },
      goTo(item) {
        if (item.split('.')[0] === 'www') {
          window.location.href = `https://${item}`;
        } else {
          window.location.href = `${item}`;
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  .express-attractions {
    position: relative;
    padding: 0 100px;
    max-width: 100%;
    overflow: hidden;

    .express-attraction {
      display: grid;
      max-width: 100%;
      overflow-x: hidden;
    }

    .right {
      position: absolute;
      left: 0;
      top: 40%;
      min-width: 30px;
      padding: 0;
      z-index: 100;
      &.hidden-arrow {
        display: none;
      }
    }

    .left {
      position: absolute;
      right: 0;
      top: 40%;
      min-width: 30px;
      padding: 0;
      z-index: 100;
      &.hidden-arrow {
        display: none;
      }
    }
  }

  @include media("<=tablet") {
    .express-attractions {
      padding: 0 50px;

      .express-attraction {
        justify-content: start !important;
      }
    }
  }

  @include media("<=phone") {
    .express-attractions {
      padding: 0 15px;

      .express-attraction {
        justify-content: start !important;
      }
    }
  }

</style>
