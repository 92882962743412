<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  .nearby_attractions h4 {
    margin-bottom: 20px;
    padding-top: 30px;
    border-top: dashed 1px grey;
  }

  .attractions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 20px;
    flex: 2;
  }

  .nearby_attractions {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }

  .attractions_wrapper {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 80px;
  }

  .loader {
    display: flex;
    min-height: 130px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .sort-and-attractions {
    flex: 2;

    .sort {
      margin: 10px 0 30px 35px;
      display: flex;
      align-items: center;

      p {
        margin: 0 10px 0 0;
      }

      > :last-child {
        margin-left: 10px;
      }
    }
  }

  .arrow {
    stroke: white;
    width: 15px;
    height: 15px;
    margin-left: 10px;

    &.black {
      stroke: #6a6c6f;
    }
  }

  .sort-buttons {
    span {
      font-size: 13px;

      svg {
        width: 16px;
        height: 12px;
      }
    }
  }

  @include media('<=tablet') {
    .sort-and-attractions {
      flex: 1;

      .sort {
        margin-left: 115px;
      }

      .attractions {
        margin: 0 100px;
        padding: 0;
      }
    }
  }

  @include media('<=phone') {
    .attractions_wrapper {
      flex-direction: column;
    }

    .sort-and-attractions {

      .sort {
        margin-left: 20px;
      }

      .attractions {
        margin: 0 auto;
        padding: 0;
      }
    }
  }

  .dropdown-item {
    &:hover {
      color: var(--bg-color);
    }
  }


</style>
<template>
  <div class="attractions_wrapper">
    <div class="loader" v-if="!!loading">
      <loader aria-label="loading"></loader>
    </div>
    <section class="sort-and-attractions" v-if="!loading && attraction_results.length > 0">

      <div class="sort" v-if="enableYelp"
           :aria-label="`Currently sorting by ${selected_sort.name} in ${selected_sort.type}ending way`">
        <p>{{ $t('lbl_sort_by') }}</p>

        <hk-dropdown v-if="access_status === false" style="flex: 0" aria-label="Sort attractions dropdown">
          <el-button aria-label="Click to open sort dropdown" :style="{'background': bookingColor}" slot="activator"
                     type="primary" size="medium">
            <span aria-hidden="true" style="display: flex; align-items: center; outline: unset">
              {{selected_sort.label}}
              <arrow-up aria-hidden="true" class="arrow" v-if="selected_sort.type === 'asc'"></arrow-up>
              <arrow-down aria-hidden="true" class="arrow" v-else></arrow-down>
            </span>
          </el-button>
          <ul slot="dropdown" slot-scope="{visible}">
            <template>
              <li style="display: flex; justify-content: space-between"
                  :style="{'background': selected_sort.name === option ? 'rgb(236, 245, 255)' : 'white'}"
                  @click="changeSorting(option)"
                  :aria-label="`Click to sort by ${option}`"
                  v-for="(option, i) in sort_options">
                <span aria-hidden="true" class="dropdown-item" :style="cssProps"
                      style="display: flex; align-items: center">
                {{ sort_labels[i] }}
              <arrow-up class="arrow black"
                        aria-hidden="true"
                        v-if="selected_sort.name === option && selected_sort.type === 'asc'"></arrow-up>
              <arrow-down class="arrow black"
                          aria-hidden="true"
                          v-if="selected_sort.name === option && selected_sort.type === 'desc'"></arrow-down>
                </span>
              </li>
            </template>
          </ul>

        </hk-dropdown>

        <div v-if="access_status" class="sort-buttons">
          <!--          <span>Active sort: {{ selected_sort.name }}</span>-->

          <el-button aria-label="Sort by Distance" type="primary" size="medium" @click="changeSorting('Distance')">
            <span :style="cssProps">
              {{ $t('lbl_distance') }}
              <arrow-up class="arrow black"
                        aria-hidden="true"
                        v-if="selected_sort.name === 'Distance' && selected_sort.type === 'asc'"></arrow-up>
              <arrow-down class="arrow black"
                          aria-hidden="true"
                          v-if="selected_sort.name === 'Distance' && selected_sort.type === 'desc'"></arrow-down>
            </span>

          </el-button>
          <el-button aria-label="Sort by Rating" type="primary" size="medium" @click="changeSorting('Rating')">
            <span :style="cssProps">
              {{ $t('lbl_rating') }}
              <arrow-up class="arrow black"
                        aria-hidden="true"
                        v-if="selected_sort.name === 'Rating' && selected_sort.type === 'asc'"></arrow-up>
              <arrow-down class="arrow black"
                          aria-hidden="true"
                          v-if="selected_sort.name === 'Rating' && selected_sort.type === 'desc'"></arrow-down>
            </span>

          </el-button>
        </div>

      </div>

      <div class="attractions">
        <!--<attraction-card v-loading="featured.loading" v-if="featuredAttractions !== null && featuredAttractions.length > 0"-->
        <!--v-for="featured in featuredAttractions" :key="featured.latitude" :info="featured"></attraction-card>-->

        <attraction-card v-loading="result.loading" v-for="result in attraction_results" v-if="!result.loading"
                         :key="result.id" :info="result"></attraction-card>
      </div>
    </section>
    <div class="tip" v-if="attraction_results.length === 0 && !loading" style="margin: 0px auto; margin-top: 40px;">
      <el-alert :title="$t('lbl_no_category_results')" type="warning" show-icon :closable="false">
      </el-alert>
    </div>
    <attractions-map v-if="!loading && attraction_results.length > 0 && theme !== 'express-theme'"
                     :results="attraction_results"/>
  </div>
</template>
<script>
  import {Loader, ArrowUp, ArrowDown} from 'vue-feather-icon';
  import attractionCard from '@/components/attractions/attraction-card.vue'
  import attractionsMap from '@/components/attractions/attractions-map.vue'
  import hkDropdown from '@/components/general/dropdown'
  import {cloneObject} from "vue-flatpickr-component/src/util";

  export default {
    components: {
      Loader: Loader.default,
      ArrowUp: ArrowUp.default,
      ArrowDown: ArrowDown.default,
      attractionCard,
      attractionsMap,
      hkDropdown
    },
    props: {
      attractionTypes: {
        type: Array,
        required: true,
      },
      attractions: {
        type: Array,
        required: true
      },
      tabType: {
        type: String,
        required: true
      },
      enableYelp: {
        type: Boolean,
        required: true
      }
    },
    mounted() {
      let missingAttractions = []
      let featuredAttractionsReturned = 0
      let featureAttractionUrl = `hotelbrand/properties/${this.hotelDetailsNeeded.id}/yelp-search-business`
      let bulkAPI = []
      let featuredAttrSample = {
        "rating": 0,
        "price": "",
        "phone": "Loading",
        "id": "rghorhdiE9-gl3fUG0scfg",
        "is_closed": false,
        "categories": [],
        "review_count": 0,
        "name": "Loading",
        "url": null,
        "coordinates": {"latitude": "0", "longitude": "0"},
        "image_url": null,
        "display_phone": "123123123",
        "location": {"city": 'loading'},
        "distance": "0",
        "transactions": [],
        "featured": true
      }

      let categories = this.attractionTypes.reduce((b, a, currentIndex) => {
        return b += currentIndex > 0 ? ',' + a : a;
      }, '');

      if (this.enableYelp) {
        this.loading = true

        this.$http.get(`hotelbrand/properties/${this.hotelDetailsNeeded.id}/yelp-search-business`, {
          params: {
            radius: 10000,
            categories: categories,
            sort_by: 'review_count',
            latitude: this.hotelDetailsNeeded.latitude,
            longitude: this.hotelDetailsNeeded.longitude
          }
        }).then(res => {
          this.attraction_results = res.data.businesses;
          this.attraction_results.forEach(item => {
            if (this.attractions.some(att => att.attraction_id === item.id)) {
              item.featured = true
              item.order_no = this.attractions.find(attraction => attraction.attraction_id === item.id).order_no
              featuredAttractionsReturned++
            } else {
              item.featured = false
              item.loading = false
              item.order_no = '100000'
              item.distance2 = this.distance(item.coordinates.latitude, item.coordinates.longitude, this.hotelDetailsNeeded.latitude, this.hotelDetailsNeeded.longitude)
            }
          })
          this.changeSorting(null)

          if (featuredAttractionsReturned === this.attractions.length) {
            return true
          }

          missingAttractions = this.attractions.filter(a => {
            if (this.attraction_results.find(att => att.id === a.attraction_id) === undefined) {
              this.attraction_results.unshift({
                ...featuredAttrSample,
                loading: true,
                id: a.id,
                order_no: a.order_no
              })
              return a
            }
          })

          bulkAPI = missingAttractions.sort((a, b) => a.order_no - b.order_no).map(att => {
            return this.$http.get(`${featureAttractionUrl}/${att.attraction_id}`)
          })

          Promise.all(bulkAPI).then(res => {
            let results = res.map((item, index) => {
              return {
                loading: false,
                ...item.data,
                featured: true,
                order_no: this.attraction_results[index].order_no,
                distance: this.distance(item.data.coordinates.latitude, item.data.coordinates.longitude, this.hotelDetailsNeeded.latitude, this.hotelDetailsNeeded.longitude)
              }
            })

            this.attraction_results = [...results, ...this.attraction_results]

            /*res.forEach((r, index) => {
              this.attraction_results.unshift({
                loading: false,
                ...r.data,
                featured: true,
                order_no: this.attraction_results[index].order_no,
                distance: this.distance(r.data.coordinates.latitude, r.data.coordinates.longitude, this.hotelDetailsNeeded.latitude, this.hotelDetailsNeeded.longitude)
              })

              this.attraction_results[index] = {
                loading: false,
                ...r.data,
                featured: true,
                order_no: this.attraction_results[index].order_no,
                distance: this.distance(r.data.coordinates.latitude, r.data.coordinates.longitude, this.hotelDetailsNeeded.latitude, this.hotelDetailsNeeded.longitude)
              }
            })*/

            // this.attraction_results = [...this.attraction_results]

          }).catch(err => {
            console.log('Attraction not found, ', err)
          })

        }).catch(err => {
          throw err;
        }).finally(() => {
          this.loading = false;
          this.$emit('eventDone', {type: this.tabType, results: this.attraction_results})
        })
      } else {
        let attractions = cloneObject(this.attractions)
        this.attraction_results = attractions.sort((a, b) => a.order_no - b.order_no)
      }
    },
    data() {
      return {
        attraction_results: [],
        loading: false,
        sort_options: ['Distance', 'Rating'],
        sort_labels: [this.$i18n.t('lbl_distance'), this.$i18n.t('lbl_rating')],
        selected_sort: {name: 'Distance', type: 'asc', label: this.$i18n.t('lbl_distance')}
      }
    },
    computed: {
      hotelDetailsNeeded() {
        let {latitude, longitude, id} = this.$store.state.hotel;
        return {latitude, longitude, id}
      },
      activeTabName() {
        return this.$store.state.explore_tab.name
      },
      bookingColor() {
        return this.$store.state.web_config.color_code
      },
      cssProps() {
        return {
          '--bg-color': this.bookingColor
        }
      },
      access_status() {
        return this.$store.state.access_status
      },
      featuredAttractions() {
        return this.attraction_results.filter(item => item.featured)
      },
      web_config() {
        return this.$store.state.web_config;
      },
      theme() {
        let theme = !!this.web_config && this.web_config.theme
        return `${theme.toLowerCase()}-theme`
      }
    },
    methods: {
      changeSorting(name) {

        if (name === 'Distance') {
          if (this.selected_sort.name === 'Distance') {
            this.selected_sort.type = this.selected_sort.type === 'asc' ? 'desc' : 'asc'
            this.selected_sort.label = this.$t('lbl_distance')
          } else {
            this.selected_sort.name = 'Distance'
            this.selected_sort.type = 'asc'
            this.selected_sort.label = this.$t('lbl_distance')
          }
        } else if (name === 'Rating') {
          if (this.selected_sort.name === 'Rating') {
            this.selected_sort.type = this.selected_sort.type === 'asc' ? 'desc' : 'asc'
            this.selected_sort.label = this.$t('lbl_rating')
          } else {
            this.selected_sort.name = 'Rating'
            this.selected_sort.type = 'desc'
            this.selected_sort.label = this.$t('lbl_rating')
          }
        }


        this.attraction_results = this.attraction_results.sort((a, b) => {
          let sortBy = this.selected_sort.name.toLowerCase()

          if (typeof (a[sortBy]) === "string" && typeof (b[sortBy]) === "string") {
            a[sortBy] = parseFloat(a[sortBy], 10);
            b[sortBy] = parseFloat(b[sortBy], 10);
          }

          if (a[sortBy] < b[sortBy]) {
            return this.selected_sort.type === 'asc' ? -1 : 1;
          }
          if (a[sortBy] > b[sortBy]) {
            return this.selected_sort.type === 'asc' ? 1 : -1;
          }
          return 0;
        })
      },
      distance(lat1, lon1, lat2, lon2) {
        var R = 6371; // km (change this constant to get miles)
        var dLat = (lat2 - lat1) * Math.PI / 180;
        var dLon = (lon2 - lon1) * Math.PI / 180;
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c * 1000;
        return `${d}`;
      }
    }
  };

</script>
