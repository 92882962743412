<template>
  <div class="attraction">
    <img v-if="image_url" :src="image_url" :alt="`${attraction.title} image`">
    <div v-else>{{ $t('lbl_no_images_available') }}</div>
    <h2 :aria-label="`Attraction name ${attraction.title}`">{{attraction.title}}</h2>
    <p>{{ attraction.description }}</p>
    <button v-if="attraction.external_link"
            :aria-label="`Check out ${attraction.title} attraction`"
            :style="{ 'background-color' : colorCode }" role="button"
            @click="$emit('activateAttraction')">
      {{ $t('lbl_explore') }}</button>
  </div>
</template>

<script>
  export default {
    name: "ExpressAttractions",
    props: ['attraction'],
    computed: {
      web_config() {
        return this.$store.state.web_config;
      },
      colorCode() {
        return !!this.web_config && this.web_config.color_code;
      },
      image_url() {
        return this.attraction.website_feature_attraction_images.length > 0 && this.attraction.website_feature_attraction_images.find(image => image.image_url).image_url
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  .attraction {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: 300px;
    }

    div {
      width: 100%;
      height: 300px;
      background: linear-gradient(to right, #FFB88C, #DE6262);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h2 {
      margin: 10px 0 20px 0;
      font-size: 1.1rem;
    }

    p {
      flex: 1;
      margin: 0 0 20px 0;
      font-size: .9rem;
      color: #545454;
      font-weight: 400;
      white-space: pre-wrap;
      text-align: justify;
    }

    button {
      padding: 1rem 2rem;
      color: #fff;
      font-size: 1rem;
    }
  }

  @include media("<=phone") {
    .attraction {
      margin: 0 0 2rem 0 !important;
      box-shadow: 0px 3px 11px -5px rgba(0, 0, 0, 0.2);

      button {
        margin-left: 1rem;
        margin-bottom: 1rem;
      }

      h2, p {
        padding: 0 1rem;
      }
    }
  }
</style>
