<template>
  <footer style="z-index:9999; position: relative;">
    <section class="info">
      <div class="hotel-info">
        <img :src="business_logo" alt="">
      </div>
      <div class="menu-items">
        <router-link aria-label="Go to Home Tab" target="_self" to="/">{{ $t('lbl_home') }}</router-link>
        <router-link aria-label="Go to Rooms Tab" v-if="!master_config" target="_self" to="/rooms">{{ $t('lbl_rooms') }}</router-link>
        <a href="#home_rooms" class="secondary-item" v-if="master_config">{{ $t('lbl_hotels') }}</a>
        <router-link aria-label="Go to Amenities Tab" v-if="!master_config" target="_self" to="/amenities">{{ $t('lbl_amenities') }}</router-link>
        <router-link aria-label="Go to Explore Tab" v-if="!master_config && activeCategories && activeCategories.length > 0" target="_self" active-class="active" to="/explore">{{ $t('lbl_explore') }}</router-link>
        <router-link aria-label="Go to Gallery Tab" target="_self" to="/gallery" v-if="!!gallery && !master_config ">{{ $t('lbl_gallery') }}</router-link>
        <router-link aria-label="Go to Packages tab" target="_self" to="/packages" v-if="!!packages && !master_config">{{ $t('lbl_packages') }}</router-link>
        <router-link aria-label="Go to Discounts Tab" target="_self" to="/discounts" v-if="!!discounts && !master_config">{{ $t('lbl_discounts') }}</router-link>

        <div class="dynamic-menus" v-if="main_menus.length>0" v-for="menu in main_menus">
          <div class="main-menu">
                <span :aria-label="`${menu.is_parent ? 'Click to open submenu' : `Click to go to ${menu.name} tab`}`" style="white-space: nowrap"
                      @click="menu.is_parent ? showSubMenu ? showSubMenu = false : showSubMenu = true : chooseMenu(menu)">
                  {{menu.name}}
                </span>
            <div class="sub-menus" v-if="showSubMenu && menu.sub_menus.length>0" >
              <a :aria-label="`This is a submenu, Redirect to ${submenu.name}`"
                 @click="chooseMenu(submenu); mobile_nav = false" v-for="submenu in menu.sub_menus">
                {{submenu.name}}
              </a>
            </div>
          </div>
        </div>

        <router-link aria-label="Go to Inquiry Tab" target="_self" active-class="active" to="/inquiry" v-if="!master_config && web_config.display_contact_us">{{ $t('lbl_hk_website_inquiry') }}</router-link>
      </div>
      <div class="contact-section">
        <address>
          <a target="_blank" :href="'tel:' + hotel.phone_no">
            <phone title="Phone Number"></phone>
            {{hotel.phone_no}}</a>
          <a target="_blank" :href="'mailto:' + hotel.email">
            <mail title="Email"></mail>
            {{hotel.email}}</a>
          <a target="_blank" :href="`http://maps.google.com/?t=k&q=${hotel.latitude},${hotel.longitude}`">
            <map-pin title="Address"></map-pin>
            {{hotel.address}},{{hotel.city}} {{hotel.state}} {{hotel.zip_code}}</a>
        </address>
        <div class="social-media">
          <p v-if="!!social_links.facebook || !!social_links.twitter || !!social_links.pinterest">
            {{ $t('lbl_follow_us_on') }}:</p>
          <div class="social-icons">
            <a title="Facebook" aria-label="Link for facebook page" v-if="!!social_links.facebook"
               :href="social_links.facebook">
              <img src="../../assets/static/facebook.svg" alt="Facebook Icon">
              <span aria-hidden="true" style="display: none">{{ $t('lbl_facebook') }}</span>
            </a>
            <a title="Twitter" aria-label="Link for twitter page" v-if="!!social_links.twitter"
               :href="social_links.twitter">
              <img src="../../assets/static/twitter-seeklogo.com.svg" alt="Twitter Icon">
              <span aria-hidden="true" style="display: none">{{ $t('lbl_twitter') }}</span>
            </a>
            <a title="Instagram" aria-label="Link for instagram page" v-if="!!social_links.instagram"
               :href="social_links.instagram">
              <img src="../../assets/static/Insta.svg" alt="Instagram Icon">
              <span aria-hidden="true" style="display: none">{{ $t('lbl_instagram') }}</span>
            </a>
            <a title="Pinterest" aria-label="Link for pinterest page" v-if="!!social_links.pinterest"
               :href="social_links.pinterest">
              <img src="../../assets/static/Pinterest.svg" alt="Pinterest Icon">
              <span aria-hidden="true" style="display: none">{{ $t('lbl_pinterest') }}</span>
            </a>
          </div>

          <div v-if="enable_trip_advisor == 'true'" id="TA_certificateOfExcellence313" class="TA_certificateOfExcellence custom-style">
            <ul style="display: flex; flex-wrap: wrap; flex-direction: row; justify-content: center"
                id="itm1kZ" class="TA_links 7GeMESJnBa">
              <li v-for="adv in tripAdvisorsJson" :id="adv.id" class="W3UFvGIwK"><a target="_blank"
                                                                                    :href="adv.redirect_to">
                <img width="60px" height="60px" style="border-radius: 50%" :src="adv.img"
                     alt="TripAdvisor"
                     class="widCOEImg" id="CDSWIDCOELOGO"/></a></li>
            </ul>
          </div>

          <div id="widgetContainerBox" data-isaddnofollow="false"></div>

        </div>
      </div>
    </section>

    <section class="copyrights">
        <div class="policy" v-if="policies.length > 0">
          <div class="policy-item" :key="item.id" v-for="(item, i) in policies" @click="$emit('openPolicy', item)">
            <span>{{ item.title }}</span>
            <span class="line" v-if="policies[i + 1]" style="padding: 0 10px">|</span>
          </div>
        </div>
        <div class="copyright" v-if="web_config.copyright_text">{{ web_config.copyright_text }}</div>
    </section>
  </footer>
</template>

<script>
  import {Facebook, Twitter, Instagram, MapPin, Phone, Mail, User } from 'vue-feather-icon';
  import Pinterest from '@/components/general/pinterest-icon.vue';
  import tripAdvisorsJson from './../../assets/trip-advisors'

  export default {
    name: "theme-footer",
    components: {
      Facebook: Facebook.default,
      Twitter: Twitter.default,
      Instagram: Instagram.default,
      MapPin: MapPin.default,
      Phone: Phone.default,
      User: User.default,
      Mail: Mail.default,
      Pinterest
    },
    beforeMount() {
      this.configure_dynamic_menus();
    },
    data() {
      return {
        mobile_nav: false,
        w_a_statement: false,
        other_statement: false,
        tripAdvisorsJson: tripAdvisorsJson,
        enable_trip_advisor: process.env.VUE_APP_ENABLE_TRIP_ADVISOR,
        main_menus: [],
        submenus: [],
        showSubMenu: false,
        active_menu: null,
        categories: [
          {
            id: 1,
            image: null,
            name: 'ATTRACTION',
            tags: ['landmarks', 'amusementparks', 'tours', 'museums', 'parks'],
          }, {
            id: 2,
            image: null,
            name: 'BARS',
            tags: ['lounges', 'breweries', 'wine_bars', 'wineries', 'divebars'],
          }, {
            id: 3,
            image: null,
            name: 'TRANSPORTATION',
            tags: ['taxis', 'buses', 'busstations', 'airports'],
          }, {
            id: 4,
            image: null,
            name: 'ENTERTAINMENT',
            tags: ['casinos', 'festivals', 'cinemas', 'theater'],
          }, {
            id: 5,
            image: null,
            name: 'ACTIVITIES',
            tags: ['golf', 'kids_activities', 'surfshop', 'climbing', 'diving', 'bungeejumping', 'gliding', 'gokarts', 'hiking', 'jetskis', 'mountainbiking', 'parasailing', 'rock_climbing', 'skiing', 'skydiving', 'snorkeling', 'surfing'],
          }, {
            id: 6,
            image: null,
            name: 'TOURS',
            tags: ['tours'],
          }]
      }
    },
    computed: {
      social_links() {
        return {
          facebook: !!this.web_config && this.web_config.facebook_url || '',
          twitter: !!this.web_config && this.web_config.twitter_url || '',
          pinterest: !!this.web_config && this.web_config.pinterest_url || '',
          instagram: !!this.web_config && this.web_config.instagram_url || ''
        }
      },
      hotel() {
        return this.$store.state.hotel;
      },
      business_logo() {
        return this.hotel.business_logo
      },
      web_config() {
        return !!this.hotel && this.$store.state.web_config;
      },
      master_config() {
        return !!this.$store.state.web_config.is_master && this.$store.state.web_config.is_master || false;
      },
      packages() {
        return !!this.hotel && this.$store.state.hotel.package_rates.filter(_package => !!_package.active).length > 0;
      },
      gallery() {
        return !!this.hotel && this.$store.state.not_touched_images.length > 0;
      },
      discounts() {
        return !!this.hotel && this.$store.getters.availableDiscounts.length > 0;
      },
      latitude() {
        return parseFloat(this.$store.state.hotel.latitude);
      },
      longitude() {
        return parseFloat(this.$store.state.hotel.longitude);
      },
      bookingColor() {
        return this.web_config.color_code
      },
      policies() {
        return this.web_config.property_website_config_policies || []
      },
      website_menus() {
        return !!this.hotel && this.$store.state.hotel.website_menus.filter(menu => menu.active === true);
      },
      activeCategories() {
        let activeAttractions = this.hotel.property_explore_attractions.filter(item => item.active)
        return this.categories.filter(c => activeAttractions.find(attr => attr.name === c.name))
      }
    },
    methods: {
      configure_dynamic_menus() {
        if (this.website_menus.length > 0) {
          this.website_menus.forEach(menu => {
            if ((menu.is_parent === true) || (menu.is_parent === false && menu.parent_website_menu_id === null)) {
              menu.sub_menus = [];
              this.main_menus.push({...menu, active_menu: menu.name});
            } else {
              this.submenus.push(menu);
            }
          })
        }
        if (this.submenus.length > 0) {
          this.submenus.forEach(submenu => {
            this.main_menus.forEach(menu => {
              if (submenu.parent_website_menu_id === menu.id) {
                menu.sub_menus.push({...submenu, active_menu: menu.name});
              }
            })
          })
        }
      },
      chooseMenu(submenu) {
        let pattern = /^((http|https|ftp):\/\/)/;
        let url_ = null;
        if (submenu.is_redirect_url) {
          if(!pattern.test(submenu.redirect_url)) {
            url_ = "http://" + submenu.redirect_url;
            window.open(url_,'_blank');
          }else{
            window.open(submenu.redirect_url,'_blank');
          }
        }else if((submenu.hasOwnProperty('sub_menus') && submenu.sub_menus.length===0)) {
          this.$router.push({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu : submenu.active_menu,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })
        }else if(!submenu.hasOwnProperty('sub_menus') && !submenu.is_redirect_url && !!submenu.parent_website_menu_id){
          this.$router.push({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu : submenu.active_menu,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  footer {
    position: relative;
    background-color: rgb(46, 46, 46);
    padding: 0;
    z-index: 0 !important;

    .info {
      padding: 50px 100px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: #fff;

      .hotel-info {
        img {
          width: 80px;
          height: 85px;
          border-radius: 8px;
        }
      }

      .menu-items {
        display: grid;
        grid-template-columns: 1fr 1fr;

        a, .dynamic-menus {
          padding: 0 0 20px 0;
          margin: 0 30px;
          cursor: pointer;
        }
      }

      .contact-section {
        display: flex;
        justify-content: space-between;
        flex: 0.7;

        address {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-right: 20px;

          svg {
            stroke: white;

            path {
              stroke: white !important;
            }
          }

          a {
            display: flex;
            align-items: center;
            padding: 5px 0;

            svg {
              margin-right: 5px;
            }
          }
        }

        .social-media {
          display: flex;
          flex-direction: column;

          p {
            margin: 10px 0 10px 10px;
          }

          .social-icons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            a {
              flex: 1;

              img {
                width: 24px;
                height: 24px;
              }
            }
          }

          .custom-style {
            margin-top: 30px;
            align-self: flex-end;
          }
        }
      }
    }

    .copyrights {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgb(36, 36, 36);
      padding: 20px 100px;
      color: #fff;

      .policy {
        display: flex;

        .policy-item {
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 768px) and (min-width: 415px) {
    footer {
      .info {
        .menu-items {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  @include media("<=tablet") {
    footer {
      .info {
        padding: 20px;
      }

      .custom-style {
        right: 5px;
        left: unset;
      }

      .copyrights {
        padding: 20px 80px 20px 20px;
      }
    }
  }

  @include media("<=phone") {
    footer {

      .info {
        padding: 20px;
        flex-direction: column;

        .hotel-info {
          margin-bottom: 30px;
        }

        .contact-section {
          flex-direction: column;

          address {
            margin: 30px 0;
          }
        }
      }

      .copyrights {
        padding: 20px;
        align-items: flex-start;

        .policy {
          flex-direction: column;

          .policy-item {
            margin-bottom: 10px;

            .line {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
