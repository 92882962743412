<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
  import localization_keys from '../localization_keys'
  import { mapState, mapMutations } from 'vuex'
  import languages from './utils/languages'
  import axios from 'axios';

  export default {
    beforeMount() {
      let initial_keys = JSON.parse(JSON.stringify(localization_keys));
      let lang_from_cookie = this.getCookie('hk_language') || process.env.VUE_APP_LANGUAGE_CODE
      let initial_locale = this.$route.query.hasOwnProperty('fromBooking') ? initial_keys.find(code => code.lang_code === lang_from_cookie) :
        initial_keys.find(code => code.lang_code === process.env.VUE_APP_LANGUAGE_CODE)
      let initial_code = initial_locale.lang_code.substring(0, 2)
      let all_languages = JSON.parse(JSON.stringify(languages));
      let seleceted_language = all_languages[initial_code]

      this.SET_LANGUAGE_CODE({ name: seleceted_language.nativeName, code: initial_locale.lang_code })
      this.$i18n.locale = initial_code
      this.$i18n.setLocaleMessage(initial_code, initial_locale.keys);
    },
    computed: {
      ...mapState({
        language: state => state.language_code,
        property: state => state.hotel,
      })
    },
    methods: {
      ...mapMutations({
        SET_LANGUAGE_CODE: 'setLanguageCode'
      }),
      getCookie(name) {
        return document.cookie.split('; ').reduce((accumulator, currentValue) => {
          const parts = currentValue.split('=')
          return parts[0] === name ? decodeURIComponent(parts[1]) : accumulator
        }, null)
      },
      injectSorjenScript(pt){
      
          if(!this.property || !this.property.website_config || !this.property.website_config.sojern_id)
              return
          console.log({pt})
          var params = {
            pt: pt, /* Page Type - CONVERSION or HOME_PAGE or PRODUCT or SEARCH or SHOPPING_CART or TRACKING */
            hd1: '', /* Check In Date. Format yyyy-mm-dd. Ex: 2015-02-14 */
            hd2: '', /* Check Out Date. Format yyyy-mm-dd. Ex: 2015-02-14 */
            hc1: this.property.city, /* Destination City */
            hs1: this.property.state, /* Destination State or Region */
            hpr: this.property.name, /* Hotel Property */
            hr: 1, /* Number of Rooms */
            hpid: this.property.website_config.sojern_id, /* Property ID */
            hcu: this.property.currency, /* Purchase Currency */
            hp: '', /* Purchase Price */
            hconfno: '', /* Confirmation Number */
            sha256_eml: ""
          };
          /* Please do not modify the below code. */
          var cid = [];
          var paramsArr = [];
          var cidParams = [];
          var pl = document.createElement('script');
          var defaultParams = {"vid":"hot","et":"hc"};
          for(let key in defaultParams) { params[key] = defaultParams[key]; }
          for(let key in cidParams) { cid.push(params[cidParams[key]]); }
          params.cid = cid.join('|');
          for(let key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) }
          pl.type = 'text/javascript';
          pl.async = true;
          pl.src = 'https://beacon.sojern.com/pixel/cp/225?f_v=v6_js&p_v=1&' + paramsArr.join('&');
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
      },
      getSojern(pt){
          if(!this.property || !this.property.website_config || !this.property.website_config.sojern_id)
              return
          console.log({pt})
          var params = {
            f_v: 'v6_js',
            p_v: '1',
            pt: pt, /* Page Type - CONVERSION or HOME_PAGE or PRODUCT or SEARCH or SHOPPING_CART or TRACKING */
            hd1: '', /* Check In Date. Format yyyy-mm-dd. Ex: 2015-02-14 */
            hd2: '', /* Check Out Date. Format yyyy-mm-dd. Ex: 2015-02-14 */
            hc1: this.property.city, /* Destination City */
            hs1: this.property.state, /* Destination State or Region */
            hpr: this.property.name, /* Hotel Property */
            hr: 1, /* Number of Rooms */
            hpid: this.property.website_config.sojern_id, /* Property ID */
            hcu: this.property.currency, /* Purchase Currency */
            hp: '', /* Purchase Price */
            hconfno: '', /* Confirmation Number */
            sha256_eml: '', 
            vid: "hot",
            et:"hc",
            cid: ''
          };
          const url = 'https://beacon.sojern.com/pixel/cp/225'
          const  headers = { 'Content-Type': 'application/javascript' }
          try {
            axios.get(url, {params, headers})
          } catch (error) {
            console.log({error})
          }
      },
    },
    watch: {
    '$route' (to, from) {
      if(to.name === 'home' || to.name === 'rooms') {
        this.injectSorjenScript(to.name === 'home' ? 'HOME_PAGE' : 'PRODUCT')
        // this.getSojern(to.name === 'home' ? 'HOME_PAGE' : 'PRODUCT')
      }
    },
    'property' (newVal, oldVal) {
      if(newVal && newVal.website_config && newVal.website_config.sojern_id) {
        this.injectSorjenScript('HOME_PAGE')
        //this.getSojern('HOME_PAGE')
      }
    }
  }
  };
</script>
