import {CheckCircle, X} from 'vue-feather-icon';
import carousel from '@/components/general/carousel/carousel.vue';
import divider from '@/components/general/divider.vue';
import amenitiesPopup from '@/components/amenities-popup.vue';

export default {
  components: {
    carousel,
    divider,
    check: CheckCircle.default,
    amenitiesPopup,
    close: X.default
  },
  props: {
    reverse: {
      required: false,
      default: false,
    },
    images: {
      type: Array,
      required: false,
      default: () => (
        [{url: null}]
      ),
    },
    subheading: {
      required: false,
    },
    title: {
      required: false,
    },
    description: {
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    titleInPicture: {
      required: false,
      default: false,
    },
    subdomain: {
      required: false,
      default: false,
    },
    viewType: {
      required: true,
      type: String
    }
  },
  data: () => ({
    showPopup: false,
    hotel_name: null,

  }),
  mounted() {
    console.log("reverse",this.reverse);
  },
  computed: {
    hotel() {
      this.hotel_name = this.$store.state.hotel.name
      console.log("reverse",this.reverse);
      return this.$store.state.hotel !== null;
    },
    hotel_id() {
      return this.$store.state.hotel.id
    },
    rendered_photos() {
      let photos = [];
      this.images.forEach((image) => {
        if (!image.hasOwnProperty('url')) {
          image.url = image.image_url
          photos.push(image);
        } else {
          photos = this.images;
        }
      });
      return photos;
    },
    room_amenities() {
      return this.$store.getters.roomAmenities;
    },
    amenitiesChunks() {
      if (!!this.id && !!this.room_amenities && this.room_amenities.hasOwnProperty(this.id)) {
        let filteredAmenities = this.room_amenities[this.id].filter(amenitie => amenitie.active);
        filteredAmenities = filteredAmenities.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        return this.chunk(filteredAmenities, 3);
      }
      return false;
    },
    splicedAmenititesChunks() {
      if (this.amenitiesChunks && this.amenitiesChunks.length > 2) {
        return this.amenitiesChunks.slice(0, 2);
      }
      return this.amenitiesChunks;
    },
    master_config() {
      return !!this.$store.state.web_config && this.$store.state.web_config.is_master || false;
    },
    web_config() {
      return this.$store.state.web_config;
    },
    buttonColor() {
      return this.web_config.booking_color_id
    },
    theme() {
      let theme = !!this.web_config && this.web_config.theme
      return `${theme.toLowerCase()}-theme`
    }
  },
  methods: {
    setPreferredRoom() {
      const env = process.env;
      const url = `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&rn=${this.title}&url=${window.location.href}`;
      window.location.href = url;
    },
    chunk(arr, chunkSize) {
      var chunks = [];
      let extraChunkSize;
      let subByOne = false
      for (var i = 0, len = arr.length; i < len; i += chunkSize) {
        if (i === 3 && arr.length > 6) {
          extraChunkSize = 2
          subByOne = true;
        } else if (subByOne) {
          i -= 1;
          subByOne = false;
        } else {
          extraChunkSize = chunkSize
        }
        chunks.push(arr.slice(i, i + extraChunkSize));
      }
      return chunks;
    },
    goToChildProperty(url) {
      window.location.href = url;
    },
  }
};
