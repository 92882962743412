<template>
  <div class="package" :style="{ 'flex-direction': reverse ? 'row-reverse' : 'row' }">
    <div class="images">
      <carousel :zoomable="false" size="full" :carousel-images="package.all_images" :hide-dots="true"></carousel>
    </div>
    <div class="info">
      <h2>{{ package.name }}</h2>
      <p>{{ package.description }}</p>
      <p :style="{ color: buttonColor }">{{ package.nights }} Nights</p>
      <button role="button" aria-label="Go to Booking Module" :style="{ 'background-color': buttonColor }"
              @click="$emit('buttonClick')">{{ $t('lbl_book_now') }}</button>
    </div>
  </div>
</template>

<script>
  import Carousel from "./general/carousel/carousel.vue";

  export default {
    name: "ExpressPackage",
    props: ['package', 'reverse'],
    components: {
      Carousel
    },
    data() {
      return {
       screenWidth: window.innerWidth
      }
    },
    computed: {
      web_config() {
        return this.$store.state.web_config;
      },
      buttonColor() {
        return this.web_config.color_code
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '~css_vars';
  @import '~mixins';

  .package {
    display: flex;
    align-items: center;

    .images {
      flex: 1;
    }

    .info {
      flex: 1;

      h2 {
        margin: 0 20px 15px 20px;
        font-size: 1.1rem;
      }

      p {
        margin: 0 20px 15px 20px;
        font-size: .9rem;
        white-space: pre-wrap;
        text-align: justify;
      }

      button {
        padding: 10px 20px;
        margin: 0 20px;
        font-size: 1rem;
        color: #fff;
      }
    }
  }

  @include media("<=tablet"){
    .package {
      margin-bottom: 0;
    }
  }

  @include media("<=phone") {
    .package {
      flex-direction: column !important;
      align-items: stretch;
      margin-bottom: 30px;
    }
  }
</style>
