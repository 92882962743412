<template>
  <div id="global-message" class="global-message">
    <span class="message">{{ message }}
      <span class="link" aria-label="Click to learn more" v-if="show_url" @click="learnMore()">
        {{ $t('lbl_hk_website_learn_more') }}</span>
    </span>
    <span class="close" @click="close"><x></x></span>
  </div>
</template>

<script>
  import {X} from 'vue-feather-icon'
  import {mapMutations} from 'vuex'

  export default {
    name: "GlobalMessage",
    components: {
      x: X.default
    },
    data() {
      return {}
    },
    computed: {
      hotel() {
        return this.$store.state.hotel;
      },
      website_menus() {
        return this.hotel.website_menus
      },
      web_config() {
        return !!this.hotel && this.$store.state.web_config;
      },
      message() {
        return this.web_config.global_message
      },
      show_url() {
        return !!this.web_config.global_message_link
      }
    },
    methods: {
      ...mapMutations({
        setGlobalMessage: 'setGlobalMessage'
      }),
      close() {
        this.setGlobalMessage(false)
      },
      learnMore() {
        let submenu = null
        let pattern = new RegExp(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/);
        let url_pattern = /^((http|https|ftp):\/\/)/;
        let url_ = null;
        let routeData

        if (pattern.test(this.web_config.global_message_link)) {
          if (!url_pattern.test(this.web_config.global_message_link)) {
            url_ = "http://" + this.web_config.global_message_link;
            window.open(url_, '_blank');
          } else {
            window.open(this.web_config.global_message_link, '_blank');
          }
        } else {
          submenu = this.website_menus.filter(item => item.active).find(menu => menu.name === this.web_config.global_message_link)

          routeData = this.$router.resolve({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu: submenu.name,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })

          window.open(routeData.href, '_blank');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';

  .global-message {
    position: fixed;
    padding: 10px;
    left: 0;
    right: 0;
    top: 0;
    z-index: 101;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    .message {
      flex: 1;
      padding: 0 40px;
      text-align: center;
    }

    .link {
      cursor: pointer;
      text-decoration: underline;
    }

    .close {
      cursor: pointer;
      height: 21px;

      svg {
        stroke: #fff;
        width: 21px;
        height: 21px;
      }
    }
  }

  @include media('<=tablet') {
    .global-message {
      .message {
        padding: 0 20px;
        text-align: center;
      }
    }
  }

  @include media('<=phone') {
    .global-message {
      .message {
        padding: 0 5px;
        text-align: center;
      }
    }
  }

</style>
