import {ChevronRight, ZoomIn, X, Loader} from 'vue-feather-icon'
import layout from '@/layouts/page/page.vue'
import divider from '@/components/general/divider.vue'

import {mapState} from 'vuex'

export default {
  mounted() {
    if (this.hotel === false) {
      this.$store.dispatch('setPropDetails');
    } else {
      document.getElementsByTagName('title')[0].innerHTML = 'Gallery | ' + this.hotel_name;
    }
  },
  components: {
    layout,
    divider,
    ZoomIn: ZoomIn.default,
    X: X.default,
    ChevronIcon: ChevronRight.default,
    loader: Loader.default
  },
  methods:{
    triggerLightbox(value) {
      this.srcNgaClicku= value;
      this.showLightbox= true;
      this.$refs.esclistener.focus();
    },
    previous() {
      let index = this.not_touched_images.findIndex(img => img.id === this.srcNgaClicku.id);

      if(index > 0 && index < this.not_touched_images.length) {
        this.srcNgaClicku = this.not_touched_images[index - 1];
      }
      this.$refs.esclistener.focus();

    },
    next() {
      let index = this.not_touched_images.findIndex(img => img.id === this.srcNgaClicku.id);

      if(index >= 0 && index < this.not_touched_images.length-1) {
        this.srcNgaClicku = this.not_touched_images[index + 1];
      }
      this.$refs.esclistener.focus();

    },
    setImageFromDots(image){
      let index = this.not_touched_images.findIndex(img => img.id === image.id);
      this.$refs.esclistener.focus();
      this.srcNgaClicku = image;
    }
  },
  computed: {

    ...mapState({
      hotel_name: state => state.hotel.name
    }),

    hotel() {
      return this.$store.state.hotel !== null;
    },
    property_code() {
      return this.$store.state.property_code;
    },
    gallery_images(){
      return !!this.$store.state.gallery_images && this.$store.state.gallery_images;
    },
    custom_images() {
      let new_array = [...this.gallery_images.array1, ...this.gallery_images.array2, ...this.gallery_images.array3]
      return new_array;
    },
    not_touched_images(){
      return !!this.$store.state.not_touched_images && this.$store.state.not_touched_images;
    }

  },
  data: () => ({
    srcNgaClicku: null,
    showLightbox:false,

  })
};
