<template>
	<layout v-if="hotel">
		<section slot="content" id="packages-page">
			<divider :style="{ 'margin': theme === 'express-theme' ? '110px 100px 50px 100px' : '160px 0 5rem 0' }">{{ $t('lbl_packages') }}</divider>

			<section id="home_rooms" :class="{ 'explore_rooms': theme !== 'express-theme', 'express': theme === 'express-theme' }">
        <express-package v-if="theme === 'express-theme'" :key="package_.id" v-for="(package_, index) in packages_with_covers"
                         :reverse="index%2 !== 0" :package="package_" @buttonClick="setPackage(package_)" />

				<room-view v-if="theme !== 'express-theme'" :title-in-picture="true"
                   v-for="(package_, index) in packages_with_covers"
                   :view-type="'package'" :key="package_.id"
                   :title="package_.name"
                   :description="package_.description">
					<carousel slot="carousel-slot" :image-type="'Package '+package_.name" :zoomable="false" size="full" :carousel-images="package_.all_images" :hide-dots="true">
						<h2 :class="theme" class="title-in-image">{{package_.name}}</h2>
					</carousel>
					<div slot="action_buttons" id="action_buttons">
						<button aria-label="Go to Booking Module" class="book-package-button" :style="{ 'background-color' : buttonColor || '#409EFF' }"
                    role="button" :class="theme" @click="setPackage(package_)">{{ $t('lbl_book_now') }}</button>
					</div>
				</room-view>
			</section>
		</section>
	</layout>
  <div class="loader" v-else>
    <loader></loader>
  </div>
</template>

<script>
import { Facebook, Twitter, Instagram, Share2, Loader } from 'vue-feather-icon';
import layout from '@/layouts/page/page.vue'
import divider from '@/components/general/divider.vue'
import roomView from '@/components/room_view/room_view.vue'
import carousel from '@/components/general/carousel/carousel.vue'
import ExpressPackage from "../components/ExpressPackage";

import {mapState} from 'vuex'

export default {
	components: {
		layout,
		divider,
		roomView,
		carousel,
		Facebook: Facebook.default,
		Twitter: Twitter.default,
		Instagram: Instagram.default,
		Share2: Share2.default,
    loader: Loader.default,
    ExpressPackage
	},
	mounted() {
		if (this.hotel === false) {
			this.$store.dispatch('setPropDetails');
		} else {
			document.getElementsByTagName('title')[0].innerHTML = 'Packages | ' + this.hotel_name;
		}
	},
	data: () => ({
		app_id: 348796902210955,
		selected_package: null,
		url: null,
	}),
	computed: {
	  ...mapState({
      hotel_id: state => state.hotel.id,
      hotel_name: state => state.hotel.name,
    }),
		hotel() {
			return this.$store.state.hotel !== null;
		},
		welcomeText() {
			if (!this.web_config) {
				return false;
			}
			let packages = this.web_config.property_website_images.PACKAGES;
			let sampletext = {
				title: '',
				description: '',
				image: require('@/assets/static/packages.jpg'),
			};
			if (packages.length === 0 || packages[0].image_url === null) {
				return { images: sampletext.image };
			} else {
				return {

					title: packages[0].tagline,
					description: packages[0].description,
					images: packages[0].image_url,
				}
			}
		},
		packages() {
			return !!this.hotel && this.$store.state.hotel.package_rates.filter(_package => !!_package.active);
		},
    packages_with_covers(){
      return !!this.packages && this.packages.filter(_package => !!_package.cover_photo ? (_package.all_images = [..._package.package_rate_images,{url:_package.cover_photo}]): _package.all_images = [..._package.package_rate_images])
    },
		web_config() {
			return this.$store.state.web_config;
		},
    buttonColor() {
      return this.web_config.color_code
    },
    theme() {
      let theme = !!this.web_config && this.web_config.theme
      return `${theme.toLowerCase()}-theme`
    }
	},
	methods: {
		setPackage(selected_package) {
			this.selected_package = selected_package;
			let url = encodeURIComponent(window.location.href)
			this.url = `${process.env.VUE_APP_BOOKING_URL}?pc=${process.env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&url=${url}&package_type=${this.selected_package.code}&package_id=${selected_package.id}&skip_search=true`;
			window.location.href = this.url;
		},
	}
}

</script>

<style lang="scss">
@import '~css_vars';
@import '~mixins';

.loader {
  display: flex;
  min-height: 180px;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%
}


#packages-page {

	.explore_rooms {
		padding: 0 50px 50px 50px;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(340px, 500px));
		grid-column-gap: 3rem;
		justify-content: center;
		>div {
			width: 100%;
			margin-bottom: 10px;
		}
	}

  .express {
    margin: 0 0 30px 0;
  }

	#action_buttons {
		margin-top: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		button {
			font-weight: 300;
			font-family: 'Roboto';
			letter-spacing: 1px;
			padding: .8rem 2rem;
      &.bold-theme {
        border-radius: 0;
        /*background: #aad571;*/
        font-weight: bold;
        /*&:hover {
          box-shadow: 0px 0px 31px -2px rgba(#aad571, 0.7);
        }*/
      }
		}
	}

	.icons {
		margin-right: auto;
		display: flex;
		align-items: center;
		height: 30px;
		justify-content: center;
		svg {
			margin-right: 10px;
			width: 20px;
			height: 20px;
			cursor: pointer;
			stroke: lighten($black, 40);
			path {
				stroke: lighten($black, 40);
			}
			&:hover {
				stroke: lighten($black, 10);
				path {
					stroke: lighten($black, 10);
				}
			}
		}
		.social-links {
			display: none;
			align-items: center;
			padding: 5px;
			margin: -10px;
		}
		&:hover {
			.share-icon {
				display: none;
			}
			.social-links {
				display: flex;
			}
		}
	}
	.custom_room_view {
		flex-direction: column;
		background-color: white;
		&.reverse {
			background-color: white;
		}
		>* {
			width: unset;
		}
		p {
			font-size: 1rem;
			font-weight: 400;
			font-family: 'Gilda Display';
			margin-bottom: 2rem;
		}
		.title-in-image {
			font-family: 'Gilda Display';
			font-size: 1.65rem;
      &.bold-theme {
        font-family: 'Roboto';
        font-weight: bold;
      }
      @include media('<=phone') {
        font-size: 1rem;
      }
		}
		.block-content {
			height: 100%;
			padding: 1.5rem;
			overflow: hidden;
		}
	}
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS styles go here */

	#packages-page{
		.explore_rooms{
			display: flex;
			max-width: 1200px;
			margin: 0 auto;
		}
	}

}


</style>
