<template>
  <header id="overlay-header" :style="{ 'top': headerTopValue }">

    <section title="Contact Us Section" class="contact-info">
      <address>
        <a target="_blank" :href="'tel:' + phone_no"><phone aria-label="Phone"></phone> {{`${phone_no}`}}</a>
        <a target="_blank" :href="'mailto:' + email"><mail aria-label="Email"></mail> {{email}}</a>
        <a target="_blank" :href="`http://maps.google.com/?t=k&q=${hotelDetails.latitude},${hotelDetails.longitude}`"><map-pin aria-label="Address"></map-pin> {{address}},{{hotelDetails.city}} {{hotelDetails.state}} {{hotelDetails.zip_code}}

        </a>
      </address>
    </section>

    <div class="logo" v-if="business_logo">
      <h1>{{hotel_name}}</h1>
      <img :src="business_logo" :alt="`${hotel_name} Logo`">
    </div>

    <div class="menu">

      <div class="lang" @click="$emit('showLanguages')" v-if="languages.length > 1">
        <img src="../../assets/static/earth.svg" alt="">
        {{ language.name }}
      </div>

      <div style="height: 24px">
        <div style="height: 24px" role="button" aria-label="Click to open menu" class="mobile-menu"
             @click="showMenu ? showMenu = false : showMenu = true">
          <menu-icon></menu-icon>
        </div>
        <nav v-if="showMenu" id="navigation" :class="{'mobile-active': mobile_nav === true}">
          <div @click="showMenu = false" v-if="mobile_nav || showMenu" class="close-icon">
            <x id="close-mobile-menu"></x>
          </div>
          <router-link aria-label="Go to Home Tab" target="_self" active-class="active" to="/"><strong>{{ $t('lbl_home') }}</strong></router-link>
          <router-link aria-label="Go to Rooms Tab" v-if="!master_config" target="_self" active-class="active" to="/rooms"><strong>{{ $t('lbl_rooms') }}</strong></router-link>
          <a href="#home_rooms" class="secondary-item" v-if="master_config"><strong>{{ $t('lbl_hotels') }}</strong></a>
          <router-link aria-label="Go to Amenities Tab" v-if="!master_config" target="_self" active-class="active" to="/amenities"><strong>{{ $t('lbl_amenities') }}</strong></router-link>
          <router-link aria-label="Go to Explore Tab" v-if="!master_config && activeCategories && activeCategories.length > 0" target="_self" active-class="active" to="/explore"><strong>{{ $t('lbl_explore') }}</strong></router-link>
          <router-link aria-label="Go to Gallery Tab" target="_self" active-class="active" to="/gallery" v-if="!!gallery && !master_config "><strong>{{ $t('lbl_gallery') }}</strong></router-link>
          <router-link aria-label="Go to Packages tab" target="_self" active-class="active" to="/packages" v-if="!!packages && !master_config"><strong>{{ $t('lbl_packages') }}</strong></router-link>
          <router-link aria-label="Go to Discounts Tab" target="_self" active-class="active" to="/discounts" v-if="!!discounts && !master_config"><strong>{{ $t('lbl_discounts') }}</strong></router-link>

          <div class="dynamic-menus" @click="hoveredMenu === null ? hoveredMenu = menu : hoveredMenu = null"
               v-if="main_menus.length>0"
               v-for="(menu,index) in main_menus">
            <div :class="{'active-menu': active_menu === menu.name}" class="main-menu">
              <span :aria-label="`${menu.is_parent ? 'Click to open submenu' : `Click to go to ${menu.name} tab`}`"
                    style="white-space: nowrap"
                    @click="chooseMenu(menu)">{{menu.name}}</span>
            </div>
            <template v-if="hoveredMenu !== null">
              <div class="dynamic-menus"
                   v-if="menu.name === hoveredMenu.name"
                   v-for="(submenu,index) in hoveredMenu.sub_menus">
                <div :class="{'active-menu': active_menu === submenu.name}" class="main-menu">
                  <span :aria-label="`This is a submenu, Redirect to ${submenu.name}`" style="white-space: nowrap"
                        @click="chooseMenu(submenu)">{{submenu.name}}</span>
                </div>
              </div>
            </template>
          </div>

          <router-link aria-label="Go to Inquiry Tab" target="_self" active-class="active" to="/inquiry" v-if="!master_config && web_config.display_contact_us">{{ $t('lbl_hk_website_inquiry') }}</router-link>
        </nav>
      </div>

    </div>
  </header>
</template>

<script>
  import { Menu, Phone, Mail, X, MapPin } from 'vue-feather-icon';
  import locales from '../../../localization_keys'
  import {mapState} from 'vuex'

  export default {
    name: "overlay-header",
    components: {
      Phone: Phone.default,
      Mail: Mail.default,
      X: X.default,
      menuIcon: Menu.default,
      MapPin: MapPin.default
    },
    props: ['globalMessage'],
    beforeMount() {
      const env = process.env;
      let url;

      this.languages = JSON.parse(JSON.stringify(locales))

      this.configure_dynamic_menus();

      url = `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&url=${window.location.href}`;
      this.book_url = url;
    },
    mounted() {
      let element = document.getElementById('overlay-header');
      element.style.setProperty('--hover-color', `${this.web_config.booking_color_id}`)

      this.active_menu = this.$route.params.active_menu;
      this.body_style = document.body.style.overflow
    },
    data() {
      return {
        languages: [],
        mobile_nav: false,
        book_url: null,
        main_menus: [],
        submenus: [],
        active_menu: null,
        showMenu: false,
        body_style: null,
        hoveredMenu: null,
        categories: [{
          id: 1,
          image: null,
          name: 'ATTRACTION',
          tags: ['landmarks', 'amusementparks', 'tours', 'museums', 'parks'],
        }, {
          id: 2,
          image: null,
          name: 'BARS',
          tags: ['lounges', 'breweries', 'wine_bars', 'wineries', 'divebars'],
        }, {
          id: 3,
          image: null,
          name: 'TRANSPORTATION',
          tags: ['taxis', 'buses', 'busstations', 'airports'],
        }, {
          id: 4,
          image: null,
          name: 'ENTERTAINMENT',
          tags: ['casinos', 'festivals', 'cinemas', 'theater'],
        }, {
          id: 5,
          image: null,
          name: 'ACTIVITIES',
          tags: ['golf', 'kids_activities', 'surfshop', 'climbing', 'diving', 'bungeejumping', 'gliding', 'gokarts', 'hiking', 'jetskis', 'mountainbiking', 'parasailing', 'rock_climbing', 'skiing', 'skydiving', 'snorkeling', 'surfing'],
        }, {
          id: 6,
          image: null,
          name: 'TOURS',
          tags: ['tours'],
        }],
      }
    },
    computed: {
      ...mapState({
        hotel_id: state => state.hotel.id,
        hotel_name: state => state.hotel.name,
        phone_no: state => state.hotel.phone_no,
        email: state => state.hotel.email,
        address: state => state.hotel.address,
        business_logo: state => state.hotel.business_logo,
        hotelDetails: state => state.hotel,
        language: state => state.language_code,
        global_message: state => state.show_global_message
      }),
      hotel() {
        return this.$store.state.hotel !== null;
      },
      master_config() {
        return !!this.$store.state.web_config.is_master && this.$store.state.web_config.is_master || false;
      },
      packages() {
        return !!this.hotel && this.$store.state.hotel.package_rates.filter(_package => !!_package.active).length > 0;
      },
      gallery() {
        return !!this.hotel && this.$store.state.not_touched_images.length > 0;
      },
      discounts() {
        return !!this.hotel && this.$store.getters.availableDiscounts.length > 0;
      },
      web_config() {
        return !!this.hotel && this.$store.state.web_config;
      },
      website_menus() {
        return !!this.hotel && this.$store.state.hotel.website_menus.filter(menu => menu.active === true);
      },
      activeCategories() {
        let activeAttractions = this.hotelDetails.property_explore_attractions.filter(item => item.active)
        return this.categories.filter(c => activeAttractions.find(attr => attr.name === c.name))
      },
      headerTopValue() {
        if (!this.global_message) {
          return '0'
        } else {
          return `${this.globalMessage}px`
        }
      }
    },
    methods: {
      configure_dynamic_menus() {
        if (this.website_menus.length > 0) {
          this.website_menus.forEach(menu => {
            if ((menu.is_parent === true) || (menu.is_parent === false && menu.parent_website_menu_id === null)) {
              menu.sub_menus = [];
              this.main_menus.push({...menu, active_menu: menu.name});
            } else {
              this.submenus.push(menu);
            }
          })
        }
        if (this.submenus.length > 0) {
          this.submenus.forEach(submenu => {
            this.main_menus.forEach(menu => {
              if (submenu.parent_website_menu_id === menu.id) {
                menu.sub_menus.push({...submenu, active_menu: menu.name});
              }
            })
          })
        }
      },
      chooseMenu(submenu) {
        let pattern = /^((http|https|ftp):\/\/)/;
        let url_ = null;

        if (submenu.is_redirect_url) {
          if (!pattern.test(submenu.redirect_url)) {
            url_ = "http://" + submenu.redirect_url;
            window.open(url_, '_blank');
          } else {
            window.open(submenu.redirect_url, '_blank');
          }
        } else if ((submenu.hasOwnProperty('sub_menus') && submenu.sub_menus.length === 0)) {
          this.$router.push({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu: submenu.active_menu,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })
        } else if (!submenu.hasOwnProperty('sub_menus') && !submenu.is_redirect_url && !!submenu.parent_website_menu_id) {
          this.$router.push({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu: submenu.active_menu,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  #overlay-header {
    --hover-color: #fff;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: absolute;
    top: 0;
    z-index: 20;
    right: 40px;
    left: 40px;
    height: 90px;

    .contact-info {
      color: #19a7f3;
      font-size: 11px;
      flex: 1;
      z-index: 1;

      address {
        display: flex;
        justify-content: flex-start;
        margin-right: 10px;
        margin-top: 5px;
        font-style: normal;

        a {
          padding: 0 5px;
          display: flex;
          align-items: center;

          svg {
            //stroke: rgba(255, 255, 255, 0.9);
            stroke: #c1c1c1;
            height: 16px;
          }
        }
      }
    }

    .logo {
      flex: 0.7;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      top: 24px;
      align-items: center;

      img {
        width: 90px;
        height: 100px;
        position: relative;
      }

      h1 {
        font-size: 1.1rem;
        display: flex;
        margin: 5px 0;
      }
    }

    .menu {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
      position: relative;
      align-items: center;

      .lang {
        display: flex;
        align-items: center;
        margin-right: 20px;
        color: $blue;
        cursor: pointer;
        opacity: 0.9;
        font-size: 0.7rem;

        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        &:hover{
          opacity: 1;
        }
      }

      .mobile-menu {
        cursor: pointer;

        svg {
          stroke: #333;
        }
      }

      nav {
        display: flex !important;
        overflow-y: auto;
        flex-direction: column;
        position: absolute;
        top: 30px;
        right: 0;
        background-color: white;
        padding: 10px 15px;

        .close-icon {
          display: none;
        }

        a {
          text-transform: uppercase;
          padding: 5px 4px;
          font-size: .9rem;
          color: #333;
          font-weight: 500;
          text-align: right;

          &.router-link-exact-active {
            color: var(--hover-color);
          }

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            color: var(--hover-color);
          }
        }

        .active-menu {
          color: var(--hover-color);
        }

        .main-menu {
          position: relative;
          cursor: pointer;
          font-size: 0.9rem;
          text-align: right;
          padding: 5px 4px;

          .sub-menus {
            visibility: visible;
            display: flex;
            position: absolute;
            border-top: 2px solid white;
            background-color: rgba(0, 0, 0, 0.55);
            min-width: 200px;
            top: 30px;
            right: -100%;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            padding: 12px 16px;
            z-index: 1;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            transform: translateY(1em);
            transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

            a {
              padding-bottom: 5px;
              margin: 0;
            }
          }

          span {
            font-size: 0.9rem;
            font-weight: 500;
            color: #333;
            text-transform: uppercase;
          }

          span:hover {
            color: var(--hover-color);
          }
        }

        .main-menu:focus .sub-menus,
        .main-menu:focus-within .sub-menus,
        .main-menu:hover .sub-menus {
          visibility: visible;
          display: flex;
          transform: translateY(0%);
          transition-delay: 0s, 0s, 0.3s;
        }

        .secondary-item {
          margin-left: 10px;
        }
      }
    }
  }

  @include media('<=tablet') {
    header {
      z-index: 102;
      background-color: white;
      left: 0;
      right: 0;

      .contact-info {
        display: none;
      }

      .logo {
        justify-content: flex-end;
        margin-left: 15px;
        top: 0;
        flex-direction: row-reverse;
        flex: 2;

        h1 {
          margin-left: 10px;
        }
      }

      .menu {

        nav {
          position: fixed;
          box-sizing: border-box;
          text-align: center;
          height: 100%;
          width: 100%;
          padding: 0 1rem 1rem 1rem;
          background-color: white;
          z-index: 99999;
          top: 0;
          left: 0;
          bottom: 0;

          .close-icon {
            display: block;
            position: absolute;
            right: 10px;
            top: 5px;
          }

          a {
            border-bottom: 1px solid #ebebeb;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
          }

          a.router-link-exact-active {
            color: var(--hover-color);
          }

          .dynamic-menus {
            padding: 10px;
            border-bottom: solid 1px #ebebeb;
            width: 100%;
            position: unset;

            span {
              color: #333;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .main-menu {
              font-size: 1.5rem;

              .sub-menus {
                display: none;
                position: unset;
                z-index: 1;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }

  @include media('<=phone') {
    header .logo {
      flex-direction: row-reverse;
      top: 0;
      flex: 4;
      justify-content: flex-end;

      img {
        max-width: 80px;
        height: 80px;
      }

      h1 {
        font-size: 0.8em;
      }
    }
  }
</style>
