<template>
  <div class="section-card-image" :style="{ 'flex-direction': reverse ? 'row-reverse' : 'row' }">
    <div class="image">
      <img v-if="section.image_url" :src="section.image_url" :alt="`Picture of ${section.title} section image`">
    </div>
    <div class="content" :style="{ 'padding': reverse ? '0 30px 0 0': '0 30px 0 30px' }">
      <h4>{{ section.title }}</h4>
      <p :aria-label="section.description">{{ section.description }}
        <!--<el-popover
          v-if="section.description.length > 600"
          placement="right"
          width="400"
          trigger="click"
          :content="section.description">
          <span style="cursor:pointer;" slot="reference">(more)</span>
        </el-popover>-->
      </p>
      <button role="button" :aria-label="`Explore ${section.title}`" :style="{ 'background-color': buttonColor }"
        v-if="section.redirect_url" @click="goTo(section.redirect_url)">{{ $t('lbl_explore') }}</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "sectionCardImage",
    props: ['section', 'reverse'],
    computed: {
      web_config() {
        return this.$store.state.web_config;
      },
      buttonColor() {
        return this.web_config.color_code
      }
    },
    mounted() {
      console.log("section",this.section)
    },
    methods: {
      goTo(item) {
        if (item.split('.')[0] === 'www') {
          window.open(`https://${item}`, '_blank');
        } else {
          window.open = (`${item}`, '_blank');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';

  .section-card-image {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;

    .image {
      flex: 1;
      /*background-size: cover;
      background-position: center;
      background-repeat: no-repeat;*/

      img {
        width: 100%;
        height: 300px;
      }
    }

    .content {
      flex: 1;

      h4 {
        font-size: 1.1rem;
        margin: 0;
      }

      p {
        margin: 10px 0;
        font-size: .9rem;
        color: #6a6c6f;
        white-space: pre-wrap;
        text-align: justify;
      }

      button {
        padding: 10px 20px;
        color: #fff;
        font-size: 1rem;
      }
    }
  }

  @include media('<=phone') {
    .section-card-image {
      flex-direction: column !important;
      align-items: stretch;

      .content {
        padding: 20px 0 !important;
      }
    }
  }

</style>
