import {ChevronRight, ZoomIn, X} from 'vue-feather-icon'
import layout from '@/layouts/page/page.vue'
import divider from '@/components/general/divider.vue'
import sectionCard from "./sectionCard";
import sectionCardImage from "./sectionCardImage";

import {mapState} from 'vuex'

export default {
  mounted() {


    let for_loop = 0;
    this.loading = true;

    if (!this.hotel) {
      this.$store.dispatch('setPropDetails');
    }

    if (this.$route.params.hasOwnProperty('images')) {
      this.params = this.$route.params;
    } else {
      let submenu = this.hotel_details.website_menus.filter(item => item.active).
        find(menu => menu.name === this.$route.params.name)


      this.params = {
        tagline: submenu.tagline,
        description: submenu.description,
        images: submenu.images,
        name: submenu.name,
        cover_image: submenu.cover_image,
        active_menu: submenu.name,
        menu_sections: submenu.property_website_menu_sections,
        enable_section_images: submenu.enable_section_images
      }
    }

    for_loop = Math.ceil(this.params.images.length / 9);
    this.params.sliced_images = [];
    for (let i = 0; i < for_loop; i++) {

      this.params.sliced_images[i] = this.params.images.slice(9 * i, 9 * (i + 1));

    }

    this.loading = false;

    this.$nextTick(() => {
      document.getElementsByTagName('title')[0].innerHTML = this.params.name + ' | ' + this.hotel_name;
    })
  },
  components: {
    layout,
    divider,
    ZoomIn:ZoomIn.default,
    X: X.default,
    ChevronIcon: ChevronRight.default,
    sectionCard,
    sectionCardImage
  },
  methods: {
    triggerLightbox(value) {
      this.srcNgaClicku= value;
      this.showLightbox= true;
      this.$refs.esclistener.focus();
    },
    previous() {
      let index = this.params.images.findIndex(img => img.id === this.srcNgaClicku.id);

      if(index > 0 && index < this.params.images.length) {
        this.srcNgaClicku = this.params.images[index - 1];
      }
      this.$refs.esclistener.focus();

    },
    next() {
      let index = this.params.images.findIndex(img => img.id === this.srcNgaClicku.id);
      // this.tranformDivStyle =
      if(index >= 0 && index < this.params.images.length-1) {
        this.srcNgaClicku = this.params.images[index + 1];
      }
      this.$refs.esclistener.focus();

    },
    setImageFromDots(image){
      let index = this.params.images.findIndex(img => img.id === image.id);
      this.$refs.esclistener.focus();
      this.srcNgaClicku = image;
    }
  },
  computed: {
    ...mapState({
      hotel_details: state => state.hotel,
      hotel_name: state => state.hotel.name
    }),
    hotel() {
      return this.$store.state.hotel !== null;
    },
    property_code() {
      return this.$store.state.property_code;
    },
    web_config() {
      return !!this.hotel && this.$store.state.web_config;
    },
    color_code() {
      return this.web_config.color_code
    },
    menu_sections_sorted() {
      return this.params.menu_sections.sort((a, b) => a.order_no - b.order_no)
    },
    show_with_images() {
      return this.params.enable_section_images
    },
    boxStyle() {
      return {
        top: this.params.cover_image !== null || this.params.images.length > 0 ? '-80px' : 'unset',
        margin: this.params.cover_image !== null || this.params.images.length > 0 ? '0 100px' : '150px 100px 100px'
      }
    },
    theme() {
      const theme = !!this.web_config && this.web_config.theme
      return `${theme.toLowerCase()}-theme`
    }
  },
  data: () => ({
    params: null,
    loading: false,
    srcNgaClicku: null,
    showLightbox:false
  })
};
