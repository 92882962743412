<template>
  <div class="modal-container">
    <div class="container">
      <div class="header">
        <h3>{{ $t('lbl_please_select_your_preferred_langauge') }}</h3>
        <span @click="close()"> <x></x> </span>
      </div>
      <div class="content">
        <div class="list">
          <span @click="changeLanguage(item)" v-for="(item, i) in languages" :key="i">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { X } from 'vue-feather-icon'
  import localizationList from '../../localization_keys'
  import { mapState, mapMutations } from 'vuex'
  import locale from "../utils/locale";
  import default_languages from '../utils/languages'

  export default {
    name: "LangModal",
    components: {
      X: X.default
    },
    beforeMount() {
      let all_languages = JSON.parse(JSON.stringify(default_languages))
      this.languages = JSON.parse(JSON.stringify(localizationList)).map(item => {
        let lang_code = item.lang_code.substring(0, 2)
        let lang = all_languages[lang_code]
        let lang_name = lang ? lang.nativeName : ''

        return {
          name: lang_name,
          code: item.lang_code,
          keys: item.keys
        }
      })
    },
    data() {
      return {
        languages: []
      }
    },
    computed: {
      ...mapState({
        language: state => state.language_code
      })
    },
    methods: {
      ...mapMutations({
        SET_LANGAUGE_CODE: 'setLanguageCode'
      }),
      close(){
        this.$emit('closeModal')
      },
      changeLanguage(item){
        let locale_key = item.code.substring(0, 2)
        this.SET_LANGAUGE_CODE({ name: item.name, code: item.code })
        this.$i18n.locale = locale_key

        if (Object.entries(item.keys).length > 0) {
          this.$i18n.setLocaleMessage(locale_key, item.keys);
        } else {
          this.$i18n.setLocaleMessage(locale_key, JSON.parse(JSON.stringify(locale)));
        }

        this.close()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~css_vars';

  .modal-container {
    z-index: 102;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($black, .4);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .container {
      background-color: white;
      width: 70vw;
      height: 70vh;
      border-radius: 4px;
      overflow: hidden;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px;

        span {
          font-size: 3rem;
          cursor: pointer;
          svg {
            stroke-width: 3px;
          }
        }
      }

      .content {
        padding: 0 50px 50px 50px;

        .list {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;

          span {
            font-size: 1rem;
            color: #b84c16;
            cursor: pointer;
            flex: 33.3%;
            margin-bottom: 15px;
            text-align: center;
          }
        }
      }
    }
  }
</style>
