<style src="./room_view_bold_theme.scss" lang="scss"></style>
<template src="./room_view_bold_theme.html"></template>
<script>
  import {CheckCircle, MapPin} from 'vue-feather-icon';
  import carousel from '@/components/general/carousel/carousel.vue';
  import divider from '@/components/general/divider.vue';

  export default {
    components: {
      carousel,
      divider,
      check: CheckCircle.default,
    },
    props: {
      reverse: {
        required: false,
        default: false,
      },
      images: {
        type: Array,
        required: false,
        default: () => (
          [{ url: null }]
        ),
      },
      subheading: {
        required: false,
      },
      title: {
        required: false,
      },
      description: {
        required: false,
      },
      id: {
        type: String,
        required: false,
      },
      titleInPicture: {
        required: false,
        default: false,
      },
      subdomain: {
        required:false,
        default:false,
      },
    },
    computed: {
      hotel() {
        return this.$store.state.hotel !== null;
      },
      hotel_id() {
        return this.$store.state.hotel.id;
      },
      rendered_photos() {
        let photos = [];
        this.images.forEach((image) => {
          if (!image.hasOwnProperty('url')) {
            image.url = image.image_url
            photos.push(image);
          }else {
            photos = this.images;
          }
        });
        return photos;
      },
      web_config() {
        return this.$store.state.web_config
      },
      room_amenities() {
        return this.$store.getters.roomAmenities;
      },
      amenitiesChunks() {
        if (!!this.id && !!this.room_amenities && this.room_amenities.hasOwnProperty(this.id)) {
          let filteredAmenities = this.room_amenities[this.id].filter(amenitie => amenitie.active);
          filteredAmenities = filteredAmenities.sort((a,b) => {
            if(a.name < b.name) {return - 1}
            if(a.name > b.name) {return 1}
            return 0
          })
          return this.chunk(filteredAmenities, 3);
        }
        return false;
      },
      master_config(){
        return !!this.$store.state.web_config && this.$store.state.web_config.is_master || false;
      },
      buttonColor() {
        return this.web_config.booking_color_id
      }
    },
    methods: {
      setPreferredRoom() {
        const env = process.env;
        const url = `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&rn=${this.title}&url=${window.location.href}`;
        window.location.href = url;
      },
      chunk(arr, chunkSize) {
        var chunks = [];
        for (var i = 0, len = arr.length; i < len; i += chunkSize) {
          chunks.push(arr.slice(i, i + chunkSize));
        }
        return chunks;
      },
      goToChildProperty(url){
        window.location.href = url;
      },
    }
  };
</script>
