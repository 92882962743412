<template>
  <div class="featured">
    <div class="trapez"></div>
    <span style="text-transform: uppercase">{{ $t('lbl_featured') }}</span>
  </div>
</template>

<script>
  export default {
    name: "featured"
  }
</script>

<style lang="scss" scoped>
  .featured{
    position: absolute;
    top: 18px;
    left: -37px;
    color: #fff;
    z-index: 10;
    transform: rotate(-45deg);

    .trapez{
      border-bottom: 40px solid #409EFF;
      border-left: 40px solid transparent;
      border-right: 40px solid transparent;
      height: 0;
      width: 150px;
    }

    /*.trapez::before {
      content: '';
      bottom: -10px;
      left: 0px;
      position: absolute;
      background-color: rgba(64, 158, 255, 0.8);
      border-left: 15px solid rgba(64, 158, 255, 0.5);
      border-right: 1px solid #fff;
      border-bottom: 10px solid #fff;
    }

    .trapez::after {
      content: '';
      bottom: -10px;
      right: 0px;
      position: absolute;
      background-color: rgba(64, 158, 255, 0.8);
      border-right: 15px solid rgba(64, 158, 255, 0.5);
      border-left: 1px solid #fff;
      border-bottom: 10px solid #fff;
    }*/

    span{
      position: absolute;
      top: 8px;
      right: 32px;
      font-size: 16px;
      font-weight: 500;
    }
  }
</style>
