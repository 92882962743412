import { render, staticRenderFns } from "./popup.vue?vue&type=template&id=6f63400e&scoped=true"
import script from "./popup.vue?vue&type=script&lang=js"
export * from "./popup.vue?vue&type=script&lang=js"
import style0 from "./popup.vue?vue&type=style&index=0&id=6f63400e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f63400e",
  null
  
)

export default component.exports