<template>
	<div class="explore-cards" :class="{ 'grid-explore': activeCategories.length < 3 }">
		<e-card v-for="(category, index) in activeCategories" :background="the_web_images[category.name].url" :title="category.label.toUpperCase()" :key="category.name" @click.native="setExploreCategory(category, index)"></e-card>
	</div>
</template>
<script>
  import eCard from './e-card.vue'
export default {
	components: {
		eCard,
	},
	mounted() {},
	data() {
		return {
      categories: [{
        id: 1,
        image: null,
        name: 'ATTRACTION',
        label: this.$t('lbl_attraction'),
        tags: ['landmarks', 'amusementparks', 'tours', 'museums', 'parks'],
      }, {
        id: 2,
        image: null,
        name: 'BARS',
        label: this.$t('lbl_bars'),
        tags: ['lounges', 'breweries', 'wine_bars', 'wineries', 'divebars'],
      }, {
        id: 3,
        image: null,
        name: 'TRANSPORTATION',
        label: this.$t('lbl_transportation'),
        tags: ['taxis', 'buses', 'busstations', 'airports'],
      }, {
        id: 4,
        image: null,
        name: 'ENTERTAINMENT',
        label: this.$t('lbl_entertainment'),
        tags: ['casinos', 'festivals', 'cinemas', 'theater'],
      }, {
        id: 5,
        image: null,
        name: 'ACTIVITIES',
        label: this.$t('lbl_activities'),
        tags: ['golf', 'kids_activities', 'surfshop', 'climbing', 'diving', 'bungeejumping', 'gliding', 'gokarts', 'hiking', 'jetskis', 'mountainbiking', 'parasailing', 'rock_climbing', 'skiing', 'skydiving', 'snorkeling', 'surfing'],
      }, {
        id: 6,
        image: null,
        name: 'TOURS',
        label: this.$t('lbl_tours'),
        tags: ['tours'],
      }]
    }
	},
	computed: {
    hotel() {
      return this.$store.state.hotel;
    },
		the_web_images() {
			let to_extract = this.categories.map(category => category.name);
			let new_struct = {};
			to_extract.forEach(extract => {
				let struct = {
					title: extract,
					url: null,
				};
				if (!!this.web_config && this.web_config.hasOwnProperty(extract.toUpperCase()) && this.web_config[extract.toUpperCase()].length > 0) {
					struct.url = this.web_config[extract.toUpperCase()][0].image_url;
				}
				if (struct.url === null) {
					struct.url = require(`@/assets/static/explore_categories/${extract}.jpg`);
				}
				new_struct[extract] = struct;
			});
			return new_struct;
		},
		web_config() {
			return this.$store.state.web_config && this.$store.state.web_config.property_website_images;
		},
    activeAttractions() {
      return !!this.hotel && this.hotel.property_explore_attractions.filter(item => item.active)
    },
    activeCategories() {
      return this.categories.filter(c => this.activeAttractions.find(attr => attr.name === c.name))
    }
	},
	methods: {
		setExploreCategory(category, index) {
		  category.id = index
			this.$store.commit('setExploreTab', category);
			this.$router.push('/explore');
		},
	},
}

</script>
<style lang="scss" scoped>
@import '~mixins';

.explore-cards {
	display: grid;
	grid-template-columns: repeat(3, minmax(300px, 1fr));
}

.grid-explore{
  grid-template-columns: repeat(2, 1fr);
}

@include media('<=tablet') {
	.explore-cards {
		grid-template-columns: 1fr 1fr;
	}

  .grid-explore{
    grid-template-columns: 1fr;
  }
}

@include media('<=phone') {
	.explore-cards {
		grid-template-columns: 1fr;
	}

  .grid-explore{
    grid-template-columns: 1fr;
  }
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS styles go here */

	.explore-cards{
		display: flex;
		flex-wrap: wrap;

		.e-card{
			max-width: 33.3333%;
		}
	}

}

</style>
