<template>
  <div class="welcome-content">
    <h6 :aria-label="`Tagline ${hotel_tagline}`" v-if="hotel_tagline">{{hotel_tagline}}</h6>
    <h2>{{ $t('lbl_welcome_to') }} {{hotel_name}}</h2>
    <p style="white-space: pre-wrap">{{additional_details}}</p>

    <carousel aria-label="Hotel Images" dots-position="express" :animated="true" :carousel-images="roomCovers"></carousel>
  </div>
</template>

<script>
  import carousel from '@/components/general/carousel/carousel.vue';
  import {mapState} from 'vuex'

  export default {
    name: "ExpressWelcomeTheme",
    components: {
      carousel
    },
    props: ['roomCovers'],
    computed: {
      ...mapState({
        hotel: state => state.hotel !== null,
        hotel_tagline: state => state.hotel.tag_line,
        hotel_name: state => state.hotel.name,
        additional_details: state => state.hotel.additional_details
      }),
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  .welcome-content {
    h6 {
      margin: 20px auto 10px auto;
      text-align: center;
      font-weight: 400;
    }

    h2 {
      margin: 0 auto;
      text-align: center;
      font-weight: 400;
    }

    p {
      text-align: center;
      margin: 20px 100px;
      font-weight: 300;
      font-size: 14px;
    }
  }

  @include media("<=tablet") {
    .welcome-content {
      p {
        margin: 20px 40px;
      }
    }
  }

  @include media("<=phone") {
    .welcome-content {
      p {
        margin: 20px 10px;
      }
    }
  }
</style>
