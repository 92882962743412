<style lang="scss" scoped>
@import '~mixins';

.section-heading,
.section-heading-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: white;
}

.section-heading-content {
  padding: 0px 2rem;
  margin: 0px 2rem;
}

.divider-line {
  height: 1px;
  width: 100%;
  position: absolute;
  top: 40%;
  border-bottom: dashed 1px rgba(0, 0, 0, .3);
}

.section-content {
  background-color: white;
  padding: 0px 2rem;
}

.section-heading .section-content p {
  margin: 0px;
  font-size: .8rem;
  margin-top: .5rem;
  letter-spacing: 2px;
}

h3.view-rooms {
  text-transform: uppercase;
  margin: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.el-divider {
  position: relative;
  grid-column: full;
  box-sizing: border-box;
  margin: 5rem 0px;
  h3 {
    background-color: white;
    padding: 0px 2rem;
    font-family: 'Gilda Display',
  }
  p {
    font-family: 'Roboto';
  }
}

@include media('<=tablet') {
  .el-divider {
    margin-left: 0 !important;
    margin-right: 0 !important;

    h3 {
      padding: 0px 0px;
      font-size: 15px;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .divider-line{
    width: auto;
  }

  .section-heading-content{
    max-width: 100%;
    margin: 0 auto;

    .view-rooms{
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

</style>
<template>
  <div class="el-divider">
    <div class="section-heading">
      <span class="divider-line"></span>
      <div class="section-heading-content">
        <h3 class="view-rooms vr">
          <slot>{{ $t('lbl_explore_our_rooms') }}</slot>
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
/* ============
 * Divider Component
 * ============
 *
 * todo: add documentation here!
 */

export default {};

</script>
