<template>
  <div class="award" aria-label="Award Item">
    <img :src="award.image_url" alt="Award Image">
    <h4>{{ award.title }}</h4>
    <p :aria-label="`Award Description: ${award.description}`">{{ award.description }}</p>
    <button @click="exploreAward" :aria-label="`Visit ${award.title}`" class="button" v-if="award.redirect_url" role="button" :style="{ 'background-color': buttonColor }">
      {{ $t('lbl_explore') }}
    </button>
  </div>
</template>

<script>
  export default {
    name: "AwardView",
    props: ['award'],
    computed: {
      web_config() {
        return this.$store.state.web_config;
      },
      buttonColor() {
        return this.web_config.color_code;
      }
    },
    methods: {
      exploreAward() {
        let pattern = /^((http|https|ftp):\/\/)/;
        let url_ = null;

        if (!pattern.test(this.award.redirect_url)) {

          url_ = "http://" + this.award.redirect_url;
          window.open(url_, '_blank');

        } else {
          window.open(this.award.redirect_url, '_blank');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  .award {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    img {
      width: 100%;
      height: 280px;
    }

    h4 {
      margin: 20px 0;
      font-size: 1rem;
    }

    p {
      flex: 1;
      margin: 0 0 20px 0;
      font-size: .9rem;
      color: #545454;
      font-weight: 400;
      white-space: pre-wrap;
      text-align: justify;
    }

    .button {
      padding: 16px 32px;
      font-size: 1rem;
      color: #fff;
    }
  }


</style>
