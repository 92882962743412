<style lang="scss"></style>
<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17" height="17" viewBox="0 0 17 17" class="pinterest">
    <g>
    </g>
    <path d="M15.025 6.225c0 3.819-2.123 6.662-5.246 6.662-1.049 0-2.035-0.568-2.365-1.213-0.568 2.238-0.682 2.667-0.682 2.667-0.203 0.746-0.619 1.493-0.985 2.074-1.043 0.737-1.14-0.404-1.14-0.404-0.024-0.684-0.012-1.505 0.166-2.237 0 0 0.189-0.785 1.25-5.285-0.315-0.62-0.315-1.543-0.315-1.543 0-1.441 0.835-2.516 1.872-2.516 0.885 0 1.314 0.67 1.314 1.467 0 0.885-0.57 2.213-0.859 3.439-0.24 1.037 0.518 1.871 1.529 1.871 1.847 0 3.084-2.363 3.084-5.158 0-2.137-1.44-3.729-4.045-3.729-2.945 0-4.778 2.2-4.778 4.652 0 0.848 0.252 1.442 0.644 1.91 0.178 0.215 0.203 0.29 0.139 0.543-0.049 0.177-0.15 0.607-0.201 0.771-0.063 0.253-0.266 0.341-0.48 0.253-1.353-0.558-1.986-2.049-1.986-3.718 0-2.756 2.325-6.068 6.928-6.068 3.717 0 6.156 2.692 6.156 5.562z" fill="#fff"/>
  </svg>
</template>
<script>
/* ============
 * Pinterest-icon Component
 * ============
 *
 * todo: add documentation here!
 */

export default {
};

</script>
