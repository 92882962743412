<template>
  <header :style="{ 'top': headerTopValue }">
    <a href="#main_content" class="skip_to_content" style="color: transparent !important;">Skip to main content.</a>

    <div class="logo">
      <img :src="sanitize(business_logo)" :alt="`${hotel_name} Logo`">
      <h1>{{ hotel_name }}</h1>
    </div>

    <div class="menu">
      <section title="Contact Us Section" class="contact-info">
        <div class="lang" @click="$emit('showLanguages')" v-if="languages.length > 1">
          <img src="../../assets/static/world.svg" alt="">
          {{ language.name }}
        </div>
        <address>
          <a target="_blank" :href="'tel:' + phone_no"><phone aria-label="Phone"></phone> {{`${phone_no}`}}</a>
          <a target="_blank" :href="'mailto:' + email"><mail aria-label="Email"></mail> {{email}}</a>
          <a target="_blank" :href="`http://maps.google.com/?t=k&q=${hotelDetails.latitude},${hotelDetails.longitude}`"><map-pin aria-label="Address"></map-pin> {{address}},{{hotelDetails.city}} {{hotelDetails.state}} {{hotelDetails.zip_code}}</a>
        </address>
      </section>

      <div @click="(mobile_nav = true)" class="mobile-menu">
        <menu-icon></menu-icon>
      </div>

      <nav id="navigation" :class="{'mobile-active': mobile_nav === true}">
        <div @click="(mobile_nav = false)" class="close-icon">
          <x></x>
        </div>
        <router-link aria-label="Go to Home Tab" target="_self" active-class="active" to="/"><strong>{{ $t('lbl_home') }}</strong></router-link>
        <router-link aria-label="Go to Rooms Tab" v-if="!master_config" target="_self" active-class="active" to="/rooms"><strong>{{ $t('lbl_rooms') }}</strong></router-link>
        <a href="#home_rooms" class="secondary-item" v-if="master_config"><strong>{{ $t('lbl_hotels') }}</strong></a>
        <router-link aria-label="Go to Amenities Tab" v-if="!master_config" target="_self" active-class="active" to="/amenities"><strong>{{ $t('lbl_amenities') }}</strong></router-link>
        <router-link aria-label="Go to Explore Tab" v-if="!master_config && activeCategories && activeCategories.length > 0" target="_self" active-class="active" to="/explore"><strong>{{ $t('lbl_explore') }}</strong></router-link>
        <router-link aria-label="Go to Gallery Tab" target="_self" active-class="active" to="/gallery" v-if="!!gallery && !master_config "><strong>{{ $t('lbl_gallery') }}</strong></router-link>
        <router-link aria-label="Go to Packages tab" target="_self" active-class="active" to="/packages" v-if="!!packages && !master_config"><strong>{{ $t('lbl_packages') }}</strong></router-link>
        <router-link aria-label="Go to Discounts Tab" target="_self" active-class="active" to="/discounts" v-if="!!discounts && !master_config"><strong>{{ $t('lbl_discounts') }}</strong></router-link>

        <a :href="search_reservation_link" class="secondary-item" v-if="showSearchReservationButton"><strong>Search Reservation</strong></a>

        <div class="accessible-menu" v-if="access_status" v-for="menu in parent_main_menu">
          <button :aria-label="`Click to open submenu`" aria-haspopup="true" aria-expanded="false" :class="{'active-menu': active_menu === menu.name}" @click="showSubMenu ? showSubMenu = false : showSubMenu = true"><strong>{{ menu.name }}</strong></button>
          <ul aria-label="Dropdown" v-if="showSubMenu">
            <li v-for="item in menu.sub_menus">
              <button :aria-label="`Click to redirect to ${item.name}`" @click="chooseMenu(item)" target="_blank"><strong>{{ item.name }}</strong></button>
            </li>
          </ul>
        </div>

        <div class="accessible-menu" v-if="access_status" v-for="menu in single_main_menu">
          <button :aria-label="`Go to ${menu.name} tab`" :class="{'active-menu': active_menu === menu.name}" @click="chooseMenu(menu)"><strong>{{ menu.name }}</strong></button>
        </div>

        <div class="dynamic-menus" v-if="access_status === false && main_menus.length>0" v-for="(menu,index) in main_menus">
          <div :class="{'active-menu': active_menu === menu.name}" class="main-menu">
            <span :aria-label="`${menu.is_parent ? 'Click to open submenu' : `Click to go to ${menu.name} tab`}`" style="white-space: nowrap" @click="menu.is_parent ? showSubMenu === true ? showSubMenu = false : showSubMenu = true : chooseMenu(menu)"><strong>{{menu.name}}</strong></span>
            <div class="sub-menus" v-if="showSubMenu && menu.sub_menus.length > 0">
              <a :aria-label="`This is a submenu, Redirect to ${submenu.name}`" @click="chooseMenu(submenu)" v-for="submenu in menu.sub_menus">
                <strong>{{submenu.name}}</strong>
              </a>
            </div>
          </div>
        </div>

        <router-link aria-label="Go to Inquiry Tab" target="_self" active-class="active" to="/inquiry" v-if="!master_config && web_config.display_contact_us">{{ $t('lbl_hk_website_inquiry') }}</router-link>

        <a aria-label="Go to Booking Module" target="_self" style="flex-shrink: 0;" class="secondary-item book" :href="book_url" v-if="!master_config">
          <span :style="buttonColor" class="book-button"><strong>{{ $t('lbl_book_now') }}</strong></span>
        </a>
        <a aria-label="Go to Booking Module" target="_self" style="flex-shrink: 0;" class="secondary-item book" href="#home_rooms" v-else>
          <span :style="buttonColor" class="book-button"><strong>{{ $t('lbl_book_now') }}</strong></span>
        </a>

      </nav>
    </div>
  </header>
</template>

<script>
  import { Menu, Phone, Mail, X, MapPin } from 'vue-feather-icon';
  import {mapState} from 'vuex'
  import locales from '../../../localization_keys'

  export default {
    components: {
      Phone: Phone.default,
      Mail: Mail.default,
      X: X.default,
      menuIcon: Menu.default,
      MapPin: MapPin.default
    },
    props: ['globalMessage'],
    beforeMount() {
      const env = process.env;
      let url;

      this.languages = JSON.parse(JSON.stringify(locales))

      this.configure_dynamic_menus();

      url = `${env.VUE_APP_BOOKING_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&url=${window.location.href}`;
      this.book_url = url;

      this.search_reservation_link = `${env.VUE_APP_SEARCH_RESERVATION_URL}?pc=${env.VUE_APP_PROPERTY_CODE}&property_id=${this.hotel_id}&url=${window.location.href}`;
    },
    mounted() {
      this.active_menu = this.$route.params.active_menu;
    },
    data() {
      return {
        languages: [],
        mobile_nav: false,
        book_url: null,
        search_reservation_link: null,
        main_menus: [],
        submenus: [],
        active_menu: null,
        showSubMenu: false,

        categories: [{
          id: 1,
          image: null,
          name: 'ATTRACTION',
          tags: ['landmarks', 'amusementparks', 'tours', 'museums', 'parks'],
        }, {
          id: 2,
          image: null,
          name: 'BARS',
          tags: ['lounges', 'breweries', 'wine_bars', 'wineries', 'divebars'],
        }, {
          id: 3,
          image: null,
          name: 'TRANSPORTATION',
          tags: ['taxis', 'buses', 'busstations', 'airports'],
        }, {
          id: 4,
          image: null,
          name: 'ENTERTAINMENT',
          tags: ['casinos', 'festivals', 'cinemas', 'theater'],
        }, {
          id: 5,
          image: null,
          name: 'ACTIVITIES',
          tags: ['golf', 'kids_activities', 'surfshop', 'climbing', 'diving', 'bungeejumping', 'gliding', 'gokarts', 'hiking', 'jetskis', 'mountainbiking', 'parasailing', 'rock_climbing', 'skiing', 'skydiving', 'snorkeling', 'surfing'],
        }, {
          id: 6,
          image: null,
          name: 'TOURS',
          tags: ['tours'],
        }],
      }
    },
    computed: {
      ...mapState({
        hotel_id: state => state.hotel.id,
        hotel_name: state => state.hotel.name,
        phone_no: state => state.hotel.phone_no,
        email: state => state.hotel.email,
        address: state => state.hotel.address,
        business_logo: state => state.hotel.business_logo,
        hotelDetails: state => state.hotel,
        language: state => state.language_code,
        global_message: state => state.show_global_message
      }),
      hotel() {
        return this.$store.state.hotel !== null;
      },
      master_config() {
        return !!this.$store.state.web_config.is_master && this.$store.state.web_config.is_master || false;
      },
      showSearchReservationButton(){
        return this.$store.state.web_config.enable_search_reservation
      },
      packages() {
        return !!this.hotel && this.$store.state.hotel.package_rates.filter(_package => !!_package.active).length > 0;
      },
      gallery() {
        return !!this.hotel && this.$store.state.not_touched_images.length > 0;
      },
      discounts() {
        return !!this.hotel && (this.$store.getters.availableDiscounts && this.$store.getters.availableDiscounts.length > 0);
      },
      web_config() {
        return !!this.hotel && this.$store.state.web_config;
      },
      website_menus() {
        return !!this.hotel && this.$store.state.hotel.website_menus.filter(menu => menu.active === true);
      },
      buttonColor() {
        return {
          'box-shadow': `${this.web_config.booking_color_id} 0px 0px 11px 2px`,
          'background-color': this.web_config.booking_color_id
        }
      },
      access_status() {
        return this.$store.state.access_status;
      },
      parent_main_menu() {
        return this.main_menus.filter(menu => menu.is_parent && menu.sub_menus.length > 0);
      },
      single_main_menu() {
        return this.main_menus.filter(menu => menu.is_parent === false);
      },
      activeCategories() {
        let activeAttractions = this.hotelDetails.property_explore_attractions.filter(item => item.active)
        return this.categories.filter(c => activeAttractions.find(attr => attr.name === c.name))
      },
      headerTopValue() {
        if (!this.global_message) {
          return '0'
        } else {
          return `${this.globalMessage}px`
        }
      }
    },
    methods: {
      sanitize(url) {
        return url.replace(/ /g, '%20');
      },
      configure_dynamic_menus() {
        if (this.website_menus.length > 0) {
          this.website_menus.forEach(menu => {
            if ((menu.is_parent === true) || (menu.is_parent === false && menu.parent_website_menu_id === null)) {
              menu.sub_menus = [];
              this.main_menus.push({...menu, active_menu: menu.name});
            } else {
              this.submenus.push(menu);
            }
          })
        }
        if (this.submenus.length > 0) {
          this.submenus.forEach(submenu => {
            this.main_menus.forEach(menu => {
              if (submenu.parent_website_menu_id === menu.id) {
                menu.sub_menus.push({...submenu, active_menu: menu.name});
              }
            })
          })
        }
      },
      chooseMenu(submenu) {
        let pattern = /^((http|https|ftp):\/\/)/;
        let url_ = null;

        if (submenu.is_redirect_url) {
          if (!pattern.test(submenu.redirect_url)) {
            url_ = "http://" + submenu.redirect_url;
            window.open(url_, '_blank');
          } else {
            window.open(submenu.redirect_url, '_blank');
          }
        } else if ((submenu.hasOwnProperty('sub_menus') && submenu.sub_menus.length === 0)) {
          this.$router.push({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu: submenu.active_menu,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })
        } else if (!submenu.hasOwnProperty('sub_menus') && !submenu.is_redirect_url && !!submenu.parent_website_menu_id) {
          this.$router.push({
            name: 'dynamic-menu',
            params: {
              tagline: submenu.tagline,
              description: submenu.description,
              images: submenu.images,
              name: submenu.name,
              cover_image: submenu.cover_image,
              active_menu : submenu.active_menu,
              menu_sections: submenu.property_website_menu_sections,
              enable_section_images: submenu.enable_section_images
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
    position: absolute;
    z-index: 20;
    width: 100%;
    background-color: rgba(black, .55);

    .skip_to_content {
      position: absolute;
    }

    .logo {
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      margin-left: 10px;

      img {
        width: 80px;
        height: 75px;
      }

      h1 {
        font-weight: 400;
        font-size: 1.1rem;
        color: #fff;
        position: absolute;
        left: 95px;
        top: -3px;
        min-width: 270px;
      }
    }

    .menu {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .contact-info {
        color: #19a7f3;
        font-size: 0.7rem;
        text-align: right;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .lang {
          display: flex;
          align-items: center;
          margin-right: 20px;
          color: #fff;
          cursor: pointer;
          opacity: 0.9;

          img {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }

          &:hover{
            opacity: 1;
          }
        }

        address{
          display: flex;
          justify-content: flex-end;
          margin-right: 10px;
          font-style: normal;

          a {
            padding: 0 5px;
            display: flex;
            align-items: center;

            svg {
              //stroke: rgba(255, 255, 255, 0.9);
              stroke: #c1c1c1;
              height: 16px;
            }
          }
        }
      }

      .mobile-menu {
        display: none;

        svg {
          stroke: white;
        }
      }

      nav {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        align-self: flex-end;

        .close-icon {
          display: none;
        }

        .active-menu {
          color: white;
          border-bottom: 1px solid white;
        }

        a, .dynamic-menus {
          text-transform: uppercase;
          padding: 8px;
          font-size: .9rem;
          color: #afafaf;
          font-weight: 500;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            color: #fff;
          }

          &.router-link-exact-active {
            color: white;
            border-bottom: solid 1px white;
          }

          .main-menu {
            position: relative;
            cursor: pointer;

            .sub-menus {
              display: flex;
              position: absolute;
              border-top: 2px solid white;
              background-color: rgba(0, 0, 0, 0.55);
              min-width: 200px;
              top: 30px;
              right: -45%;
              box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
              padding: 12px 16px;
              z-index: 1;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              transform: translateY(1em);
              transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

              a {
                padding-bottom: 5px;
                margin: 0;
              }
            }

            span:hover {
              color: white;
            }
          }
        }

        .accessible-menu{
          button {
            background-color: transparent;
            text-transform: uppercase;
            padding: 10px 4px;
            font-size: .9rem;
            color: #afafaf;
            font-weight: 500;
            &:hover{
              color: #fff;
            }
          }

          ul {
            position: absolute;
            li{
              padding: 5px 0;
              button {
                background-color: transparent;
                text-transform: uppercase;
                font-size: .9rem;
                color: #afafaf;
                font-weight: 500;
                padding: 0;
                &:hover{
                  color: #fff;
                }
              }
            }
          }
        }

        .book-button {
          border: none;
          border-radius: 25px;
          color: white;
          font-size: .9rem;
          cursor: pointer;
          letter-spacing: .1em;
          outline: none;
          padding: .6rem 1rem;
          position: relative;
          text-transform: uppercase;
          z-index: 1;

          &::before {
            border-radius: inherit;
            content: '';
            filter: blur(10px);
            opacity: .75;
            position: absolute;
            transition: all .3s ease-in-out;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: -1;
          }

          &:hover {
            transition: all .3s ease-in-out;
            transform: scale(1.1);
          }
        }
      }
    }
  }

  @include media('<=tablet') {
    header {
      position: fixed;
      z-index: 102;

      .logo {
        img {
          width: 60px;
          height: 50px;
        }

        h1 {
          position: relative;
          left: 5px;
          top: 0;
        }
      }

      .menu {
        .contact-info {
          position: absolute;
          top: 10px;
          right: 0px;

          address {
            display: none;
          }
        }

        .mobile-menu {
          display: block;
          margin: 5px 5px 0 0;
        }

        nav {
          display: none;
          position: fixed;
          box-sizing: border-box;
          flex-direction: column;
          text-align: center;
          height: 100vh;
          width: 100%;
          padding: 2rem;
          background-color: white;
          z-index: 99999;
          grid-column: full;
          top: 0;
          left: 0;
          bottom: 0;

          .active-menu {
            color: $black;
            border-bottom: solid 1px #ebebeb;
          }

          a, .dynamic-menus {
            padding: 1.5rem 0px;
            border-bottom: solid 1px #ebebeb;
            width: 100%;
            position: unset;
            color: #888;
            font-size: 1.5rem;

            &.router-link-exact-active {
              color: $black;
              border-bottom: solid 1px #ebebeb;
            }

            .main-menu {
              font-size: 1.5rem;

              .sub-menus {
                display: none;
                position: unset;
                z-index: 1;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              }
            }

            .main-menu:focus .sub-menus,
            .main-menu:focus-within .sub-menus,
            .main-menu:hover .sub-menus {
              display: flex;
              box-shadow: none;
              margin: 0;
              background-color: transparent;

              a {
                border-bottom: none;
              }
            }
          }

          &.mobile-active {
            display: flex !important;
            overflow-y: auto;
            flex-wrap: nowrap;

            .close-icon {
              display: block;
              position: absolute;
              top: 10px;
              right: 10px;
            }
          }
        }
      }
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      header nav {
        background-color: rgba(255, 255, 255, 1);
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
      }
    }
  }

  @include media('<=phone') {
    .logoImg img {
      max-width: 60px !important;
    }

    header {
      .contact-info {
        display: none;
      }
    }
  }
</style>
