import {Loader} from 'vue-feather-icon'
import layout from '@/layouts/page/page.vue'
import divider from '@/components/general/divider.vue'
import AmenitieCard from '@/components/amenitie-card.vue'

import {mapState} from 'vuex'

export default {
	mounted() {
	  console.log("tthis.hotel asdasd",this.hotel);
		if (!this.hotel) {
			this.$store.dispatch('setPropDetails');
		} else {
			document.getElementsByTagName('title')[0].innerHTML = 'Amenities | ' + this.hotel_name;
		}
	},
	components: {
		layout,
		divider,
		AmenitieCard,
    loader: Loader.default
	},
	computed: {
	  ...mapState({
      hotel_name: state => state.hotel.name,
      special_amenities: state => state.hotel.special_amenities
    }),
		hotel() {
			return this.$store.state.hotel;
		},
    theme () {
      const theme = this.hotel.website_config.theme
      return theme ? `${theme.toLowerCase()}-theme` : null
    },
		property_code() {
			return this.$store.state.property_code;
		},
		amenities() {
			return this.$store.state.amenities;
		},
		defaultAmenities() {
			return !!this.amenities && this.amenities.filter((item) => {
				if (item.category === 'ADDITIONAL') {
					return null;
				} else if (item.category === 'STANDARD') {
					return item;
				}
			});
		},
		finalAmenities() {
			if (this.hotel) {
				let defaultAm = this.defaultAmenities || [];
				let specialAm = !!this.special_amenities &&
					!!this.special_amenities.length > 0 &&
					this.special_amenities.split(',');

				if (specialAm) {
					let pairing = specialAm.map((obj) => {
						return { name: obj, active: true, property_id: '9d0cbf1b-1015-45f8-8f61-9372d0719e57' };
					});
					return defaultAm.concat(pairing);
				}
				return defaultAm;
			}
			return false;
		},
	},
	data: () => ({})
};
