import { render, staticRenderFns } from "./dynamic-menu.html?vue&type=template&id=28650386&scoped=true&external"
import script from "./dynamic-menu.js?vue&type=script&lang=js&external"
export * from "./dynamic-menu.js?vue&type=script&lang=js&external"
import style0 from "./dynamic-menu.scss?vue&type=style&index=0&id=28650386&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28650386",
  null
  
)

export default component.exports