<template>
  <div class="property_view">
    <carousel size="full" :carousel-images="rendered_photos">
    </carousel>
    <section class="block-content">
      <h2>{{property.name}}</h2>
      <h4>{{property.tag_line}}</h4>
      <h3>
        <place-icon></place-icon>
        {{property.city}}
      </h3>
      <p style="margin-bottom: 0">{{property.address}}.</p>
      <p>{{property.city}}, {{property.state}} {{property.zip_code}}</p>
      <div class="email" v-if="property.email">
        <img src="@/assets/static/email.svg" alt="">
        <span>{{property.email}}</span>
      </div>
      <div class="buttons">
        <button @click="goToProperty()">
          <span>Visit Website</span>
          <arrow-right></arrow-right>
        </button>
        <button @click="callProperty()">
          <span>{{property.phone_no}}</span>
          <arrow-right></arrow-right>
        </button>
      </div>
    </section>
  </div>

</template>

<script>
  import {CheckCircle, X, MapPin, ArrowRight} from 'vue-feather-icon';
  import carousel from '@/components/general/carousel/carousel.vue';

  export default {
    components: {
      carousel,
      check: CheckCircle.default,
      close: X.default,
      placeIcon: MapPin.default,
      arrowRight: ArrowRight.default
    },
    props: {
      images: {
        type: Array,
        required: false,
        default: () => (
          [{url: null}]
        ),
      },
      property: {
        type: Object,
        required: true
      }
    },
    computed: {
      rendered_photos() {
        let photos = [];
        this.images.forEach((image) => {
          if (image.hasOwnProperty('url')) {
            photos = this.images;
          } else {
            image.url = image.image_url
            photos.push(image);
          }
        });
        return photos;
      },
    },
    methods: {
      goToProperty() {
        if(this.property.website.startsWith('http')) {
          return window.open(this.property.website, '_blank')
        }
        return window.open(`http://${this.property.website}`, '_blank')
      },
      callProperty() {
        window.location.href = `tel:${this.property.phone_no}`
      }
    }
  };

</script>

<style lang="scss" scoped>
  @import "~css_vars";
  @import "~mixins";

  article, figure {
    margin: 0;
  }

  .property_view {
    display: flex;
    background-color: white;
    max-width: 1800px;
    margin: 50px;
    font-family: Poppins, sans-serif;

    > * {
      width: 50%;
    }

    .block-content {
      word-break: break-word;
      padding: 1rem 3rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-between;

      h1,h2,h3,h4,h5,h6,p,button {
        font-family: Poppins, sans-serif;
      }

      h6 {
        text-transform: uppercase;
        color: grey;
        letter-spacing: .28em;
      }

      h4 {
        font-weight: 600;
        font-size: 0.8em;
        letter-spacing: 0.25em;
        margin: 0;
      }

      h2 {
        font-weight: 400;
        font-size: 1.75em;
        line-height: 1.2em;
      }

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #17a7f3;
        font-size: 1.4em;
        font-weight: 500;
        line-height: 1.2em;

        svg {
          stroke: #17a7f3;
          stroke-width: 1px;
          margin-right: 10px;
        }
      }

      p {
        line-height: 1.75em;
        letter-spacing: 0.01em;
        font-weight: 300;
        margin-bottom: 1rem;
        font-size: 1em;
        margin-top: 0;
        opacity: 0.65;
      }

      .email {
        display: flex;
        justify-content: center;
        margin-bottom: 1.2rem;
        color: #17a7f3;
        font-size: 15px;
        img {
          height: 20px;
          margin-right: 10px;
        }
      }

    }

    .buttons {

      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      button {
        cursor: pointer;
        border: 1px solid #dadada;
        color: black !important;
        font-weight: normal;
        font-size: 1rem;
        color: white;
        padding: 1rem 2rem;
        background: white;

        svg {
          stroke-width: 1px;
          margin-left: 15px;
        }

        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }

  @include media('<tablet', 'portrait') {
    .property_view {
      flex-direction: column;
      margin-bottom: 2rem;
      margin: 20px;

      >* {
        width: 100%;
      }

      .block-content {
        padding: 1rem;
        font-size: 80%;

        h2 {
          font-size: 1.7rem;
          margin-bottom: 10px;
        }

        p {
          font-size: 1rem;
        }
      }

      > * {
        width: 100%;
      }

      .buttons {
        flex-direction: column;
        button:first-child {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      box-shadow: 0px 3px 11px -5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      overflow: hidden;

      #carousel_wrap {
        min-height: 50vmin;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */

    .property_view {
      flex: 1;
      margin: 0 20px;

      > * {
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
      }
    }

  }

</style>
