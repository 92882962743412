<template>
  <div class="express-rooms">
    <button @click="goLeft()" class="right" :class="{'hidden-arrow': !scrollLeftt}">
      <chevron-left></chevron-left>
    </button>

    <div class="express-room-container" :style="style">
      <room-view :amenities="amenities" v-for="(room, index) in sortedRooms" :key="room.id" :id="room.id"
                 :title="room.name" :reverse="index%2 !== 0" :subheading="room.subheading"
                 :description="room.additional_details"
                 :images="room.cover_pics" />
    </div>

    <button @click="goRight()" class="left" :class="{'hidden-arrow': !scrollRightt}">
      <chevron-right></chevron-right>
    </button>
  </div>
</template>

<script>
  import {ChevronLeft, ChevronRight} from 'vue-feather-icon'
  import roomView from "./room_view_express_theme";

  export default {
    name: "ExpressRoomContainer",
    props: ['rooms', 'amenities'],
    components: {
      roomView,
      ChevronLeft: ChevronLeft.default,
      ChevronRight: ChevronRight.default
    },
    mounted() {
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
      this.checkChevrons();
    },
    data() {
      return {
        windowWidth: window.innerWidth,
        scrollLeftt: true,
        scrollRightt: true
      }
    },
    computed: {
      sortedRooms() {
        let rooms = [...this.rooms].sort((a, b) => a.upsell_sequence_number - b.upsell_sequence_number)

        return rooms
      },
      justifyContent() {
        if (this.sortedRooms.length < 4) {
          return true
        } else {
          if (this.windowWidth > 1600) {
            if (this.sortedRooms.length < 5) {
              return true
            } else {
              return false
            }
          }
          if (this.windowWidth < 1600) {
            return false
          }
        }

        return false
      },
      style() {
        return {
          'grid-template-columns': `repeat(${this.sortedRooms.length}, minmax(340px, 350px))`,
          'grid-gap' : this.sortedRooms.length < 3 ? '50px' : '20px',
          'justify-content': this.justifyContent ? 'center' : 'start'
        }
      }
    },
    methods: {
      goLeft() {
        let elem = document.getElementsByClassName('express-room-container')[0]

        elem.scroll({
          left: elem.scrollLeft - document.getElementsByClassName('custom_room_view_express_theme')[0].scrollWidth - 20,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkChevrons()
        }, 300)
      },
      goRight() {

        let elem = document.getElementsByClassName('express-room-container')[0]

        elem.scroll({
          left: elem.scrollLeft + document.getElementsByClassName('custom_room_view_express_theme')[0].scrollWidth + 20,
          behavior: 'smooth'
        })
        setTimeout(() => {
          this.checkChevrons()
        }, 300)
      },
      checkChevrons() {

        let elem = document.getElementsByClassName('express-room-container')[0];
        if (elem.scrollLeft === 0) {
          this.scrollLeftt = false
        } else if (elem.scrollLeft > 0) {
          this.scrollLeftt = true;
        }

        let scrollLefti = parseInt(elem.scrollLeft.toFixed(0))

        if (scrollLefti + parseInt(elem.offsetWidth.toFixed(0)) === parseInt(elem.scrollWidth.toFixed(0))) {
          this.scrollRightt = false;
        } else if (scrollLefti + parseInt(elem.offsetWidth.toFixed(0)) !== parseInt(elem.scrollWidth.toFixed(0))) {
          this.scrollRightt = true;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~mixins';
  @import '~css_vars';

  .express-rooms {
    position: relative;
    padding: 0 100px;
    max-width: 100%;
    overflow: hidden;

    .express-room-container {
      display: grid;
      overflow-x: hidden;
    }

    .right {
      position: absolute;
      left: 0;
      top: 40%;
      min-width: 30px;
      padding: 0;
      z-index: 100;
      &.hidden-arrow {
        display: none;
      }
    }

    .left {
      position: absolute;
      right: 0;
      top: 40%;
      min-width: 30px;
      padding: 0;
      z-index: 100;
      &.hidden-arrow {
        display: none;
      }
    }
  }

  @include media("<=tablet") {
    .express-rooms {
      padding: 0 50px;
    }
  }

  @include media("<=phone") {
    .express-rooms {
      padding: 0 15px;
    }
  }
</style>
